import React, { useEffect, useState, useRef } from "react";
import arrowleft from "../../../../Assets/Admin/Arrow-left.svg";
import edit from "../../../../Assets/Admin/Edit.svg";
import loc from "../../../../Assets/Admin/location.svg";
import male from "../../../../Assets/Admin/male.svg";
import idcard from "../../../../Assets/Admin/id-card.svg";
import call from "../../../../Assets/Admin/call.svg";
import iraq from "../../../../Assets/Admin/iraq.svg";
import iran from "../../../../Assets/Admin/iran.svg";
import start from "../../../../Assets/Admin/Start-date.svg";
import end from "../../../../Assets/Admin/End-date.svg";
import personnel from "../../../../Assets/img/personnel.png";
import dot from "../../../../Assets/Admin/dot.svg";
import { useNavigate, useParams } from "react-router-dom";
import moment from "jalali-moment";
import female from "../../../../Assets/Admin/Female.svg";
import persenal from "../../../../Assets/img/personnel.png";
import Modal from "../../../Module/Module";
import ageRangesData from "@/Components/Servant/Add/Static/ageRangeData";
import ageTypesData from "@/Components/Servant/Add/Static/ageTypeData";
import { fetchCountries } from '@/api/countriesApi';
import { fetchCities } from '@/api/cityAPI';
import { convertToTime } from "@/utils/convertToTime";
import { convertToJalaali } from '@/utils/convertToJalaali';
import { Country } from "@/types/country";
import { City } from "@/types/city";
import { getAgeGroup } from "@/utils/getAgeGroup";
import { getAgeRange } from "@/utils/getAgeRange";
import { getGenderText } from '@/utils/getGenderText';
import { getCountryTitle } from "@/utils/getCountryTitle";
import { getCityTitle } from "@/utils/getCityTitle";

type UserData = {
  id: number;
  name: string;
  family: string;
  national_code: string;
  gender: string;
  iran_phone: string;
  iraq_phone: string;
  country_id: number;
  city_id: number;
  user_id: number;
  created_at: string;
  updated_at: string;
};

type Pilgrim = {
  id: number;
  name: string;
  family: string;
  gender: string;
  age_range_id: number;
  age_group_id: number;
  is_special: string;
  country_id: number;
  city_id: number;
  region: string;
  appointment: string;
  created_by: string;
  description: string;
  national_code: string;
  companions: string;
  iran_mobile: string;
  iraq_mobile: string;
  report_by_servant: number;
  type: string;
  status: string;
  notifier_name: string;
  notifier_family: string;
  notifier_relation: string;
  notifier_phone_number: string;
  created_at: Date;
  photo_url: string;
};

type Servant = {
  id: number;
  username: string;
  status: string;
  start_at: any;
  expire_at: any;
  created_at: string;
  updated_at: string;
  image_url: string;
  pilgrims_count: number;
  user_data: UserData;
  pilgrims: Pilgrim[];
  // roles:roles,

};

interface ApiResponse {
  user: Servant;
  photo: string;
}
// type roles= {
//   name:string
// }


interface ApiResponse {
  user: Servant;
  photo: string
}

export default function Chosenservant() {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [rol, setRol] = useState("")
  const [user, setUser] = useState<Servant | null>(null);
  const [listsCountries, setListsCountries] = useState<Country[]>([]);
  const [listsCity, setListsCity] = useState<City[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [zoomedImage, setZoomedImage] = useState<string | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const fetchedCountries = useRef(false);
  const fetchedCities = useRef(false);

  const token = localStorage.getItem("token");
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    const fetchPersonInfo = async () => {
      try {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);
        myHeaders.append("Accept", "application/json");

        const requestOptions = {
          method: "GET",
          headers: myHeaders,
        };

        const response = await fetch(
          `https://api.mafghoudin-zakerin.com/api/users/${id}`,
          requestOptions
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();
        setUser(data.user);
        setRol(data.user.roles[0].name)
        setLoading(false);
        console.log(data);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
    fetchPersonInfo();
  }, [id, token]);
  useEffect(() => {
    const fetchCountriesData = async () => {
      if (fetchedCountries.current) return;
      fetchedCountries.current = true;
      if (token) {
        const countryList = await fetchCountries(token);
        setListsCountries(countryList);
      }
    };
    fetchCountriesData();
  }, [token]);

  useEffect(() => {
    const fetchCitiesData = async () => {
      if (fetchedCities.current) return;
      fetchedCities.current = true;
      if (token) {
        const cityList = await fetchCities(token);
        setListsCity(cityList);
      }
    };
    fetchCitiesData();
  }, [token]);

  const handleImageClick = (image: string | null) => {
    if (image) {
      setZoomedImage(image);
    }
  };

  const handleBackClick = () => {
    setZoomedImage(null);
  };
  const handleViewInfoClick = (id: number) => {
    navigate(`/Missinginfopage/${id}`);
  };
  if (loading) {
    return (
      <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center  z-50">
        <div className="flex flex-col items-center">
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
          <div className="mt-4 text-white text-xl">لطفا صبر کنید</div>
        </div>
      </div>
    );
  }
  if (zoomedImage) {
    return (
      <div className="fixed top-0 left-0 w-full h-full flex flex-col items-center justify-center bg-gray-900 bg-opacity-75 z-50">
        <div className="relative">
          <img
            src={zoomedImage}
            alt="Zoomed"
            className="w-[500px] h-[500px] object-cover"
          />
          <button
            onClick={handleBackClick}
            className="absolute top-2 right-2 rounded-full p-1"
          >
            <span role="img" aria-label="Close">
              ❌
            </span>
          </button>
        </div>
      </div>
    );
  }
  const photoUrl = user?.image_url !== undefined ? user.image_url : null;
  function Back() {
    window.history.back();
  }
  function Back2() {
    navigate('/Servantpage')
  }
  return (
    <>
      <div className="  w-full  h-[56px] flex justify-center items-center gap-[200px] bg-[white] rounded-t-md border-b-[1px] border-[#F0F0F0] sticky top-0  shadow-custom  ">
        <div className=" w-[104px] h-[32px] flex justify-center items-center">
          <p className=" text-sm text-[#434343] font-semibold">مشخصات خادم</p>
        </div>
        <button
          onClick={Back2}
          className=" w-[24px] h-[24px] flex justify-center items-center"
        >
          <img src={arrowleft} alt="back" />
        </button>
      </div>

      <div className="w-full  h-[761px]  flex flex-col    items-center     overflow-x-hidden overflow-y-auto  ">
        <div className="  w-[328px] h-[376px]  flex flex-col  items-center gap-4">
          <div className="w-[328px] h-[48px]   flex    gap-[120px]">
            <div className="w-[60%] h-[48px]   flex   gap-2">
              <img
                onClick={() => handleImageClick(photoUrl)}
                // src={user?.image_url}

                src={user?.image_url ? user?.image_url : personnel}
                width={48}
                height={48}
                alt=""
                className="rounded-full  w-[48px] h-[48px] "
              />

              <div className=" min-w-[89px] h-[48px]  flex flex-col  items-center gap-2  ">
                <p className="text-[#262626] text-[14px] font-semibold  min-w-[75px] h-[24px]">
                  {user?.user_data?.name === "" ? "" : user?.user_data?.name} { }
                  {user?.user_data?.family === "" ? "" : user?.user_data?.family}
                </p>
                <p className="text-[#595959] text-[12px] min-w-[75px] h-[24px]">
                  {rol === "servant" ? "ثبت کننده" : "پیجر"}
                </p>
              </div>
            </div>
            <div className="    relative   w-6  h-10 ">
              <img
                src={dot}
                width={3.33}
                height={13.33}
                alt=""
                onClick={toggleDropdown}
              />

              {isOpen && (
                <Modal />
              )}
            </div>
          </div>

          <div className=" w-[328px] h-[48px]   flex  justify-between ">
            <div className="w-[156px] h-[48px] bg-[#FAFAFA]  rounded-[12px]  flex gap-1 justify-center items-center">
              <div className="min-w-[123px] h-[24px]    flex gap-1 ">
                <img src={loc} alt="loc" />
                <span className="text-[12px] text-[#595959] mt-[2px] min-w-[101px] h-[24px]">
                  <>
                    {user?.user_data?.country_id == null
                      ? ""
                      : getCountryTitle(user?.user_data?.country_id, listsCountries)}
                    {user?.user_data?.city_id == null
                      ? ""
                      : `, ${getCityTitle(user?.user_data?.city_id, listsCity)}`}
                  </>
                </span>
              </div>
            </div>
            <div className="w-[156px] h-[48px] bg-[#FAFAFA]  rounded-[12px]   flex items-center  justify-center">
              <div className=" w-[123px] h-[24px]   flex  gap-5  ">
                <div className=" min-w-[40px] h-[24px]   flex     items-center gap-[3px] ">
                  <img
                    height={17}
                    width={17}
                    src={user?.user_data?.gender === "male" ? male : female}
                    alt="gender"
                  />
                  <p className="text-[#595959] text-xs">
                    {user?.user_data?.gender === "male" ? "مرد" : "زن"}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="w-[328px]  h-[97px]  bg-[#FAFAFA]    flex  flex-col  items-center  justify-around   rounded-[12px]">
            <div className=" w-[300px]  h-[24px]  flex  justify-between ">
              <div className=" min-w-[111px]  h-[24px] flex  items-center ">
                <div className=" w-[60px]  h-[24px]  flex   gap-1">
                  <img src={call} alt="id" />
                  <img src={iraq} alt="id" />
                </div>

                <span className="text-[12px]  text-[#595959]   min-w-[63px]  ">
                  شماره عراقی
                </span>
              </div>

              <div className="  w-[111px]   h-[24px]  flex  items-center ">
                <span className=" text-xs text-[#595959]">
                  {user?.user_data?.iraq_phone}
                </span>
              </div>
            </div>
            <span className=" w-[304px]  h-0   border-[1px]  border-[#F0F0F0]"></span>

            <div className=" w-[300px]  h-[24px] flex  justify-between ">
              <div className=" min-w-[111px]  h-[24px]  flex  items-center  ">
                <div className=" w-[60px]  h-[24px]  flex  gap-1">
                  <img src={call} alt="id" />
                  <img src={iran} alt="id" />
                </div>

                <span className="text-[12px]  text-[#595959]   min-w-[63px]  ">
                  شماره ایرانی
                </span>
              </div>

              <div className="  w-[111px]   h-[24px]  flex  items-center ">
                <span className=" text-xs text-[#595959]">
                  {user?.user_data?.iran_phone}
                </span>
              </div>
            </div>
          </div>

          <div className=" w-[328px] h-[48px]   flex  justify-between ">
            <div className="w-[156px] h-[48px] bg-[#FAFAFA]  rounded-[12px]  flex gap-1 justify-center items-center">
              <div className="min-w-[147px] h-[24px]   flex gap-1  items-center">
                <img src={start} alt="loc" />
                <span className=" text-[12px] text-[#595959] mt-[2px]  min-w-[200px] h-[24px]  flex items-center">
                  شروع خدمت:{convertToJalaali(user?.start_at)}
                </span>
              </div>
            </div>
            <div className="w-[156px] h-[48px] bg-[#FAFAFA]  rounded-[12px]  flex gap-1 justify-center items-center">
              <div className="min-w-[147px] h-[24px]  flex gap-1  items-center">
                <img src={end} alt="loc" />
                <span className=" text-[12px] text-[#595959] mt-[2px]  min-w-[101px] h-[24px]  flex items-center">
                  پایان خدمت:{convertToJalaali(user?.expire_at)}
                </span>
              </div>
            </div>
          </div>
        </div>
        <span className="  w-full  h-0   border-[4px]  border-[#F0F0F0]  mt-[16px]"></span>
        {/* Lost informations */}
        <div className="  w-[328px]  min-h-[70px]  flex  items-center   justify-between">
          <span className=" w-[114px] h-[28px]  text-[14px] font-bold  text-[#434343]">
            گمشدگان ثبت شده
          </span>
          <span className=" w-[27px]  h-[28px]  text-[14px] font-bold text-[#E48157]">
            {user?.pilgrims_count}
          </span>
        </div>
        <div className="w-[328]    min-h-[899px]  mb-20  overflow-y-auto   overflow-x-hidden    flex  flex-col   gap-4  mt-[8px]">
          {user?.pilgrims.map((person) => (
            <>
              <div
                key={person.id}
                className="min-h-[280px]  min-w-[328px] p-5 bg-white rounded-[12px] border-[1px]    border-[#F0F0F0] shadow-md flex flex-col gap-5  pr-2"        >
                <div className="w-[300px] h-[48px] flex justify-between items-center  ">
                  <div className="w-[328px] h-[48px] flex justify-between items-center gap-1 ">
                    <div className=" min-w-[190px] h-[48px]  flex  gap-2">
                      <img
                        onClick={() => handleImageClick(person.photo_url)}
                        src={person.photo_url ? person.photo_url : persenal}
                        alt={`${person.name} ${person.family}`}
                        className="rounded-full  w-[48px] h-[48px]  "
                      />
                      <div className=" min-w-[89px] h-[48px]  flex flex-col  items-center gap-2  ">
                        <p className="text-[#262626] text-[14px] font-semibold  min-w-[75px] h-[24px]">
                          {person.name === "" ? "" : person.name} { }
                          {person.family === "" ? "" : person.family}
                        </p>
                        <p className="text-[#595959] text-[12px] min-w-[75px] h-[24px]">
                          گمشده
                        </p>
                      </div>
                    </div>
                    <div className=" w-[129px] h-[48px]   flex flex-col  items-center ">
                      {" "}
                      <span className="min-w-[53px] h-[24px] text-[#5EA19B]   flex items-center  justify-center  font-semibold   text-[12px]">
                        {person.is_special === "1" ? "افراد خاص" : ""}
                      </span>
                      <p className="text-[#595959] text-[12px]   flex items-center  justify-center  font-semibold   w-[129px]  h-[24px] ">
                        {convertToJalaali(person.created_at)}
                      </p>
                      <p className="text-[#595959] text-[12px]   flex items-center  justify-center  font-semibold   w-[129px]  h-[24px] ">
                        {convertToTime(person.created_at)}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="min-w-[224px]  h-[24px]  flex">
                  <div className=" w-[84px] h-[24px]  flex  items-center  gap-1">
                    <img src={loc} alt="loc" />
                    <p className="text-[#595959] text-[10px] font-semibold w-[62px] h-[24px] flex items-center">
                      <>
                        {person.country_id == null
                          ? ""
                          : getCountryTitle(person.country_id, listsCountries)}
                        {person.city_id == null ? "" : getCityTitle(person.city_id, listsCity)}
                      </>
                    </p>
                  </div>

                  <div className=" min-w-[124px] h-[24px]   flex  gap-5">
                    <div className=" min-w-[40px] h-[24px]   flex     items-center gap-[3px] ">
                      {/* <img src={gender} alt="gender" /> */}

                      <p className="text-[#595959] text-xs">
                        {person.gender == null ? "" : getGenderText(person.gender)}
                      </p>
                    </div>
                    <span className="min-w-[140px] h-[24px] flex   items-center  gap-1   text-[#595959] text-[12px]">
                      {person.age_group_id
                        ? getAgeGroup(Number(person.age_group_id))
                        : null}{" "}
                      { }
                      {person.age_range_id
                        ? getAgeRange(Number(person.age_range_id))
                        : null}
                    </span>
                  </div>
                </div>
                <div className=" w-[167px] h-[22px] flex     gap-1">
                  <span className=" w-[167px] h-[24px]   font-semibold text-[12px] text-[#8C8C8C]  flex  gap-1   items-center">
                    خادم ثبت کننده :
                    <span className="  text-[12px]   font-semibold text-[#2E857D]">
                      {person.created_by}
                    </span>
                  </span>
                </div>
                <div className=" min-w-[167px] h-[22px] flex     gap-1">
                  <span className=" min-w-[167px] h-[24px]   font-semibold text-[12px] text-[#8C8C8C]  flex  gap-1   items-center">
                    محل قرار:
                    <span className="  text-[12px]   font-semibold text-[#2E857D]">
                      {" "}
                      {person.appointment}
                    </span>
                  </span>
                </div>
                <div className=" min-w-[301px]  h-[22px]   flex items-center">
                  <span className=" font-semibold text-[12px] text-[#8C8C8C]  flex  gap-1   items-center">
                    توضیحات:
                    <span className="  text-[12px]   font-semibold text-[#2E857D]">
                      {" "}
                      {person.description}
                    </span>
                  </span>
                </div>
                <div className=" w-[305px] min-h-[50px]  flex    flex-col    gap-[13px]">
                  <span className=" font-semibold text-[12px] text-[#8C8C8C]  flex  gap-1 ">
                    نام و نام خانوادگی:
                    <span className="  text-[12px]   font-semibold text-[#2E857D]">
                      {person.notifier_name} {person.notifier_family}
                    </span>
                  </span>
                  <div className="  w-[100%] min-h-[40%]   flex  justify-between  ">
                    <span className=" font-semibold text-[12px] text-[#8C8C8C]  flex  gap-1   ">
                      {" "}
                      نسبت:
                      <span className="  text-[12px]   font-semibold text-[#2E857D]">
                        {person.notifier_relation}
                      </span>
                    </span>
                    <button
                      onClick={() => handleViewInfoClick(person.id)}
                      className="w-[87px] h-[24px] flex justify-center items-center"
                    >
                      <p className="text-xs text-[#E48157]">مشاهده اطلاعات</p>
                    </button>
                  </div>
                </div>
              </div>
            </>
          ))}
        </div>
      </div>
    </>
  );
}
