const ageRangesData = [
    {
      id: 1,
      type_id: 1,
      range: "۰ تا ۵",
      status: "enable",
      created_at: null,
      updated_at: null,
    },
    {
      id: 2,
      type_id: 1,
      range: "۵ تا ۱۰",
      status: "enable",
      created_at: null,
      updated_at: null,
    },
    {
      id: 3,
      type_id: 1,
      range: "۱۰ تا ۱۵",
      status: "enable",
      created_at: null,
      updated_at: null,
    },
    {
      id: 4,
      type_id: 2,
      range: "۱۵ تا ۲۰",
      status: "enable",
      created_at: null,
      updated_at: null,
    },
    {
      id: 5,
      type_id: 2,
      range: "۲۰ تا ۲۵",
      status: "enable",
      created_at: null,
      updated_at: null,
    },
    {
      id: 6,
      type_id: 2,
      range: "۲۵ تا ۳۰",
      status: "enable",
      created_at: null,
      updated_at: null,
    },
    {
      id: 7,
      type_id: 2,
      range: "۳۰ تا ۳۵",
      status: "enable",
      created_at: null,
      updated_at: null,
    },
    {
      id: 8,
      type_id: 2,
      range: "۳۵ تا ۴۰",
      status: "enable",
      created_at: null,
      updated_at: null,
    },
    {
      id: 9,
      type_id: 3,
      range: "۴۰ تا ۴۵",
      status: "enable",
      created_at: null,
      updated_at: null,
    },
    {
      id: 10,
      type_id: 3,
      range: "۴۵ تا ۵۰",
      status: "enable",
      created_at: null,
      updated_at: null,
    },
    {
      id: 11,
      type_id: 3,
      range: "۵۰ تا ۵۵",
      status: "enable",
      created_at: null,
      updated_at: null,
    },
    {
      id: 12,
      type_id: 3,
      range: "۵۵ تا ۶۰",
      status: "enable",
      created_at: null,
      updated_at: null,
    },
    {
      id: 13,
      type_id: 3,
      range: "۶۰ تا ۶۵",
      status: "enable",
      created_at: null,
      updated_at: null,
    },
    {
      id: 14,
      type_id: 3,
      range: "۶۵ تا ۷۰",
      status: "enable",
      created_at: null,
      updated_at: null,
    },
    {
      id: 15,
      type_id: 4,
      range: "۷۰ تا ۷۵",
      status: "enable",
      created_at: null,
      updated_at: null,
    },
    {
      id: 16,
      type_id: 4,
      range: "۷۵ تا ۸۰",
      status: "enable",
      created_at: null,
      updated_at: null,
    },
    {
      id: 17,
      type_id: 4,
      range: "۸۰ تا ۸۵",
      status: "enable",
      created_at: null,
      updated_at: null,
    },
    {
      id: 18,
      type_id: 4,
      range: "۸۵ تا ۹۰",
      status: "enable",
      created_at: null,
      updated_at: null,
    },
    {
      id: 19,
      type_id: 4,
      range: "۹۰ تا ۹۵",
      status: "enable",
      created_at: null,
      updated_at: null,
    },
    {
      id: 20,
      type_id: 4,
      range: "۹۵ تا ۱۰۰",
      status: "enable",
      created_at: null,
      updated_at: null,
    },
    {
      id: 21,
      type_id: 4,
      range: "۱۰۰ +",
      status: "enable",
      created_at: null,
      updated_at: null,
    },
  ];
  
  export default ageRangesData;