import React, { useEffect, useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import Admin from "./Components/Admin/Admin";
import Profpager from "./Components/Pager/Profpager/Profpager";
import Login from "./Components/Login/Login";
import Lost from "./Components/Admin/The lost/Lost";
import Servants from "./Components/Admin/servants/Servants";
import Lostperson from "./Components/Pager/Lostpersons/Pagerloatpersons";
import Add from "./Components/Servant/Add/Add";
import BottomNavadmin from "./Components/common/BottomNavadmin";
import BottomNavpager from "./Components/common/BottomNavpager";
import BottomNavservant from "./Components/common/BottomNavservant";
import Servenlostpersons from "./Components/Servant/Lostpersons/Servenlostpersons";
import Pagerloatpersons from "./Components/Pager/Lostpersons/Pagerloatpersons";
import Prof from "./Components/Servant/Prof/Prof";
import Infoperson from "./Components/Admin/The lost/Missing information/Infoperson/Infoperson";
import Addserv from "./Components/Admin/servants/Addservant/Addservant";
import Chosenservant from "./Components/Admin/servants/Chosen servant/Chosenservant";
import RouteChosenservant from "./Components/Admin/servants/Chosen servant/RouteChosenservant";
import Inquiry from "./Components/Servant/Add/Inquiry";
import { Pilgrim } from "./Components/Pager/Lostpersons/Lostinfo/Lostinfo";
import PropsLosts from "./Components/Servant/Add/PropsLosts";
import PasswordChange from "./Components/PasswordChange/PasswordChange";
import ServantEdite from "./Components/Admin/servants/Addservant/ServantEdite";
import Specialpeople from "./Components/Pager/Lostpersons/Specialpeople/Specialpeople";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function App() {
  useEffect(() => {
    const disableZoom = (event: any) => {
      if (event.ctrlKey || event.metaKey || (event.touches && event.touches.length > 1)) {
        event.preventDefault();
      }
    };

    const disableKeyZoom = (event: any) => {
      if ((event.ctrlKey || event.metaKey) && (event.key === '+' || event.key === '-' || event.key === '0')) {
        disableZoom(event);
      }
    };

    const disableTouchZoom = (event: any) => {
      if (event.touches.length > 1) {
        event.preventDefault();
      }
    };

    document.addEventListener('wheel', disableZoom, { passive: false });
    document.addEventListener('keydown', disableKeyZoom, { passive: false });
    document.addEventListener('touchstart', disableTouchZoom, { passive: false });
    document.addEventListener('touchmove', disableTouchZoom, { passive: false });

    return () => {
      document.removeEventListener('wheel', disableZoom);
      document.removeEventListener('keydown', disableKeyZoom);
      document.removeEventListener('touchstart', disableTouchZoom);
      document.removeEventListener('touchmove', disableTouchZoom);
    };
  }, []);

  return (
    <div>
      <ToastContainer  />
      <Routes>
        <Route path="/Home" element={<Admin />} />
        <Route path="/change-password/:id" element={<PasswordChange />} />
        <Route path="/ServantEdite/:id"   element={<ServantEdite/>}/>
        <Route path="/special-people"   element={<Specialpeople/>}/>

        <Route
          path="/Lostpage"
          element={
            <>
              <Lost />
              <BottomNavadmin />
            </>
          }
        />
        <Route path="/Missinginfopage/:id" element={<Infoperson />} />
        <Route
          path="/Servantpage"
          element={
            <>
              <Servants />
              <BottomNavadmin />
            </>
          }
        />
        <Route
          path="/Servantprof"
          element={
            <>
              <Prof />
              <BottomNavservant />
            </>
          }
        />
        <Route
          path="/Profpager"
          element={
            <>
              <Profpager />
              <BottomNavpager />
            </>
          }
        />
        <Route path="/" element={<Login />} />
        <Route path="/Addservant" element={<Addserv/>} />
        
        {/* <Route path="/Addperson" element={<Addperson/>} /> */}



        <Route
          path="/Servenlostpersons"
          element={
            <>
              <Servenlostpersons />
              <BottomNavservant />
            </>
          }
        />
        <Route
          path="/Pagerloatpersons"
          element={
            <>
              <Pagerloatpersons />
              <BottomNavpager />

            </>
          }
        />
       


      </Routes>
      <RouteChosenservant/>
      <PropsLosts/>

    </div>
  );
}

export default App;
