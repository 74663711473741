import React, { useState, ChangeEventHandler, useEffect, useRef } from "react";
import personnel from "../../../Assets/img/personnel.png";
import Edite2 from "../../../Assets/img/Edite2.png";
import Aroow from "../../../Assets/Servant/Arrow.svg";
import { Checkbox } from "antd";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import arrowback from "../../../Assets/Admin/Arrow-left.svg";
import imageCompression from 'browser-image-compression';
import { fetchCountries } from '@/api/countriesApi';
import { Country } from "@/types/country";
import { fetchCities } from '@/api/cityAPI';
import { City } from "@/types/city";
import ageRangesData from "@/Components/Servant/Add/Static/ageRangeData";
import ageTypesData from "@/Components/Servant/Add/Static/ageTypeData";
import { getAgeRange } from "@/utils/getAgeRange";
import { getCountryTitle } from "@/utils/getCountryTitle";

export type ListageRanges1 = {
  range: string;
  id: number;
};
export type listAge_group1 = {
  title: string;
  id: number;
};
const Add = ({ setSimilarPilgrims, name, setName, last, setLast, age_range, setAge_range_id, country, setCountry, city, setCity, appointment, setAppointment, description, setDescription, nid, setNid, companions, setCompanions, irn, setIrn, irq, setIrq, type, setType, status, setStatus, is_special, only_pager, setIs_special, setOnly_pager, Ratio, setRatio, firstLast, setFirstLast, firstLast2, setFirstLast2, region, setRegion, gender, setGender, selectedImage, setSelectedImage, Phone, setPhone }: any) => {
  const navigate = useNavigate();
  const [previewImgUrl, setPreviewimgUrl] = useState<string>("");
  const [isOpen1, setIsOpen1] = useState(false);
  const [isRotated1, setIsRotated1] = useState(false);
  const [selectedItem1, setSelectedItem1] = useState("");
  const [isOpen2, setIsOpen2] = useState(false);
  const [isRotated2, setIsRotated2] = useState(false);
  const [selectedItem2, setSelectedItem2] = useState("");
  const [isOpen3, setIsOpen3] = useState(false);
  const [isRotated3, setIsRotated3] = useState(false);
  const [selectedItem3, setSelectedItem3] = useState("");
  const [isOpen4, setIsOpen4] = useState(false);
  const [isRotated4, setIsRotated4] = useState(false);
  const [isLoading2, setisLoading2] = useState(false);
  const [ListageRanges, setListageRanges] = useState<ListageRanges1[]>([]);
  const [listAge_group, setlistAge_group] = useState<listAge_group1[]>([]);
  const [loading, setLoading] = useState(true);

  const fetchedCountries = useRef(false);
  const fetchedCities = useRef(false);

  const [inputValue, setInputValue] = useState<any>("");
  const [age_group, setAge_group] = useState<any>("");

  const [selectedCountry, setSelectedCountry] = useState(null);

  const getAgeGroupText = (ageGroup: any, selectedItem: any) => {
    switch (ageGroup) {
      case "1":
        return selectedItem || "خردسال و کودک";
      case "2":
        return selectedItem || "نوجوان ";
      case "3":
        return selectedItem || "جوان";
      case "4":
        return selectedItem || "بزرگسال";
      case "5":
        return selectedItem || "مسن";
      default:
        return selectedItem || "";
    }
  };

  const getCityTitle = (id: number | undefined) => {
    if (id === undefined) return "Unknown City";
    const city = cities.find((city) => city.id === id);
    return city ? city.title : null;
  };

  const handleFileChange: ChangeEventHandler<HTMLInputElement> = async (
    event
  ) => {
    const fileList = event.target.files as FileList;
    const file = fileList?.[0];
    if (!file) {
      return;
    }
    try {
      const imgUrl = await fileToDataString(file);
      setPreviewimgUrl(imgUrl);
      const options = {
        maxSizeMB: 1, // حداکثر اندازه فایل (مگابایت)
        maxWidthOrHeight: 800, // حداکثر عرض یا ارتفاع تصویر
      };
      const compressedFile = await imageCompression(file, options);
      setSelectedImage(compressedFile)

    } catch (error) {
      console.error(error);
    }
  };
  const fileToDataString = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (reader.result) {
          resolve(reader.result as string);
        } else {
          reject(new Error("Failed to convert file to data URL"));
        }
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const ChengInput2 = (e: React.ChangeEvent<HTMLInputElement>) => {
    let input = e.target.value.replace(/[^\s0-9]/g, "");
    if (input.length > 11) {
      return;
    }

    setIrn(input);
  };
  const ChengInput3 = (e: React.ChangeEvent<HTMLInputElement>) => {
    let input = e.target.value.replace(/[^\s0-9]/g, "");
    if (input.length > 11) {
      return;
    }

    setIrq(input);
  };
  const ChengInput4 = (e: React.ChangeEvent<HTMLInputElement>) => {
    let input = e.target.value.replace(/[^\s0-9]/g, "");
    if (input.length > 11) {
      return;
    }

    setPhone(input);
  };
  const handleCheckboxChange = (e: any) => {
    setIs_special(e.target.checked ? "1" : "0");
  };

  const toggleDropdown1 = () => {
    setIsOpen1(!isOpen1);
    setIsRotated1(!isRotated1);
  };
  const handleItemClick1 = (event: any, id: number) => {
    setAge_group(id);
    setSelectedItem1(event.target.textContent);
    setIsOpen1(false);
  };
  const toggleDropdown2 = () => {
    setIsOpen2(!isOpen2);
    setIsRotated2(!isRotated2);
  };
  const handleItemClick2 = (event: any, id: number) => {
    setAge_range_id(id);
    setSelectedItem2(event.target.textContent);
    setIsOpen2(false);
  };
  const toggleDropdown3 = () => {
    setIsOpen3(!isOpen3);
    setIsRotated3(!isRotated3);
  };

  const handleItemClick3 = (event: React.MouseEvent<HTMLButtonElement>, id: any) => {
    setSelectedCountry(id);
    setCountry(id);

    const selectedCountryItem = countries.find((item) => item.id === id);
    if (selectedCountryItem) {
      setSelectedItem3(selectedCountryItem.title);
    }
    setIsOpen3(false);
  };

  const toggleDropdown4 = () => {
    setIsOpen4(!isOpen4);
    setIsRotated4(!isRotated4);
  };

  const [countries, setCountries] = useState<Country[]>([]);
  const [cities, setCities] = useState<City[]>([]);
  const token = localStorage.getItem('token');

  useEffect(() => {
    const fetchCountriesData = async () => {
      if (fetchedCountries.current) return;
      fetchedCountries.current = true;
      if (token) {
        const countryList = await fetchCountries(token);
        setCountries(countryList);
      }
    };
    fetchCountriesData();
    setLoading(false);
  }, [token]);

  useEffect(() => {
    const fetchCitiesData = async () => {
      if (fetchedCities.current) return;
      fetchedCities.current = true;
      if (token) {
        const cityList = await fetchCities(token);
        setCities(cityList);
      }
    };
    fetchCitiesData();
    setListageRanges(ageRangesData);
    setlistAge_group(ageTypesData);
  }, [token]);

  const openModal1 = async () => {
    setisLoading2(true);
    try {
      const queryParams = new URLSearchParams({
        "filter[name]": name,
        "filter[family]": last,
        "filter[national_code]": nid,
        "filter[notifier_name]": firstLast,
        "filter[notifier_family]": firstLast2,
        "filter[notifier_phone_number]": Phone,

      });

      const response = await fetch(
        `https://api.mafghoudin-zakerin.com/api/pilgrims/similar?${queryParams}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      if (response.ok) {
        navigate("/Inquiry")

      }
      const data = await response.json();
      setSimilarPilgrims(data[0]);
      console.log(data[0]);
      // setModalIsOpen(true);
    } catch (error) {
      console.error("Error fetching similar pilgrims:", error);
    } finally {
      setisLoading2(false); // Set loading to false once data is fetched or errored
    }
  };

  function Back() {
    navigate('/Servenlostpersons');
  }
  const handleItemClick4 = (event: any, id: number) => {
    const selectedCity = cities.find((city) => city.id === id);
    if (selectedCity) {
      setCity(selectedCity.id);
      setInputValue(event.target.textContent);
      setIsOpen4(false);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const filteredCities = cities.filter((city) =>
    city.title.toLowerCase().includes(inputValue.toLowerCase())
  );

  if (loading) {
    return (
      <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-500 bg-opacity-75 z-50">
        <div className="flex flex-col items-center">
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
          <div className="mt-4 text-white text-xl">لطفا صبر کنید</div>
        </div>

      </div>
    );
  }
  return (
    <div
      className="rss-backdrop"
      style={{
        background: "#f7f8f8",
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <ToastContainer />
      <>
        <div className="w-full h-full  flex  items-center flex-col justify-center ">
          <div className="w-[328px] h-[899px] bg-white flex flex-col items-center gap-[14px]   overflow-y-auto  overflow-x-hidden">
            <div className="  w-[328px] min-h-[20px]  flex   justify-between   items-center  mt-6 ">
              <span className="   mr-4">ثبت گمشده</span>
              <button
                onClick={Back}
                className=" w-[24px] h-[24px] flex justify-center items-center"
              >
                <img
                  src={arrowback}
                  className="  flex items-center  ml-2"
                  alt=""
                />
              </button>
            </div>

            <div className="w-[251px] min-h-[225px] flex justify-center flex-col items-center">
              <img
                src={previewImgUrl || personnel}
                alt=""
                width={150}
                height={150}
                className="rounded-full"
              />
              <label
                htmlFor="file-upload"
                className="w-[99px] my-2 py-1 text-[14px] font-kalameDemiBold text-gray-500 hover:text-black cursor-pointer"
              >
                <img className="mt-[-70px]" src={Edite2} alt="" />
              </label>
              <input
                id="file-upload"
                type="file"
                accept=".png,.jpg,.jpeg"
                hidden
                onChange={handleFileChange}
              />
            </div>
            <div className="relative mt-4">
              <label
                htmlFor="password"
                className="absolute right-4 -top-3 text-sm text-gray-700 bg-white "
              >
                نام{" "}
              </label>
              <input
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="block  w-[320px] min-h-[48px] px-4 py-2 text-gray-900 placeholder-gray-400 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#E99875]"
              />
            </div>
            <div className="relative mt-4">
              <label
                htmlFor="password"
                className="absolute right-4 -top-3 text-sm text-gray-700 bg-white "
              >
                نام خانوادگی
              </label>
              <input
                value={last}
                onChange={(e) => setLast(e.target.value)}
                className="block  w-[320px] min-h-[48px] px-4 py-2 text-gray-900 placeholder-gray-400 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#E99875]"
              />
            </div>
            <div className="  w-[299px]  flex   gap-8 ">
              <span>جنسیت</span>
              <div className="w-[49px] h-[24px]  flex justify-evenly items-center">
                <input
                  type="radio"
                  name="gender"
                  value="male"
                  checked={gender === "male"}

                  onChange={(e) => setGender(e.target.value)}
                />
                <div className="w-[60px] h-[22px] flex justify-center items-center ">
                  <span className="text-xs text-[#595959]">آقا</span>
                </div>
              </div>
              <div className="w-[60px] h-[24px] flex justify-evenly items-center ">
                <input
                  type="radio"
                  name="gender"
                  value="female"
                  checked={gender === "female"}

                  onChange={(e) => setGender(e.target.value)}
                />
                <div className="w-[26px] h-[22px] flex justify-center items-center">
                  <span className="text-xs text-[#595959]">خانم</span>
                </div>
              </div>
            </div>
            {/* <div className="w-[466px] h-[102px] flex flex-col gap-[25px]"> */}

            <div className=" w-[328px] h-[48px] flex  gap-2 justify-center">
              <div className="relative ">
                <label
                  htmlFor="password"
                  className="absolute right-4 -top-3 text-sm text-gray-700 bg-white "
                >
                  رده سنی{" "}
                </label>
                <button
                  className="  min-w-[156px]  flex  items-center  gap-7  justify-center  h-[48px] px-4 py-2 text-gray-900 placeholder-gray-400 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#E99875]"
                  onClick={toggleDropdown1}
                >
                  <div className="font-kalameDemiBold text-[#535353] leading-[21px] text-[14px] min-w-[100px] ">
                    {getAgeGroupText(age_group, selectedItem1)}
                  </div>
                  <img src={Aroow} alt="" />
                </button>

                {isOpen1 && (
                  <div className="absolute z-50  flex flex-col  gap-2  w-[156px] max-h-[250px] pr-[12px] pt-[10px] overflow-auto no-scrollbar justify-between overflow-y-scroll  overflow-x-hidden font-kalameRegular text-[14px] text-[#535353] mt-[6px] bg-white border border-gray-300 rounded-lg shadow-md ">
                    {listAge_group.map((item) => (
                      <>
                        <button
                          onClick={(event) => handleItemClick1(event, item.id)}
                          className="my-2 py-1 text-[15px] font-kalameDemiBold ml-2 text-gray-500 hover:text-black"
                        >
                          {item.title}
                        </button>
                      </>
                    ))}
                  </div>
                )}
              </div>
              <div className="relative ">
                <label
                  htmlFor="password"
                  className="absolute right-4 -top-3 text-sm text-gray-700 bg-white "
                >
                  بازه سنی{" "}
                </label>
                <button
                  className="  w-[156px] min-h-[48px] flex  items-center  gap-7  px-4 py-2 text-gray-900 placeholder-gray-400 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#E99875]"
                  onClick={toggleDropdown2}
                >
                  <div className="font-kalameDemiBold text-[#535353] leading-[21px] text-[14px] min-w-[90px] ">
                    {age_range ? getAgeRange(age_range) : selectedItem2}
                  </div>
                  <img src={Aroow} alt="" />
                </button>

                {isOpen2 && (
                  <div className="absolute z-50  flex flex-col  gap-2  w-[156px] max-h-[250px] pr-[12px] pt-[10px] overflow-auto no-scrollbar justify-between overflow-y-scroll  overflow-x-hidden font-kalameRegular text-[14px] text-[#535353] mt-[6px] bg-white border border-gray-300 rounded-lg shadow-md ">
                    {ListageRanges.map((item) => (
                      <>
                        <button
                          onClick={(event) => handleItemClick2(event, item.id)}
                          className="my-2 py-1 text-[15px] font-kalameDemiBold ml-2 text-gray-500 hover:text-black"
                        >
                          {item.range}
                        </button>
                      </>
                    ))}
                  </div>
                )}
              </div>
            </div>

            <div className=" w-[328px] h-[48px] flex  gap-2 justify-center">
              <div className="relative ">
                {/* <label
                  htmlFor="password"
                  className="absolute right-4 -top-3 text-sm text-gray-700 bg-white "
                >
                  کشور{" "}
                </label> */}
                <button
                  className="  w-[100px]  flex  items-center   gap-7 min-h-[48px] px-4 py-2 text-gray-900 placeholder-gray-400 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#E99875]"
                  onClick={toggleDropdown3}
                >
                  <div className="font-kalameDemiBold text-[#535353] leading-[21px] text-[14px] w-[80px] ">
                    {country ? getCountryTitle(country, countries) : selectedItem3 || "کشور"}
                  </div>
                  <img src={Aroow} alt="" />
                </button>

                {isOpen3 && (
                  <div className="absolute z-50  flex flex-col  gap-2  w-[100px] max-h-[250px] pr-[12px] pt-[10px] overflow-auto no-scrollbar justify-between overflow-y-scroll  overflow-x-hidden font-kalameRegular text-[14px] text-[#535353] mt-[6px] bg-white border border-gray-300 rounded-lg shadow-md ">
                    {countries.map((item) => (
                      <>
                        <button
                          onClick={(event) => handleItemClick3(event, item.id)}
                          className="my-2 py-1 text-[15px] font-kalameDemiBold ml-2 text-gray-500 hover:text-black"
                        >
                          {item.title}
                        </button>
                      </>
                    ))}
                  </div>
                )}
              </div>
              <div className="relative">
                <input
                  type="text"
                  value={city ? getCityTitle(city) : inputValue}
                  onChange={handleInputChange}
                  className={`w-[100px] min-h-[48px] px-4 py-2 text-gray-900 placeholder-gray-400 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#E99875] ${selectedCountry === 2 ? 'hidden' : ''}`}
                  placeholder='شهر'
                  onFocus={toggleDropdown4}
                />
                {isOpen4 && (
                  <div className="absolute z-50 flex flex-col gap-2 w-[100px] max-h-[250px] pr-[12px] pt-[10px] overflow-auto no-scrollbar justify-between overflow-y-scroll overflow-x-hidden font-kalameRegular text-[14px] text-[#535353] mt-[6px] bg-white border border-gray-300 rounded-lg shadow-md">
                    {filteredCities.map((item) => (
                      <button
                        key={item.id}
                        onClick={(event) => handleItemClick4(event, item.id)}
                        className="my-2 py-1 text-[15px] font-kalameDemiBold ml-2 text-gray-500 hover:text-black"
                      >
                        {item.title}
                      </button>
                    ))}
                  </div>
                )}
              </div>

              <div className="relative ">
                <input
                  placeholder='محله'
                  value={region}
                  onChange={(e) => setRegion(e.target.value)}
                  className="block w-[100px] min-h-[48px]  px-4 py-2 text-gray-900 placeholder-gray-400 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#E99875]"
                />
              </div>
            </div>

            <div className="relative mt-4">
              <label
                htmlFor="password"
                className="absolute right-4 -top-3 text-sm text-gray-700 bg-white "
              >
                توضیحات{" "}
              </label>
              <input
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className="block  w-[320px] min-h-[48px] px-4 py-2 text-gray-900 placeholder-gray-400 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#E99875]"
              />
            </div>
            <div className="relative mt-4">
              <label
                htmlFor="password"
                className="absolute right-4 -top-3 text-sm text-gray-700 bg-white "
              >
                مکان ملاقات{" "}
              </label>
              <input
                value={appointment}
                onChange={(e) => setAppointment(e.target.value)}
                className="block  w-[320px] min-h-[48px] px-4 py-2 text-gray-900 placeholder-gray-400 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#E99875]"
              />
            </div>

            <div className="relative mt-4">
              <label
                htmlFor="password"
                className="absolute right-4 -top-3 text-sm text-gray-700 bg-white "
              >
                همراهان{" "}
              </label>
              <input
                value={companions}
                onChange={(e) => setCompanions(e.target.value)}
                className="block  w-[320px] min-h-[48px] px-4 py-2 text-gray-900 placeholder-gray-400 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#E99875]"
              />
            </div>

            <div className="relative mt-4">
              <label
                htmlFor="password"
                className="absolute right-4 -top-3 text-sm text-gray-700 bg-white "
              >
                شماره تلفن ایرانی{" "}
              </label>
              <input
                type="text"
                inputMode="numeric"
                value={irn}
                onChange={ChengInput2}
                className="block  w-[320px] min-h-[48px] px-4 py-2 text-gray-900 placeholder-gray-400 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#E99875]"
              />
            </div>

            <div className="relative mt-4 mb-6">
              <label
                htmlFor="password"
                className="absolute right-4 -top-3 text-sm text-gray-700 bg-white "
              >
                شماره تلفن عراقی{" "}
              </label>
              <input
                type="text"
                inputMode="numeric"
                value={irq}
                onChange={ChengInput3}
                className="block  w-[320px] min-h-[48px] px-4 py-2 text-gray-900 placeholder-gray-400 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#E99875]"
              />
            </div>
            <div className=" w-[328px] min-h-[50px]       flex  items-center  justify-around">
              <div className="w-[200px] h-[22px] flex  items-center ">
                <Checkbox
                  checked={is_special === "1"}
                  onChange={handleCheckboxChange}
                  className="w-[24px] h-[24px] flex"
                />
                <p className="w-fit h-[21px] font-kalameDemiBold text-[14px] lesading-[21px] text-[#535353] ">
                  این فرد جزء افراد خاص هست
                </p>
              </div>
            </div>
            {/* <div className=" w-[328px] min-h-[50px]       flex  items-center  justify-around">
              <div className="w-[200px] h-[22px] flex  items-center ">
                <Checkbox
                  checked={only_pager === "1"}
                  onChange={handleOnlyPagerCheckboxChange}
                  className="w-[24px] h-[24px] flex"
                />
                <p className="w-fit h-[21px] font-kalameDemiBold text-[14px] lesading-[21px] text-[#535353] ">
                  نمایش برای پیجر
                </p>
              </div>
            </div> */}
            {/* <div className=" w-fit min-h-[50px]       flex  items-center  justify-around">
              <div className="w-fit h-[22px] flex  items-center ">
                <Checkbox
                  checked={is_iranian === "1"}
                  onChange={handleCheckboxChange2}
                  className="w-[24px] h-[24px] flex"
                />

                <p className="w-fit h-[21px] font-kalameDemiBold text-[14px] lesading-[21px] text-[#535353] ">
                  این فرد جزء افراد غیر ایرانی هست
                </p>
              </div>
            </div> */}
            <div className="  w-[299px] h-[60px]   flex ">
              <span className=" text-sm text-gray-700 text-center  min-w-[100px]">
                {" "}
                مشخصات مراجعه کننده
              </span>
            </div>
            <div className=" w-[320px] min-h-[200px] flex flex-col">
              <div className="relative mt-4 mb-6">
                <label
                  htmlFor="password"
                  className="absolute right-4 -top-3 text-sm text-gray-700 bg-white "
                >
                  نام{" "}
                </label>
                <input
                  value={firstLast}
                  onChange={(e) => setFirstLast(e.target.value)}
                  className="block  w-[320px] min-h-[48px] px-4 py-2 text-gray-900 placeholder-gray-400 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#E99875]"
                />
              </div>
              <div className="relative mt-4 mb-6">
                <label
                  htmlFor="password"
                  className="absolute right-4 -top-3 text-sm text-gray-700 bg-white "
                >
                  نام خانوادگی{" "}
                </label>
                <input
                  value={firstLast2}
                  onChange={(e) => setFirstLast2(e.target.value)}
                  className="block  w-[320px] min-h-[48px] px-4 py-2 text-gray-900 placeholder-gray-400 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#E99875]"
                />
              </div>

              <div className="relative mt-4 mb-6">
                <label
                  htmlFor="password"
                  className="absolute right-4 -top-3 text-sm text-gray-700 bg-white "
                >
                  نسبت{" "}
                </label>
                <input
                  value={Ratio}
                  onChange={(e) => setRatio(e.target.value)}
                  className="block  w-[320px] min-h-[48px] px-4 py-2 text-gray-900 placeholder-gray-400 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#E99875]"
                />
              </div>
              <div className="relative mt-4 mb-6">
                <label
                  htmlFor="password"
                  className="absolute right-4 -top-3 text-sm text-gray-700 bg-white "
                >
                  شماره تلفن
                </label>
                <input
                  value={Phone}
                  type="text"
                  inputMode="numeric"
                  onChange={ChengInput4}
                  className="block  w-[320px] min-h-[48px] px-4 py-2 text-gray-900 placeholder-gray-400 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#E99875]"
                />
              </div>
            </div>
          </div>
        </div>
      </>
      <button
        onClick={openModal1}
        className="w-[320px] min-h-[48px] bg-[#2E857D] flex justify-center items-center p-[8px,16px,8px,16px] rounded-[8px] mt-6 text-white bottom-0"
      >
        {isLoading2 ? (
          <svg
            className="animate-spin h-5 w-5 mr-3 text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A8.002 8.002 0 014 12H0c0 3.042 1.135 5.82 3 7.938l3-2.647z"
            ></path>
          </svg>
        ) : (
          "ثبت اطلاعات"
        )}
      </button>
    </div>
  );
};

export default Add;
