import React, { ChangeEventHandler, useEffect, useState } from "react";
import personnel from "../../../../../src/Assets/img/personnel.png";
import Edite from "../../../../../src/Assets/img/Edite.png";
import DatePicker from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import Icon from "react-multi-date-picker/components/icon";
import { Country } from "@/types/country";
import { City } from "@/types/city";
import Aroow from "../../../../Assets/Servant/Arrow.svg";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { Servant } from "../Registrar/Registrar";
import { getCityTitle } from "@/utils/getCityTitle";
import { getCountryTitle } from "@/utils/getCountryTitle";
import { convertToJalaali } from "@/utils/convertToJalaali";

type AgentType = {
  country_id: string | number;
  city_id: string | number;
};
const ServantEdite = () => {
  const { id } = useParams<{ id: string }>();
  const [selectedImage, setSelectedImage] = useState<File | undefined | any>(
    undefined
  );
  const [userId, setUserId] = useState("");
  const [previewImgUrl, setPreviewimgUrl] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [last, setLast] = useState<string>("");
  const [gender, setGender] = useState<string>("");
  const [irn, setIrn] = useState<string>("");
  const [irq, setIrq] = useState<string>("");
  const [country, setCountry] = useState<number | undefined | any>();
  const [city, setCity] = useState<number | undefined | any>();
  const [type, setType] = useState<string>("");
  const [start, setStart] = useState<Date | null>(null);
  const [end, setEnd] = useState<Date | null>(null);
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [ListsCountries, setListsCountries] = useState<Country[]>([]);
  const [isOpen1, setIsOpen1] = useState(false);
  const [isRotated1, setIsRotated1] = useState(false);
  const [selectedItem1, setSelectedItem1] = useState("");
  const [ListsCity, setListsCity] = useState<City[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasServants, setHasServants] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [inputValue, setInputValue] = useState<any>("");
  const [selectedItem4, setSelectedItem4] = useState("");
  const [isOpen4, setIsOpen4] = useState(false);
  const [isRotated4, setIsRotated4] = useState(false);
  const [isCountryTyped, setIsCountryTyped] = useState(false);
  const [isCityTyped, setIsCityTyped] = useState(false);
  const navigate = useNavigate();
  const [Agent, setAgent] = useState<AgentType>({
    country_id: "",
    city_id: "",
  });
  const handleFileChange: ChangeEventHandler<HTMLInputElement> = async (
    event
  ) => {
    const fileList = event.target.files as FileList;
    const file = fileList?.[0];
    setSelectedImage(file);
    if (!file) {
      return;
    }
    try {
      const imgUrl = await fileToDataString(file);
      setPreviewimgUrl(imgUrl);
    } catch (error) {
      console.error(error);
    }
  };
  const fileToDataString = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (reader.result) {
          resolve(reader.result as string);
        } else {
          reject(new Error("Failed to convert file to data URL"));
        }
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };
  const moment = require("jalali-moment");
  function persianToEnglishDigits(persianNumber: any) {
    if (typeof persianNumber !== "string") {
      return ""; // یا یک مقدار پی
    }
    const persianDigits = "۰۱۲۳۴۵۶۷۸۹";
    // استفاده از تابع بازگرداننده با نوع مناسب
    return persianNumber.replace(/[۰-۹]/g, function (match) {
      return persianDigits.indexOf(match).toString(); // تبدیل عدد به رشته
    });
  }

  const convertToGregorian = (date: any) => {
    return moment(date).format("YYYY-MM-DD");
  };
  const ChengInput2 = (e: React.ChangeEvent<HTMLInputElement>) => {
    let input = e.target.value.replace(/[^\s0-9]/g, "");
    if (input.length > 11) {
      return;
    }
    setIrn(input);
  };
  const ChengInput3 = (e: React.ChangeEvent<HTMLInputElement>) => {
    let input = e.target.value.replace(/[^\s0-9]/g, "");
    if (input.length > 11) {
      return;
    }
    setIrq(input);
  };
  const formatDate = (date: any) => {
    return new Intl.DateTimeFormat("fa-IR").format(date);
  };
  const handleChangeDate = (date: any) => {
    setStart(date ? new Date(date) : null);
  };
  const handleChangeDate2 = (date: any) => {
    setEnd(date ? new Date(date) : null);
  };
  const token = localStorage.getItem("token");
  useEffect(() => {
    const fetchData = async () => {
      try {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);
        myHeaders.append("Accept", "application/json");

        const requestOptions = {
          method: "GET",
          headers: myHeaders,
        };
        // Fetch countries
        const responseCountries = await fetch(
          "https://api.mafghoudin-zakerin.com/api/countries",
          requestOptions
        );
        if (!responseCountries.ok) {
          throw new Error("Failed to fetch countries");
        }
        const countriesData = await responseCountries.json();
        setListsCountries(countriesData);

        // Fetch cities
        const responseCities = await fetch(
          "https://api.mafghoudin-zakerin.com/api/cities",
          requestOptions
        );
        if (!responseCities.ok) {
          throw new Error("Failed to fetch cities");
        }
        const citiesData = await responseCities.json();
        setListsCity(citiesData);

        // Once data is fetched, set loading to false
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false); // Set loading to false on error too
      }
    };

    fetchData();
  }, []);

  const toggleDropdown1 = () => {
    setIsOpen1(!isOpen1);
    setIsRotated1(!isRotated1);
  };
  useEffect(() => {
    const cities = async () => {
      try {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token} `);
        myHeaders.append("Accept", "application/json");

        const requestOptions = {
          method: "GET",
          headers: myHeaders,
        };

        const response = await fetch(
          "https://api.mafghoudin-zakerin.com/api/cities",
          requestOptions
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        setListsCity(data);
        console.log(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    cities();
  }, []);
  const filteredCities = ListsCity.filter((city) =>
    city.title.toLowerCase().includes(inputValue.toLowerCase())
  );
  const handleItemClick3 = (event: any, id: number) => {
    const selectedCity = ListsCountries.find((city) => city.id === id);
    if (selectedCity) {
      setCountry(selectedCity.id);
      setSelectedItem1(event.target.textContent);
      setIsOpen1(false);
    }
  };
  const handleItemClick4 = (event: any, id: number) => {
    const selectedCity = ListsCity.find((city) => city.id === id);
    if (selectedCity) {
      setCity(selectedCity.id);
      setInputValue(event.target.textContent);
      setIsOpen4(false);
    }
  };
  const toggleDropdown4 = () => {
    setIsOpen4(!isOpen4);
    setIsRotated4(!isRotated4);
  };
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };
  useEffect(() => {
    const fetchPersonInfo = async () => {
      try {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);
        myHeaders.append("Accept", "application/json");

        const requestOptions = {
          method: "GET",
          headers: myHeaders,
        };
        const response = await fetch(
          `https://api.mafghoudin-zakerin.com/api/users/${id}`,
          requestOptions
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        setPreviewimgUrl(data.user.image_url);
        setUsername(data.user.username);
        //   setPassword(data.user)
        setStart(data.user.start_at);
        setEnd(data.user.expire_at);
        setName(data.user.user_data.name);
        setLast(data.user.user_data.family);
        setGender(data.user.user_data.gender);
        setIrn(data.user.user_data.iran_phone);
        setIrq(data.user.user_data.iraq_phone);
        setCountry(data.user.user_data.country_id);
        setCity(data.user.user_data.city_id);
        setType(data.user.roles[0].name);
        setUserId(data.user.user_data.user_id);
        // setLoading(false);
        console.log(data);
      } catch (error) {
        console.error("Error fetching data:", error);
        // setLoading(false);
      }
    };
    fetchPersonInfo();
  }, [id, token]);

  const updates = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading2(true);

    const userDetails = {
      name: name,
      family: last,
      gender: gender,
      iran_phone: irn,
      iraq_phone: irq,
      country_id: country,
      city_id: city,
      start_at: start ? convertToGregorian(moment(start).format("YYYY/MM/DD")) : null,
      expire_at: start ? convertToGregorian(moment(end).format("YYYY/MM/DD")) : null,
      type: type,
    };

    try {
      // Update user details (PUT request)
      const updateUserUrl = `https://api.mafghoudin-zakerin.com/api/users/update?user_id=${id}`;
      const updateUserRequestOptions = {
        method: "PUT",
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userDetails),
      };

      const updateUserResponse = await fetch(updateUserUrl, updateUserRequestOptions);
      if (!updateUserResponse.ok) {
        throw new Error("Failed to update user details");
      }
      const updateUserData = await updateUserResponse.json();
      console.log(updateUserData);

      // Update user image (POST request)
      const updateImageUrl = "https://api.mafghoudin-zakerin.com/api/users/updateImage";
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);
      myHeaders.append("Accept", "application/json");
      const formData = new FormData();
      formData.append("user_id", userId);
      formData.append("image", selectedImage);

      const updateImageRequestOptions: RequestInit = {
        method: "POST",
        headers: myHeaders,
        body: formData,
      };

      const updateImageResponse = await fetch(updateImageUrl, updateImageRequestOptions);
      if (!updateImageResponse.ok) {
        throw new Error("Failed to update user image");
      }
      const updateImageData = await updateImageResponse.text();
      console.log(updateImageData);

      // Navigate after successful updates
      navigate(`/Chosenserv/${id}`);
      // toast("گمشده با موفقیت ثبت شد");
    } catch (error: any) {
      console.error("Error:", error.message || "Network error occurred.");
      // toast.error(" ثبت نشد دوباره تلاش کنید");
    } finally {
      setIsLoading2(false);
    }
  };


  return (
    <div
      className={`flex flex-col justify-center items-center h-screen ${hasServants ? "overflow-auto" : "overflow-hidden"
        }`}
    >
      <div className="w-[366px] h-[899px] flex flex-col gap-10 items-center overflow-auto">
        <ToastContainer />
        {isLoading ? (
          <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-500 bg-opacity-75 z-50">
            <div className="flex flex-col items-center">
              <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
              <div className="mt-4 text-white text-xl">لطفا صبر کنید</div>
            </div>
          </div>
        ) : (
          <>
            <div className=" w-[366px] h-[899px]  flex flex-col  gap-10 items-center   overflow-auto ">
              <div className="w-[251px] min-h-[225px] flex justify-center flex-col items-center">
                <img
                  src={previewImgUrl ? previewImgUrl : personnel}
                  alt=""
                  width={150}
                  height={150}
                  className="rounded-full"
                />
                <label
                  htmlFor="file-upload"
                  className="w-[99px] my-2 py-1 text-[14px] font-kalameDemiBold text-gray-500 hover:text-black cursor-pointer"
                >
                  <img className="mt-[-70px]" src={Edite} alt="" />
                </label>
                <input
                  id="file-upload"
                  type="file"
                  accept=".png,.jpg,.jpeg"
                  hidden
                  onChange={handleFileChange}
                />
              </div>
              <div className="relative mt-4">
                <label
                  htmlFor="password"
                  className="absolute  flex   gap-1  right-4 -top-3 text-sm text-gray-700 bg-white px-1"
                >
                  <span className=" text-[red]    font-[20px]">*</span>
                  نام کاربری
                </label>
                <input
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  placeholder="نام کاربری"
                  className="block  w-[328px] min-h-[48px] px-4 py-2 text-gray-900 placeholder-gray-400 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#E99875]"
                />
              </div>
              {" "}
              <div className="relative mt-4  ">
                <label className="absolute flex gap-1 right-4 -top-3 text-sm text-gray-700 bg-white px-1">
                  <span className=" text-[red]    font-[20px]">*</span>
                  شروع
                </label>
                <div className="flex  gap-4 items-center  w-[328px] min-h-[48px] px-4 py-2 text-gray-900 placeholder-gray-400 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#E99875]">
                  <DatePicker
                    // value={start}
                    onChange={handleChangeDate}
                    render={<Icon />}
                    type="icon"
                    calendar={persian}
                    locale={persian_fa}
                    calendarPosition="bottom-right"
                  />
                  <p className="w-[65px] h-[25px] font-kalameRegular text-[14px] leading-[25.2px] text-[#535353] ">
                    {convertToJalaali(start) ? convertToJalaali(start) : formatDate(start)}
                  </p>
                </div>
              </div>
              <div className="relative mt-4  ">
                <label className="absolute flex gap-1 right-4 -top-3 text-sm text-gray-700 bg-white px-1">
                  <span className=" text-[red]    font-[20px]">*</span>
                  پایان{" "}
                </label>
                <div className="flex  gap-4 items-center  w-[328px] min-h-[48px] px-4 py-2 text-gray-900 placeholder-gray-400 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#E99875]">
                  <DatePicker
                    // value={end}
                    onChange={handleChangeDate2}
                    render={<Icon />}
                    type="icon"
                    calendar={persian}
                    locale={persian_fa}
                    calendarPosition="bottom-right"
                  />
                  <p className="w-[65px] h-[25px] font-kalameRegular text-[14px] leading-[25.2px] text-[#535353] ">
                    {convertToJalaali(end) ? convertToJalaali(end) : formatDate(end)}

                  </p>
                </div>
              </div>
              <div className="relative mt-4">
                <label
                  htmlFor="password"
                  className="absolute flex gap-1 right-4 -top-3 text-sm text-gray-700 bg-white px-1"
                >
                  <span className=" text-[red]    font-[20px]">*</span>
                  نام{" "}
                </label>
                <input
                  placeholder="نام"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="block  w-[328px] min-h-[48px] px-4 py-2 text-gray-900 placeholder-gray-400 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#E99875]"
                />
              </div>
              <div className="relative mt-4">
                <label
                  htmlFor="password"
                  className="absolute flex gap-1 right-4 -top-3 text-sm text-gray-700 bg-white px-1"
                >
                  <span className=" text-[red]    font-[20px]">*</span>
                  نام خانوادگی{" "}
                </label>
                <input
                  placeholder="نام خانوادگی"
                  value={last}
                  onChange={(e) => setLast(e.target.value)}
                  className="block  w-[328px] min-h-[48px] px-4 py-2   text-gray-900 placeholder-gray-400 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#E99875]"
                />
              </div>
              <div className="  w-[300px] h-[100px] flex items-center justify-between">
                <div className="flex justify-evenly items-center   gap-1">
                  <span className=" text-[red]    font-[20px]">*</span>
                  جنسیت :
                </div>
                <div className="w-[49px] h-[24px]  flex justify-evenly items-center">
                  <input
                    type="radio"
                    name="gender"
                    value="male"
                    onChange={(e) => setGender(e.target.value)}
                    checked={gender === "male"}
                    style={{
                      backgroundColor:
                        gender === "male" ? "pink" : "transparent",
                    }}
                  />
                  <div className="w-[60px] h-[22px] flex justify-center items-center ">
                    <span className="text-gray-900 w-[17px] h-[22px] text-[14px]  font-semibold">
                      آقا
                    </span>
                  </div>
                </div>
                <div className="w-[60px] h-[24px] flex justify-evenly items-center ">
                  <input
                    type="radio"
                    name="gender"
                    value="female"
                    onChange={(e) => setGender(e.target.value)}
                    style={{
                      backgroundColor:
                        gender === "female" ? "pink" : "transparent",
                    }}
                  />
                  <div className="w-[26px] h-[22px] flex justify-center items-center">
                    <span className=" text-gray-900  w-[17px] h-[22px] text-[14px]    font-semibold">
                      خانم
                    </span>
                  </div>
                </div>
              </div>
              <div className="relative mt-4">
                <label
                  htmlFor="password"
                  className="absolute flex gap-1 right-4 -top-3 text-sm text-gray-700 bg-white px-1"
                >
                  شماره ایرانی{" "}
                </label>
                <input
                  placeholder="شماره ایرانی"
                  value={irn}
                  onChange={ChengInput2}
                  className="block  w-[328px] min-h-[48px] px-4 py-2 text-gray-900 placeholder-gray-400 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#E99875]"
                />
              </div>
              <div className="relative mt-4">
                <label
                  htmlFor="password"
                  className="absolute right-4 -top-3 text-sm text-gray-700 bg-white px-1"
                >
                  شماره عراقی{" "}
                </label>
                <input
                  placeholder="شماره عراقی"
                  value={irq}
                  onChange={ChengInput3}
                  className="block  w-[328px] min-h-[48px] px-4 py-2 text-gray-900 placeholder-gray-400 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#E99875]"
                />
              </div>
              <div className="relative ">
                <label
                  htmlFor="password"
                  className="absolute right-4 -top-3 text-sm text-gray-700 bg-white "
                >
                  کشور{" "}
                </label>
                <button
                  className="w-[328px] min-h-[48px]     py-2 text-gray-900 placeholder-gray-400 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#E99875]"
                  onClick={toggleDropdown1}
                >
                  <div className="font-kalameDemiBold text-[#535353] leading-[21px] text-[14px] w-[80px] ">
                    {country ? getCountryTitle(country, ListsCountries) : selectedItem1}
                  </div>
                </button>

                {isOpen1 && (
                  <div className="absolute z-50  flex flex-col  gap-2  w-[100px] max-h-[250px] pr-[12px] pt-[10px] overflow-auto no-scrollbar justify-between overflow-y-scroll  overflow-x-hidden font-kalameRegular text-[14px] text-[#535353] mt-[6px] bg-white border border-gray-300 rounded-lg shadow-md ">
                    {ListsCountries.map((item) => (
                      <>
                        <button
                          onClick={(event) => handleItemClick3(event, item.id)}
                          className="my-2 py-1 text-[15px] font-kalameDemiBold ml-2 text-gray-500 hover:text-black"
                        >
                          {item.title}
                        </button>
                      </>
                    ))}
                  </div>
                )}
              </div>
              <div className="relative">
                <label
                  htmlFor="city"
                  className="absolute right-4 -top-3 text-sm text-gray-700 bg-white"
                >
                  شهر
                </label>
                <input
                  type="text"
                  value={city ? getCityTitle(city, ListsCity) : inputValue}
                  onChange={handleInputChange}
                  className="w-[328px] min-h-[48px] px-4 py-2 placeholder:text-[14px]   placeholder:font-kalameDemiBold text-[#535353]    placeholder-gray-400 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#E99875]"
                  placeholder={selectedItem4}
                  onFocus={toggleDropdown4}
                />
                {isOpen4 && (
                  <div className="absolute z-50 flex  flex-col gap-2 w-[328px] max-h-[250px] pr-[12px] pt-[10px] overflow-auto no-scrollbar justify-between overflow-y-scroll overflow-x-hidden font-kalameRegular text-[14px] text-[#535353] mt-[6px] bg-white border border-gray-300 rounded-lg shadow-md">
                    {filteredCities.map((item) => (
                      <button
                        key={item.id}
                        onClick={(event) => handleItemClick4(event, item.id)}
                        className="my-2 py-1 text-[15px] font-kalameDemiBold ml-2 text-gray-500 hover:text-black"
                      >
                        {item.title}
                      </button>
                    ))}
                  </div>
                )}
              </div>
              <div className="  h-[100px] w-[300px] flex items-center justify-between">
                <div className="flex justify-evenly items-center gap-1">
                  <span className=" text-[red]    font-[20px]">*</span>
                  نقش خادم :
                </div>
                <div className="w-[90px] h-[24px]  flex justify-evenly items-center">
                  <input
                    type="radio"
                    name="type"
                    value="servant"
                    checked={type === "servant"}
                    onChange={(e) => setType(e.target.value)}
                    style={{
                      backgroundColor:
                        type === "servant" ? "pink" : "transparent",
                    }}
                  />
                  <div className="w-[60px] h-[22px] flex justify-center items-center ">
                    <span className="text-xs text-[#595959]">ثبت کننده</span>
                  </div>
                </div>
                <div className="w-[60px] h-[24px] flex justify-evenly items-center ">
                  <input
                    type="radio"
                    name="type"
                    value="pager"
                    checked={type === "pager"}
                    onChange={(e) => setType(e.target.value)}
                    style={{
                      backgroundColor:
                        type === "pager" ? "pink" : "transparent",
                    }}
                  />
                  <div className="w-[26px] h-[22px] flex justify-center items-center">
                    <span className="text-xs text-[#595959]">پیجر</span>
                  </div>
                </div>
              </div>
              <button
                onClick={updates}
                className=" w-[328px] min-h-[48px] mb-3 flex justify-center items-center rounded-[12px] bg-[#2E857D] text-white bottom-0"
              >
                {isLoading2 ? (
                  <svg
                    className="animate-spin h-5 w-5 mr-3 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A8.002 8.002 0 014 12H0c0 3.042 1.135 5.82 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                ) : (
                  "افزودن خادم"
                )}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
export default ServantEdite;
