import React, { useEffect } from 'react'
import noting from "../../../Assets/Nothing.png"
export default function Nothing2() {

  useEffect(() => {
    // اضافه کردن کلاس به بدنه در هنگام نصب کامپوننت
    document.body.classList.add('no-scroll');
    // حذف کلاس از بدنه در هنگام لغو نصب کامپوننت
    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, []);
    return (
        <>
            <div className=' flex flex-col gap-8 w-[328px]  h-[600px]  justify-center items-center    '>
                <div className=' w-[111px] h-[121px] flex justify-center items-center'>
                    <img src={noting} alt=''  />
                </div>
                <div className=' w-[168px] h-[24px] flex justify-center items-center'>
                    <p className=' text-[#8C8C8C] text-xs'>در حال بارگزاری</p>
                </div>

                  {/* <div className='  w-[328px]  h-[50%]  bg-red-400'></div> */}
            </div>

        </>
    )
}
