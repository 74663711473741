import React, { ChangeEventHandler, useEffect, useState, useRef } from "react";
import personnel from "../../../../../src/Assets/img/personnel.png";
import Edite from "../../../../../src/Assets/img/Edite.png";
import DatePicker from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import Icon from "react-multi-date-picker/components/icon";
import { Country } from "../../../../types/country";
import { City } from "../../../../types/city";
import { fetchCountries } from '@/api/countriesApi';
import { fetchCities } from '@/api/cityAPI';
import Aroow from "../../../../Assets/Servant/Arrow.svg";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
type AgentType = {
  country_id: string | number;
  city_id: string | number;
};


const Addserv = () => {
  const [selectedImage, setSelectedImage] = useState<File | undefined | any>(undefined)
  const [previewImgUrl, setPreviewimgUrl] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [last, setLast] = useState<string>("");
  const [gender, setGender] = useState<string>("");
  const [irn, setIrn] = useState<string>("");
  const [irq, setIrq] = useState<string>("");
  const [country, setCountry] = useState<number | undefined | any>();
  const [city, setCity] = useState<number | undefined | any>();
  const [type, setType] = useState<string>("");
  const [start, setStart] = useState(new Date());
  const [end, setEnd] = useState(new Date());
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [ListsCountries, setListsCountries] = useState<Country[]>([]);
  const [isOpen1, setIsOpen1] = useState(false);
  const [isRotated1, setIsRotated1] = useState(false);
  const [selectedItem1, setSelectedItem1] = useState("");
  const [ListsCity, setListsCity] = useState<City[]>([]);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isRotated2, setIsRotated2] = useState(false);
  const [selectedItem2, setSelectedItem2] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isFieldFilled, setIsFieldFilled] = useState<boolean>(false);
  const [hasServants, setHasServants] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [selectedItem4, setSelectedItem4] = useState("");
  const [isOpen4, setIsOpen4] = useState(false);
  const [isRotated4, setIsRotated4] = useState(false);
  const [isCountryTyped, setIsCountryTyped] = useState(false);
  const [isCityTyped, setIsCityTyped] = useState(false);
  const fetchedCountries = useRef(false);
  const fetchedCities = useRef(false);
  const navigate = useNavigate();
  const [Agent, setAgent] = useState<AgentType>({
    country_id: '',
    city_id: ''
  });

  const [formError, setFormError] = useState<{ [key: string]: boolean }>({
    username: false,
    password: false,
    start: false,
    end: false,
    name: false,
    last: false,
    nid: false,
    gender: false,
    irn: false,
    irq: false,
    country: false,
    city: false,
    type: false,
  });
  const handleFileChange: ChangeEventHandler<HTMLInputElement> = async (
    event
  ) => {
    const fileList = event.target.files as FileList;
    const file = fileList?.[0];
    setSelectedImage(file);
    if (!file) {
      return;
    }
    try {
      const imgUrl = await fileToDataString(file);
      setPreviewimgUrl(imgUrl);
    } catch (error) {
      console.error(error);
    }
  };


  const fileToDataString = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (reader.result) {
          resolve(reader.result as string);
        } else {
          reject(new Error("Failed to convert file to data URL"));
        }
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };
  const moment = require("jalali-moment");
  function persianToEnglishDigits(persianNumber: any) {
    if (typeof persianNumber !== "string") {
      return ""; // یا یک مقدار پی  
    }
    const persianDigits = "۰۱۲۳۴۵۶۷۸۹";
    //استفاده از تابع بازگرداننده با نوع مناسب
    return persianNumber.replace(/[۰-۹]/g, function (match) {
      return persianDigits.indexOf(match).toString(); // تبدیل عدد به رشته
    });
  }

  const convertToGregorian = (shamsiDate: any) => {
    const shamsiDates = persianToEnglishDigits(shamsiDate);
    return moment(shamsiDates, "jYYYY/jMM/jDD").format("YYYY-MM-DD");
  };
  const ChengInput2 = (e: React.ChangeEvent<HTMLInputElement>) => {
    let input = e.target.value.replace(/[^\s0-9]/g, "");
    if (input.length > 11) {
      return;
    }

    setIrn(input);
  };

  const ChengInput3 = (e: React.ChangeEvent<HTMLInputElement>) => {
    let input = e.target.value.replace(/[^\s0-9]/g, "");
    if (input.length > 11) {
      return;
    }
    setIrq(input);
  };
  const handelclick = async () => {
    const token = localStorage.getItem("token");

    if (!token) {
      console.error("Token not found in localStorage");
      return;
    }

    if (
      !username ||
      !password ||
      !name ||
      !last ||
      !gender ||
      !country ||
      !city ||
      !type ||
      !start ||
      !end
    ) {
      toast.error("لطفا تمامی فیلدهای ضروری را پر کنید", { position: "top-center" });
      return;
    }

    const headers = {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    };

    const formdata = new FormData();
    formdata.append("username", username);
    formdata.append("password", password);
    formdata.append("start_at", convertToGregorian(formatDate(start)));
    formdata.append("expire_at", convertToGregorian(formatDate(end)));
    formdata.append("name", name);
    formdata.append("family", last);
    formdata.append("gender", gender);
    formdata.append("iran_phone", irn);
    formdata.append("iraq_phone", irq);
    formdata.append("country_id", country);
    formdata.append("city_id", city);
    formdata.append("type", type);

    if (selectedImage) {
      formdata.append("image", selectedImage);
    } else {
      formdata.append("image", "undefined");
    }

    setIsLoading2(true);
    try {
      const response = await axios.post(
        "https://api.mafghoudin-zakerin.com/api/users/addUser",
        formdata,
        { headers }
      );

      toast.success(`خادم با موفقیت ثبت شد`, { position: "bottom-center" });

      // Clear form fields after successful submission
      setTimeout(() => {
        setUsername("");
        setPassword("");
        setName("");
        setLast("");
        setGender("");
        setIrn("");
        setIrq("");
        setCountry(undefined);
        setCity(undefined);
        setType("");
        setStart(new Date());
        setEnd(new Date());
        setSelectedImage(undefined);
        setPreviewimgUrl("");
        navigate("/Servantpage");
      }, 3000);

    } catch (error) {
      toast.error(`خطای شبکه در ثبت خدمت دهنده`, { position: "top-center" });
    } finally {
      setIsLoading2(false);
    }
  };
  const handleChangeDate = (date: any) => {
    setStart(date);
  };

  const formatDate = (date: number | Date | undefined) => {
    return new Intl.DateTimeFormat("fa-IR").format(date);
  };

  const handleChangeDate2 = (date: any) => {
    setEnd(date);
  };
  const token = localStorage.getItem("token");

  useEffect(() => {
    const fetchCountriesData = async () => {
      if (fetchedCountries.current) return;
      fetchedCountries.current = true;
      if (token) {
        const countryList = await fetchCountries(token);
        setListsCountries(countryList);
      }
    };
    fetchCountriesData();
    setIsLoading(false);
  }, [token]);

  useEffect(() => {
    const fetchCitiesData = async () => {
      if (fetchedCities.current) return;
      fetchedCities.current = true;
      if (token) {
        const cityList = await fetchCities(token);
        setListsCity(cityList);
      }
    };
    fetchCitiesData();
  }, [token]);

  const toggleDropdown1 = () => {
    setIsOpen1(!isOpen1);
    setIsRotated1(!isRotated1);
  };

  const handleItemClick1 = (event: any, id: number) => {
    setCountry(id);
    setSelectedItem1(event.target.textContent);
    setIsOpen1(false);
  };

  useEffect(() => {
    const toggleDropdown2 = () => {
      setIsOpen2(!isOpen2);
      setIsRotated2(!isRotated2);
    };

    const handleItemClick2 = (event: any, id: number) => {
      setCity(id);
      setSelectedItem2(event.target.textContent);
      setIsOpen2(false);
    };
  }, []);
  const handleCountryChange = (e: any) => {
    const value = e.target.value;
    setCountry(value);
    setAgent({ ...Agent, country_id: value });
    setIsCountryTyped(value !== '');
    setSelectedItem1(value);
    if (value === '') setIsOpen1(true);
    else setIsOpen4(false);
  };

  const handleCityChange = (e: any) => {
    const value = e.target.value;
    setCity(value);
    setAgent({ ...Agent, city_id: value });
    setIsCityTyped(value !== '');
    setInputValue(value);
    if (value === '') setIsOpen4(true);
  };
  const filteredCities = ListsCity.filter((city) =>
    city.title.toLowerCase().includes(inputValue.toLowerCase())
  );
  const handleCountrySelect = (event: any, id: number) => {
    const value = event.target.textContent;
    setSelectedItem1(value);
    setAgent({ ...Agent, country_id: id });
    setCountry(id);
    setIsCountryTyped(false);
    setIsOpen1(false);
    setIsOpen4(true)
  };

  const handleCitySelect = (event: any, id: number) => {
    const value = event.target.textContent;
    setInputValue(value);
    setAgent({ ...Agent, city_id: id });
    setCity(id);
    setIsCityTyped(false);
    setIsOpen4(false);
  };

  const handleItemClick3 = (event: any, id: number) => {
    const selectedCity = ListsCountries.find((city) => city.id === id);
    if (selectedCity) {
      setCountry(selectedCity.id);
      setSelectedItem1(event.target.textContent);
      setIsOpen1(false);
    }
  };

  const handleItemClick4 = (event: any, id: number) => {
    const selectedCity = ListsCity.find((city) => city.id === id);
    if (selectedCity) {
      setCity(selectedCity.id);
      setInputValue(event.target.textContent);
      setIsOpen4(false);
    }
  };
  const toggleDropdown4 = () => {
    setIsOpen4(!isOpen4);
    setIsRotated4(!isRotated4);
  };
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };
  console.log(convertToGregorian(formatDate(start)));
  console.log(convertToGregorian(formatDate(end)));
  console.log(formatDate(start));
  console.log(formatDate(end));
  return (
    <div
      className={`flex flex-col justify-center items-center h-screen ${hasServants ? "overflow-auto" : "overflow-hidden"
        }`}
    >
      <div className="w-[366px] h-[899px] flex flex-col gap-10 items-center overflow-auto">
        <ToastContainer />
        {isLoading ? (
          <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-500 bg-opacity-75 z-50">
            <div className="flex flex-col items-center">
              <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
              <div className="mt-4 text-white text-xl">لطفا صبر کنید</div>
            </div>
          </div>
        ) : (
          <>
            <div className=" w-[366px] h-[899px]  flex flex-col  gap-10 items-center   overflow-auto ">
              <div className="w-[251px] min-h-[225px] flex justify-center flex-col items-center">
                <img
                  src={previewImgUrl || personnel}
                  alt=""
                  width={150}
                  height={150}
                  className="rounded-full"
                />
                <label
                  htmlFor="file-upload"
                  className="w-[99px] my-2 py-1 text-[14px] font-kalameDemiBold text-gray-500 hover:text-black cursor-pointer"
                >
                  <img className="mt-[-70px]" src={Edite} alt="" />
                </label>
                <input
                  id="file-upload"
                  type="file"
                  accept=".png,.jpg,.jpeg"
                  hidden
                  onChange={handleFileChange}
                />
              </div>

              <div className="relative mt-4">
                <label
                  htmlFor="password"
                  className="absolute  flex   gap-1  right-4 -top-3 text-sm text-gray-700 bg-white px-1"
                >
                  <span className=" text-[red]    font-[20px]">*</span>
                  نام کاربری
                </label>
                <input
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  placeholder="نام کاربری"
                  className="block  w-[328px] min-h-[48px] px-4 py-2 text-gray-900 placeholder-gray-400 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#E99875]"
                />
              </div>
              <div className="relative mt-4">
                <label
                  htmlFor="password"
                  className="absolute  flex gap-1 right-4 -top-3 text-sm text-gray-700 bg-white px-1"
                >
                  <span className=" text-[red]    font-[20px]">*</span>
                  رمز عبور
                </label>
                <input
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="رمز عبور"
                  type="password"
                  className="block  w-[328px] min-h-[48px] px-4 py-2 text-gray-900 placeholder-gray-400 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#E99875]"
                />
              </div>
              <div className="relative mt-4  ">
                <label className="absolute flex gap-1 right-4 -top-3 text-sm text-gray-700 bg-white px-1">
                  <span className=" text-[red]    font-[20px]">*</span>
                  شروع
                </label>
                <div className="flex  gap-4 items-center  w-[328px] min-h-[48px] px-4 py-2 text-gray-900 placeholder-gray-400 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#E99875]">
                  <DatePicker
                    value={start}
                    onChange={handleChangeDate}
                    render={<Icon />}
                    type="icon"
                    calendar={persian}
                    locale={persian_fa}
                    calendarPosition="bottom-right"
                  />
                  <p className="w-[65px] h-[25px] font-kalameRegular text-[14px] leading-[25.2px] text-[#535353] ">
                    {formatDate(start)}
                  </p>
                </div>
              </div>
              <div className="relative mt-4  ">
                <label className="absolute flex gap-1 right-4 -top-3 text-sm text-gray-700 bg-white px-1">
                  <span className=" text-[red]    font-[20px]">*</span>
                  پایان{" "}
                </label>
                <div className="flex  gap-4 items-center  w-[328px] min-h-[48px] px-4 py-2 text-gray-900 placeholder-gray-400 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#E99875]">
                  <DatePicker
                    value={end}
                    onChange={handleChangeDate2}
                    render={<Icon />}
                    type="icon"
                    calendar={persian}
                    locale={persian_fa}
                    calendarPosition="bottom-right"
                  />
                  <p className="w-[65px] h-[25px] font-kalameRegular text-[14px] leading-[25.2px] text-[#535353] ">
                    {formatDate(end)}
                  </p>
                </div>
              </div>

              <div className="relative mt-4">
                <label
                  htmlFor="password"
                  className="absolute flex gap-1 right-4 -top-3 text-sm text-gray-700 bg-white px-1"
                >
                  <span className=" text-[red]    font-[20px]">*</span>
                  نام{" "}
                </label>
                <input
                  placeholder="نام"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="block  w-[328px] min-h-[48px] px-4 py-2 text-gray-900 placeholder-gray-400 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#E99875]"
                />
              </div>
              <div className="relative mt-4">
                <label
                  htmlFor="password"
                  className="absolute flex gap-1 right-4 -top-3 text-sm text-gray-700 bg-white px-1"
                >
                  <span className=" text-[red]    font-[20px]">*</span>
                  نام خانوادگی{" "}
                </label>
                <input
                  placeholder="نام خانوادگی"
                  value={last}
                  onChange={(e) => setLast(e.target.value)}
                  className="block  w-[328px] min-h-[48px] px-4 py-2   text-gray-900 placeholder-gray-400 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#E99875]"
                />
              </div>
              <div className="  w-[300px] h-[100px] flex items-center justify-between">
                <div className="flex justify-evenly items-center   gap-1">
                  <span className=" text-[red]    font-[20px]">*</span>
                  جنسیت :
                </div>
                <div className="w-[49px] h-[24px]  flex justify-evenly items-center">
                  <input
                    type="radio"
                    name="gender"
                    value="male"
                    onChange={(e) => setGender(e.target.value)}
                  />
                  <div className="w-[60px] h-[22px] flex justify-center items-center ">
                    <span className="text-gray-900 w-[17px] h-[22px] text-[14px]  font-semibold">
                      آقا
                    </span>
                  </div>
                </div>
                <div className="w-[60px] h-[24px] flex justify-evenly items-center ">
                  <input
                    type="radio"
                    name="gender"
                    value="female"
                    onChange={(e) => setGender(e.target.value)}
                  />
                  <div className="w-[26px] h-[22px] flex justify-center items-center">
                    <span className=" text-gray-900  w-[17px] h-[22px] text-[14px]    font-semibold">
                      خانم
                    </span>
                  </div>
                </div>
              </div>
              <div className="relative mt-4">
                <label
                  htmlFor="password"
                  className="absolute flex gap-1 right-4 -top-3 text-sm text-gray-700 bg-white px-1"
                >
                  شماره ایرانی{" "}
                </label>
                <input
                  type="text"
                  inputMode="numeric"
                  placeholder="شماره ایرانی"
                  value={irn}
                  onChange={ChengInput2}
                  className="block  w-[328px] min-h-[48px] px-4 py-2 text-gray-900 placeholder-gray-400 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#E99875]"
                />
              </div>
              <div className="relative mt-4">
                <label
                  htmlFor="password"
                  className="absolute right-4 -top-3 text-sm text-gray-700 bg-white px-1"
                >
                  شماره عراقی{" "}
                </label>
                <input
                  type="text"
                  inputMode="numeric"
                  placeholder="شماره عراقی"
                  value={irq}
                  onChange={ChengInput3}
                  className="block  w-[328px] min-h-[48px] px-4 py-2 text-gray-900 placeholder-gray-400 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#E99875]"
                />
              </div>
              <div className="relative">
                <label htmlFor="country" className="absolute right-4 -top-3 text-sm text-gray-700 bg-white">
                  <span className="text-[red] font-20px">*</span>
                  کشور
                </label>
                <input
                  type="text"
                  value={selectedItem1}
                  onChange={handleCountryChange}
                  className="w-[328px] min-h-[48px] px-4 py-2 text-gray-900 placeholder-gray-400 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#E99875]"
                  onFocus={toggleDropdown1}
                />
                {isOpen1 && (
                  <div className="absolute z-50 flex flex-col gap-2 w-[328px] max-h-[250px] pr-[12px] pt-[10px] overflow-auto no-scrollbar justify-between overflow-y-scroll overflow-x-hidden font-kalameRegular text-[14px] text-[#535353] mt-[6px] bg-white border border-gray-300 rounded-lg shadow-md">
                    {ListsCountries.filter((item, index) => index === 0).map((item) => (
                      <button
                        key={item.id}
                        onClick={(event) => handleItemClick3(event, item.id)}
                        className="my-2 py-1 text-[15px] font-kalameDemiBold ml-2 text-gray-500 hover:text-black"
                      >
                        {item.title}
                      </button>
                    ))}
                  </div>
                )}
              </div>

              <div className="relative">
                <label
                  htmlFor="city"
                  className="absolute right-4 -top-3 text-sm text-gray-700 bg-white"
                >
                  شهر
                </label>
                <input
                  type="text"
                  value={inputValue}
                  onChange={handleInputChange}
                  className="w-[328px] min-h-[48px] px-4 py-2 text-gray-900 placeholder-gray-400 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#E99875]"
                  placeholder={selectedItem4}
                  onFocus={toggleDropdown4}
                />
                {isOpen4 && (
                  <div className="absolute z-50 flex flex-col gap-2 w-[328px] max-h-[250px] pr-[12px] pt-[10px] overflow-auto no-scrollbar justify-between overflow-y-scroll overflow-x-hidden font-kalameRegular text-[14px] text-[#535353] mt-[6px] bg-white border border-gray-300 rounded-lg shadow-md">
                    {filteredCities.map((item) => (
                      <button
                        key={item.id}
                        onClick={(event) => handleItemClick4(event, item.id)}
                        className="my-2 py-1 text-[15px] font-kalameDemiBold ml-2 text-gray-500 hover:text-black"
                      >
                        {item.title}
                      </button>
                    ))}
                  </div>
                )}
              </div>


              <div className="  h-[100px] w-[300px] flex items-center justify-between">
                <div className="flex justify-evenly items-center gap-1">
                  <span className=" text-[red]    font-[20px]">*</span>
                  نقش خادم :
                </div>
                <div className="w-[90px] h-[24px]  flex justify-evenly items-center">
                  <input
                    type="radio"
                    name="type"
                    value="servant"
                    onChange={(e) => setType(e.target.value)}
                  />
                  <div className="w-[60px] h-[22px] flex justify-center items-center ">
                    <span className="text-xs text-[#595959]">ثبت کننده</span>
                  </div>
                </div>
                <div className="w-[60px] h-[24px] flex justify-evenly items-center ">
                  <input
                    type="radio"
                    name="type"
                    value="pager"
                    onChange={(e) => setType(e.target.value)}
                  />
                  <div className="w-[26px] h-[22px] flex justify-center items-center">
                    <span className="text-xs text-[#595959]">پیجر</span>
                  </div>
                </div>
              </div>
              <button
                className=" w-[328px] min-h-[48px] mb-3 flex justify-center items-center rounded-[12px] bg-[#2E857D] text-white bottom-0"
                onClick={handelclick}
              >
                {/* <div className=" w-[77px] h-[24px]  flex justify-center items-center">
                  <span className=" text-sm text-white">افزودن خادم</span>
                </div> */}
                {isLoading2 ? (
                  <svg
                    className="animate-spin h-5 w-5 mr-3 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A8.002 8.002 0 014 12H0c0 3.042 1.135 5.82 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                ) : (
                  "افزودن خادم"
                )}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
export default Addserv;
// import React, { ChangeEventHandler, useState } from 'react'

// export default function Addservant() {
//   const [selectedImage, setSelectedImage] = useState<File | undefined | any>(undefined)
//   const [previewImgUrl, setPreviewimgUrl] = useState<string>("");
//   const handleFileChange: ChangeEventHandler<HTMLInputElement> = async (
//     event: any
//   ) => {
//     const fileList = event.target.files as FileList;
//     const file = fileList?.[0];
//     setSelectedImage(file);
//     if (!file) {
//       return;
//     }
//     try {
//       const imgUrl = await fileToDataString(file);
//       setPreviewimgUrl(imgUrl);
//     } catch (error) {
//       console.error(error);
//     }
//   };


//   const fileToDataString = (file: File): Promise<string> => {
//     return new Promise((resolve, reject) => {
//       const reader = new FileReader();
//       reader.onloadend = () => {
//         if (reader.result) {
//           resolve(reader.result as string);
//         } else {
//           reject(new Error("Failed to convert file to data URL"));
//         }
//       };
//       reader.onerror = reject;
//       reader.readAsDataURL(file);
//     });
//   };
//   const handelclick = async () => {
//     const token = localStorage.getItem("token");
//     if (!token) {
//       console.error("Token not found in localStorage");
//       return;
//     }
//     const myHeaders = new Headers();
//     myHeaders.append("Authorization", `Bearer ${token}`);
//     myHeaders.append("Accept", "application/json");
//     const formdata = new FormData();
//     formdata.append("username", "sherv");
//     formdata.append("password", "2005");
//     formdata.append("start_at", "2024-08-07");
//     formdata.append("expire_at", "2024-08-20");
//     formdata.append("name", "شروین");
//     formdata.append("family", "منصوری");
//     formdata.append("national_code", "0200571612");
//     formdata.append("gender", "male");
//     formdata.append("iran_phone", "09909264111");
//     formdata.append("iraq_phone", "07909632102");
//     formdata.append("country_id", "1");
//     formdata.append("city_id", "2");
//     formdata.append("type", "servant");
//     if (selectedImage) {
//       formdata.append("image", selectedImage);
//     } else {
//       formdata.append("image", "undefined");
//     }
//     const requestOptions: RequestInit = {
//       method: "POST",
//       headers: myHeaders,
//       body: formdata,
//     };
//     try {
//       const response = await fetch(
//         "https://api.mafghoudin-zakerin.com/api/users/addUser",
//         requestOptions
//       ); if (response.ok) {
//         console.log(response);
//       }
//       const result = await response.json();
//       console.log(result, "kir");
//     } catch (error: any) {
//       console.error(`خطای شبکه در ثبت خدمت دهنده`, {
//         position: "top-center",
//       });
//     }
//   };
//   return (
//     <div className=" flex justify-center items-center flex-col">
//       <img
//         src={previewImgUrl}
//         alt=""
//         width={150}
//         height={150}
//         className="rounded-full"
//       />
//       <label
//         htmlFor="file-upload"
//         className="w-[99px] my-2 py-1 text-[14px] font-kalameDemiBold text-gray-500 hover:text-black cursor-pointer"
//       >
//         <img className="mt-[-70px]" src={Edite} alt="" />
//       </label >
//       <input
//         id="file-upload"
//         type="file"
//         accept=".png,.jpg,.jpeg"
//         hidden
//         onChange={handleFileChange}
//       />
//       <button onClick={handelclick}>add</button>
//     </div>
//   )
// }
