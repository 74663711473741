import axios from 'axios';

export const fetchCountries = async (token: string) => {
  const response = await axios.get(
    "https://api.mafghoudin-zakerin.com/api/countries",
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json"
      }
    }
  );
  return response.data;
};