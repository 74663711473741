import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Modal = () => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const handleUpdateServent = () => {
    setSelectedOption('edit');
    navigate(`/ServantEdite/${id}`);
  };

  const handleChangePassword = () => {
    setSelectedOption('changePassword');
    navigate(`/change-password/${id}`);
  };

  const handleUserDelete = () => {
    setSelectedOption('finish');
    setIsModalOpen(true);
  };

  const confirmUserDelete = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`https://api.mafghoudin-zakerin.com/api/users/${id}`, {
        method: "DELETE",
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        toast.success("کاربر با موفقیت حذف شد.");
        setTimeout(() => navigate('/Servantpage'), 2000); // هدایت به صفحه لیست کاربران بعد از 2 ثانیه
      } else {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log("Response:", data);
    } catch (error) {
      toast.error("خطا در حذف کاربر.");
      console.error("Error deleting user:", error);
    } finally {
      setIsLoading(false);
      setIsModalOpen(false);
    }
  };

  const closeModal = () => setIsModalOpen(false);

  return (
    <div className="relative">
      <div className="absolute left-7 rounded-[11px] flex flex-col bg-[#FAFAFA] items-center text-gray-800 shadow-xl w-[166px]">
        <div className="w-[158px] h-[40px] flex items-center"
          onClick={handleUpdateServent}
        >
          <span
            className={`my-2 py-1 w-[100px] text-[12px] font-kalameDemiBold mr-2 text-gray-500 hover:text-black ${selectedOption === 'edit' ? 'border-[#006A60] rounded-[8px] border-[1px] bg-[#e6f0ef] w-[158px] h-[36px]' : ''}`}
          >
            ویرایش خدمت
          </span>
        </div>
        <div className="w-[158px] h-[40px] flex items-center"
          onClick={handleUserDelete}
        >
          <span
            className={`my-2 py-1 text-[12px] font-kalameDemiBold mr-2 text-gray-500 hover:text-black ${selectedOption === 'finish' ? 'border-[#006A60] rounded-[8px] border-[1px] bg-[#e6f0ef] w-[158px] h-[36px]' : ''}`}
          >
            اتمام خدمت
          </span>
        </div>
        <div className="text-[12px] font-kalameDemiBold text-gray-500 hover:text-black mr-2"
          // onClick={handleChangePassword}
        >
          <span
            onClick={handleChangePassword}
            className={`w-[158px] h-[40px] flex items-center ${selectedOption === 'changePassword' ? 'border-[#006A60] rounded-[8px] border-[1px] bg-[#e6f0ef] w-[158px] h-[36px]' : ''}`}
          >
            تغییر رمزعبور
          </span>
        </div>
      </div>
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
            <h2 className="text-2xl font-bold mb-4 text-center">آیا مطمئن هستید؟</h2>
            <p className="mb-6 text-center">این عملیات قابل بازگشت نیست.</p>
            <div className="flex justify-between">
              <button
                onClick={closeModal}
                className="py-2 px-4 bg-gray-500 text-white font-semibold rounded hover:bg-gray-600"
              >
                انصراف
              </button>
              <button
                onClick={confirmUserDelete}
                className={`py-2 px-4 bg-red-500 text-white font-semibold rounded hover:bg-red-600 ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                disabled={isLoading}
              >
                {isLoading ? (
                  <svg
                    className="animate-spin h-5 w-5 mr-3 text-white inline-block"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                ) : 'حذف'}
              </button>
            </div>
          </div>
        </div>
      )}

      <ToastContainer />
    </div>
  );
};

export default Modal;
