import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";

export default function Login() {
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // وضعیت نمایش/عدم نمایش رمز عبور
  const navigate = useNavigate();
  const handleLogin = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/login`,
        { username, password },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
  
      if (response.status === 200) {
        const responseData = response.data;
        localStorage.setItem("token", responseData.accessToken);
        toast.success("خوش آمدید", {
          position: "top-center",
          onClose: () => {
            if (responseData.user_role === "admin") {
              navigate("/Home");
            } else if (responseData.user_role === "pager") {
              navigate("/Pagerloatpersons");
            } else if (responseData.user_role === "servant") {
              navigate("/Servenlostpersons");
            } else {
              navigate("/");
            }
          },
          autoClose: 2000, // تنظیم زمان بسته شدن اتوماتیک toast
        });
      } else {
        setError(response.data.message || "Login failed");
        localStorage.removeItem("token");
        toast.error(response.data.message, {
          position: "top-center",
        });
      }
    } catch (error:any) {
      console.log(error.response.data.message);
      const msError = error.response.data.message
      // console.error("Connection error", error);
      setError(msError);
      toast.error(msError,{
        position: "top-center",
      });
      localStorage.removeItem("token");
    } finally {
      setIsLoading(false);
    }
  }; 

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };


  useEffect(() => {
    // اضافه کردن کلاس به بدنه در هنگام نصب کامپوننت
    document.body.classList.add('no-scroll');
    // حذف کلاس از بدنه در هنگام لغو نصب کامپوننت
    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, []);


  return (
    <>
      <ToastContainer  />
      <div className="h-[290px] bg-Loginbg bg-cover bg-center bg-no-repeat flex items-center justify-center">
        <div className="min-w-[317px] h-[128px] flex flex-col gap-2">
          <span className="min-w-[273px] h-[48px] font-black text-[white] text-[24px] leading-[48px] flex gap-1">
            السَّلاَمُ عَلَیْکَ یَا
            <span className="text-[#E99875]">أَمِیرَ الْمُؤْمِنِینَ</span>
          </span>
  
          <span className="w-[317px] h-[72px] font-normal text-[#FAFAFA] text-[14px] leading-6">
            این سامانه برای خادمی آقا امیرالمومنین در نجف اشرف تهیه شده تا
            بتوانیم افراد گمشده در نجف را در سامانه ثبت و سازماندهی کنیم.
          </span>
        </div>
      </div>
      <div className="relative bottom-10 z-10 h-full flex flex-col rounded-t-[25px] bg-white     overflow-y-hidden  overflow-x-hidden">
        <div className="flex flex-col h-[80px] w-full items-start justify-between p-7">
          <span className="text-[20px]">ورود به سامانه</span>
          <span className="text-[14px] text-gray-400">
            برای ورود به این سامانه لطفا نام کاربری و رمز عبور خود را <br />{" "}
            وارد کنید.
          </span>
        </div>
        <div className="flex flex-col h-[340px] justify-evenly items-center gap-3 p-[20px]">
          <div className="w-[328px] h-[60px] flex justify-center items-center">
            <div className="w-[328px]">
              <div className="relative w-full min-w-[200px] h-10">
                <input
                  className="peer w-full h-full bg-transparent text-blue-gray-700 font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 border focus:border-2 border-t-transparent focus:border-t-transparent text-sm px-3 py-2.5 rounded-[7px] border-blue-gray-200 focus:border-[#E99875]"
                  placeholder=" "
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
                <label className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-blue-gray-500 leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[3.75] text-[#595959] peer-focus:text-[#E99875] before:border-blue-gray-200 peer-focus:before:!border-[#E99875] after:border-blue-gray-200 peer-focus:after:!border-[#E99875]">
                  نام کاربری
                </label>
              </div>
            </div>
          </div>
          <div className="w-[328px] h-[60px] flex justify-center items-center">
            <div className="w-[328px]">
              <div className="relative w-full min-w-[200px] h-10">
                <input
                  className="peer w-full h-full bg-transparent text-blue-gray-700 font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 border focus:border-2 border-t-transparent focus:border-t-transparent text-sm px-3 py-2.5 rounded-[7px] border-blue-gray-200 focus:border-[#E99875]"
                  placeholder=" "
                  type={showPassword ? "text" : "password"} // تغییر نوع ورودی بر اساس وضعیت showPassword
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <label className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-blue-gray-500 leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[3.75] text-[#595959] peer-focus:text-[#E99875] before:border-blue-gray-200 peer-focus:before:!border-[#E99875] after:border-blue-gray-200 peer-focus:after:!border-[#E99875]">
                  رمز عبور
                </label>
                <div
                  className="absolute top-2.5 left-2.5 cursor-pointer"
                  onClick={togglePasswordVisibility} // تغییر وضعیت showPassword با کلیک بر روی آیکون
                >
                  {showPassword ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5 text-blue-gray-500"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 3.293a1 1 0 0 1 0 1.414L10.414 10l6.293 6.293a1 1 0 1 1-1.414 1.414L9 11.414l-6.293 6.293a1 1 0 1 1-1.414-1.414L7.586 10 1.293 3.707a1 1 0 0 1 1.414-1.414L9 8.586l6.293-6.293a1 1 0 0 1 1.414 0z"
                      />
                    </svg>
                  ) : (
                    <VisibilityOutlinedIcon />
                  )}
                </div>
              </div>
            </div>
          </div>
          {error && (
            <div className="text-red-500 text-sm mt-2">{error}</div> // نمایش پیام خطا
          )}
          <button
            className="w-[328px] h-[44px] flex items-center justify-center bg-[#2E857D] text-white font-bold rounded-[7px] focus:outline-none"
            onClick={handleLogin}
            disabled={isLoading}
          >
            {isLoading ? (
              <svg
                className="animate-spin h-5 w-5 mr-3 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A8.002 8.002 0 014 12H0c0 3.042 1.135 5.82 3 7.938l3-2.647z"
                ></path>
              </svg>
            ) : (
              "ورود"
            )}
          </button>
        </div>
      </div>
    </>
  );
  
}
