import React, { useEffect, useState, useRef } from 'react';
import profback from "../../../Assets/img/1.png";
import call from "../../../Assets/Admin/call.svg";
import loc from "../../../Assets/Admin/location.svg";
import id from "../../../Assets/Admin/id-card.svg";
import start from "../../../Assets/Admin/Start-date.svg";
import end from "../../../Assets/Admin/End-date.svg";
import logout from "../../../Assets/Pager/Log out.svg";
import { useNavigate } from 'react-router-dom';
import moment from 'jalali-moment';
import { fetchCities } from '@/api/cityAPI';
import { fetchCountries } from '@/api/countriesApi';
import { Country } from "@/types/country";
import { City } from "@/types/city";
import { convertToJalaali } from "@/utils/convertToJalaali";
import { getCountryTitle } from "@/utils/getCountryTitle";

export interface Servantprof {
    id: number;
    name: string;
    family: string;
    country_id: number;
    city_id: number;
    gender: string;
    description: string;
    age_range_id: number;
    age_group_id: string;
    appointment: string;
    create_at: string | null;
    iran_phone: string;
    iraq_phone: string;
    national_code: string;
}

export interface Profile {
    user: Servantprof;
    image: string;
    start_at: string;
    expire_at: string;
}

export default function Prof() {
    const token = localStorage.getItem("token");
    const [profile, setProfile] = useState<Profile | null>(null);
    const [listsCountries, setListsCountries] = useState<Country[]>([]);
    const [listsCity, setListsCity] = useState<City[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [zoomedImage, setZoomedImage] = useState<string | null>(null);
    const fetchedCountries = useRef(false);
    const fetchedCities = useRef(false);
    const navigate = useNavigate();

    const handlelogout = async () => {
        setLoading(true);
        try {
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${token}`);
            myHeaders.append("Accept", "application/json");

            const requestOptions = {
                method: "GET",
                headers: myHeaders,
            };
            const response = await fetch(
                "https://api.mafghoudin-zakerin.com/api/logout",
                requestOptions
            );
            if (!response.ok) {
                throw new Error("Failed to fetch data");
            }
            const data = await response.json();
            console.log(data, "response data");
            localStorage.removeItem("token");
            navigate("/");
        } catch (error) {
            console.error("Connection error", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const myHeaders = new Headers();
                myHeaders.append("Authorization", `Bearer ${token}`);
                myHeaders.append("Accept", "application/json");

                const requestOptions = {
                    method: "GET",
                    headers: myHeaders,
                };

                const response = await fetch(
                    "https://api.mafghoudin-zakerin.com/api/profile",
                    requestOptions
                );
                if (!response.ok) {
                    throw new Error("Failed to fetch data");
                }

                const data = await response.json();
                console.log(data, "response data");
                setProfile(data);
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchProfile();
    }, [token]);

    useEffect(() => {
        const fetchCountriesData = async () => {
            if (fetchedCountries.current) return;
            fetchedCountries.current = true;
            if (token) {
                const countryList = await fetchCountries(token);
                setListsCountries(countryList);
            }
        };
        fetchCountriesData();
        setLoading(false);
    }, [token]);

    useEffect(() => {
        const fetchCitiesData = async () => {
            if (fetchedCities.current) return;
            fetchedCities.current = true;
            if (token) {
                const cityList = await fetchCities(token);
                setListsCity(cityList);
            }
        };
        fetchCitiesData();
    }, [token]);

    const getCityTitle = (id: number) => {
        const city = listsCity.find((city) => city.id === id);
        return city ? city.title : 'Unknown City';
    };

    if (loading) {
        return (
            <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-500 bg-opacity-75 z-50">
                <div className="flex flex-col items-center">
                    <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
                    <div className="mt-4 text-white text-xl">لطفا صبر کنید</div>
                </div>
            </div>
        );
    }

    if (!profile) {
        return <div>هیچ کاربری با این نام کاربری وجود ندارد</div>;
    }
    const handleImageClick = (image: string | null) => {
        if (image) {
            setZoomedImage(image);
        }
    };

    const handleBackClick = () => {
        setZoomedImage(null);
    };
    if (zoomedImage) {
        return (
            <div className="fixed top-0 left-0 w-full h-full flex flex-col items-center justify-center bg-gray-900 bg-opacity-75 z-50">
                <div className="relative">
                    <img src={zoomedImage} alt="Zoomed" className="w-[500px] h-[500px] object-cover" />
                    <button
                        onClick={handleBackClick}
                        className="absolute top-2 right-2  rounded-full p-1"
                    >
                        <span role="img" aria-label="Close">
                            ❌
                        </span>
                    </button>
                </div>
            </div>
        );
    }
    return (
        <div className="w-[328]    min-h-[899px]      overflow-y-auto   overflow-x-hidden    flex  flex-col    items-center    "  >
            <img src={profback} alt='backprof' className='bg-[#2E857D] h-[240px] min-w-[361px]' />
            <div className='flex w-full  h-[60px] justify-center items-center '>
                <div className='w-[120px] h-[120px] rounded-[75px] border-[2px] border-[#5EA19B] relative bottom-[60px]' onClick={() => handleImageClick(profile.image)}>
                    <img src={profile.image} alt={`${profile.user.name} ${profile.user.family}`} className="rounded-full w-full h-full" />
                </div>
            </div>
            <div className=' w-screen h-[55px] flex flex-col justify-between  items-center  '>
                <div className='  h-[28px] flex justify-center items-center'>
                    <span className=' text-[#595959] font-bold mb-3'>{profile.user.name}  {profile.user.family}</span>
                </div>
                <div className='  h-[24px] flex justify-center items-center'>
                    <button className="bg-white  text-gray-800 font-semibold py-1 px-4 border border-gray-400 rounded shadow">ثبت کننده</button>
                </div>
            </div>
            <div className='flex flex-col gap-[24px]  mt-[32px]'>
                <div className='w-[328px] h-[146px] flex flex-col justify-evenly pr-3 bg-[#FAFAFA] rounded-[12px]'>
                    <div className='w-[200px] h-[50px] flex gap-2 pr-2 pb-2'>
                        <div className='w-[18px] h-[18px] flex justify-center items-center mt-1'>
                            <img src={call} alt='phone' />
                        </div>
                        <div className='w-[150px] h-[50px] flex flex-col justify-between'>
                            <div className='w-[63px] h-[24px] flex justify-center items-center'>
                                <p className='text-[#595959] text-xs'>شماره عراقی</p>
                            </div>
                            <div className='w-fit h-[24px] flex justify-center items-center' dir='ltr'>
                                <p className='text-[#595959] text-xs'>{profile.user.iraq_phone}</p>
                            </div>
                        </div>
                    </div>
                    <div className='w-[304px] border-[1px] border-[#F0F0F0] rounded-sm' />
                    <div className='w-[200px] h-[50px] flex gap-2 pr-2 pb-2'>
                        <div className='w-[18px] h-[18px] flex justify-center items-center mt-1'>
                            <img src={call} alt='phone' />
                        </div>
                        <div className='w-[150px] h-[50px] flex flex-col justify-between'>
                            <div className='min-w-[63px] h-[24px] flex items-center'>
                                <p className='text-[#595959] text-xs'>شماره ایرانی</p>
                            </div>
                            <div className='w-fit h-[24px] flex justify-center items-center' dir='ltr'>
                                <p className='text-[#595959] text-xs'>{profile.user.iran_phone}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='w-[328px] h-[168px] bg-[#FAFAFA] flex flex-col justify-evenly pr-3 rounded-[12px]'>
                    <div className='w-fit h-[24px] flex justify-between items-center gap-1'>
                        <div className='w-[18px] h-[18px] flex justify-center items-center'>
                            <img src={loc} alt='location' />
                        </div>
                        <div className='w-fit h-[24px] flex justify-center items-center'>
                            <p className='text-[#595959] text-xs'>{getCountryTitle(profile.user.country_id, listsCountries)} , {getCityTitle(profile.user.city_id)}</p>
                        </div>
                    </div>
                    <div className='w-fit h-[24px] flex justify-between items-center gap-1'>
                        <div className='w-[18px] h-[18px] flex justify-center items-center'>
                            <img src={id} alt='Nid' />
                        </div>
                        <div className='w-fit h-[24px] flex justify-center items-center'>
                            <p className='text-[#595959] text-xs'>{profile.user.national_code}</p>
                        </div>
                    </div>
                    <div className='w-fit h-[24px] flex justify-between items-center gap-1'>
                        <div className='w-[18px] h-[18px] flex justify-center items-center'>
                            <img src={start} alt='sdate' />
                        </div>
                        <div className='w-fit h-[24px] flex justify-center items-center'>
                            <p className='text-[#595959] text-xs'>{convertToJalaali(profile.start_at)}</p>
                        </div>
                    </div>
                    <div className='w-fit h-[24px] flex justify-between items-center gap-1'>
                        <div className='w-[18px] h-[18px] flex justify-center items-center'>
                            <img src={end} alt='edate' />
                        </div>
                        <div className='w-fit h-[24px] flex justify-center items-center'>
                            <p className='text-[#595959] text-xs'>{convertToJalaali(profile.expire_at)}</p>
                        </div>
                    </div>
                </div>
                <div className=' w-[328px] h-[50px]  flex items-center  '>
                    <button className=' w-[161px] h-[24px] gap-1 flex' onClick={handlelogout}>
                        <div className=' w-[24px] h-[24px] flex justify-center items-center'>
                            <img src={logout} alt='logout' />
                        </div>
                        <div className=' w-full h-[24px] flex justify-center items-center'>
                            <p className=' text-[#F5222D] text-xs font-bold'>خروج از حساب کاربری</p>
                        </div>
                    </button>
                </div>
            </div>
        </div>
    );
}
