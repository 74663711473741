import React, { useEffect, useState, useRef } from "react";
import idcard from "../../../../../Assets/Admin/id-card.svg";
import start from "../../../../../Assets/Admin/Start-date.svg";
import end from "../../../../../Assets/Admin/End-date.svg";
import arrowback from "../../../../../Assets/Admin/Arrow-left.svg";
import loction from "../../../../../Assets/Admin/location.svg";
import user from "../../../../../Assets/Admin/id-card.svg";
import gender from "../../../../../Assets/Admin/male.svg";
import loc from "../../../../../Assets/Admin/Loc.svg";
import call from "../../../../../Assets/Admin/call.svg";
import iraq from "../../../../../Assets/Admin/iraq.svg";
import iran from "../../../../../Assets/Admin/iran.svg";
import dot from "../../../../../Assets/Admin/dot.svg";
import personnel from "../../../../../Assets/img/personnel.png";
import female from "../../../../../Assets/Admin/Female.svg";

import ageRangesData from "@/Components/Servant/Add/Static/ageRangeData";
import ageTypesData from "@/Components/Servant/Add/Static/ageTypeData";

import { useNavigate, useParams } from "react-router-dom";
import moment from "jalali-moment";
import Link from "antd/es/typography/Link";

import { fetchCountries } from '@/api/countriesApi';
import { fetchCities } from '@/api/cityAPI';
import { Country } from "@/types/country";
import { City } from "@/types/city";
import { getAgeGroup } from "@/utils/getAgeGroup";
import { getAgeRange } from "@/utils/getAgeRange";
import { getGenderText } from '@/utils/getGenderText';
import { getCountryTitle } from "@/utils/getCountryTitle";

export interface Pilgrim {
  id: number;
  name: string;
  family: string;
  country_id: number | undefined;
  city_id: number | undefined;
  gender: string | null;
  description: string;
  age_range_id: number;
  age_group_id: number | undefined;
  appointment: string;
  created_by: string;
  created_at: Date;
  photo_url: string;
  notifier_relation: string | null;
  notifier_phone_number: string;
  notifier_name: string;
  notifier_family: string;
  region: string | null;
  iraq_mobile: string | null;
  iran_mobile: string;
  national_code: string | null;
  companions: string | null;
}

export interface ApiResponse {
  pilgrim: Pilgrim;
  photo: string;
}

export default function Infoperson() {
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const { id } = useParams<{ id: string }>();
  const [personInfo, setPersonInfo] = useState<Pilgrim | null>(null);
  const [listsCountries, setListsCountries] = useState<Country[]>([]);
  const [listsCity, setListsCity] = useState<City[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [zoomedImage, setZoomedImage] = useState<string | null>(null);
  const token = localStorage.getItem("token");
  const fetchedCountries = useRef(false);
  const fetchedCities = useRef(false);

  useEffect(() => {
    const fetchPersonInfo = async () => {
      try {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);
        myHeaders.append("Accept", "application/json");
        const requestOptions = {
          method: "GET",
          headers: myHeaders,
        };
        const response = await fetch(
          `https://api.mafghoudin-zakerin.com/api/pilgrims/${id}`,
          requestOptions
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data: ApiResponse = await response.json();
        setPersonInfo(data.pilgrim);
        console.log(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
    fetchPersonInfo();
  }, [id, token]);

  useEffect(() => {
    const fetchCountriesData = async () => {
      if (fetchedCountries.current) return;
      fetchedCountries.current = true;
      if (token) {
        const countryList = await fetchCountries(token);
        setListsCountries(countryList);
      }
    };
    fetchCountriesData();
  }, [token]);

  useEffect(() => {
    const fetchCitiesData = async () => {
      if (fetchedCities.current) return;
      fetchedCities.current = true;
      if (token) {
        const cityList = await fetchCities(token);
        setListsCity(cityList);
      }
    };
    fetchCitiesData();
  }, [token]);

  const getCityTitle = (id: number | undefined) => {
    if (id === undefined) return "Unknown City";
    const city = listsCity.find((city) => city.id === id);
    return city ? city.title : null;
  };

  const handleImageClick = (image: string | null) => {
    if (image) {
      setZoomedImage(image);
    }
  };

  const handleBackClick = () => {
    setZoomedImage(null);
  };

  if (loading) {
    return (
      <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-500 bg-opacity-75 z-50">
        <div className="flex flex-col items-center">
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
          <div className="mt-4 text-white text-xl">لطفا صبر کنید</div>
        </div>
      </div>
    );
  }
  if (zoomedImage) {
    return (
      <div className="fixed top-0 left-0 w-full h-full flex flex-col items-center justify-center bg-gray-900 bg-opacity-75 z-50">
        <div className="relative">
          <img
            src={zoomedImage}
            alt="Zoomed"
            className="w-[500px] h-[500px] object-cover"
          />
          <button
            onClick={handleBackClick}
            className="absolute top-2 right-2 rounded-full p-1"
          >
            <span role="img" aria-label="Close">
              ❌
            </span>
          </button>
        </div>
      </div>
    );
  }
  const photoUrl =
    personInfo?.photo_url !== undefined ? personInfo.photo_url : null;

  function Back() {
    window.history.back();
    // navigate('/Servenlostpersons');  
  }
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  console.log(personInfo?.notifier_name);
  return (
    <div className=" flex   h-full    flex-col  items-center   gap-4  rounded-[8px]     overflow-x-hidden">
      <div className="  w-full  h-[56px] flex justify-center items-center gap-[200px] bg-[white] rounded-t-md border-b-[1px] border-[#F0F0F0] sticky top-0  shadow-custom  ">
        <div className=" w-[104px] h-[32px] flex justify-center items-center">
          <p className=" text-sm text-[#434343] font-semibold">مشخصات گمشده</p>
        </div>
        <button
          onClick={Back}
          className=" w-[24px] h-[24px] flex justify-center items-center"
        >
          <img src={arrowback} alt="back" />
        </button>
      </div>
      <div className="w-full  h-[761px]  flex flex-col    items-center ">
        <div className="  w-[328px] h-[376px]  flex flex-col  items-center gap-4">
          <div className="w-[328px] h-[48px]   flex    gap-[120px]">
            <div className="w-[60%] h-[48px]    flex   gap-2">
              <img
                onClick={() => handleImageClick(photoUrl)}
                src={personInfo?.photo_url ? personInfo?.photo_url : personnel}
                width={48}
                height={48}
                alt=""
                className="rounded-full  w-[48px] h-[48px] "
              />

              <div className=" min-w-[89px] h-[48px]  flex flex-col  items-center gap-2  ">
                <p className="text-[#262626] text-[14px] font-semibold  min-w-[75px] h-[24px]">
                  {personInfo?.name === "" ? "" : personInfo?.name} { }
                  {personInfo?.family === "" ? "" : personInfo?.family}
                </p>
                <p className="text-[#595959] text-[12px] min-w-[75px] h-[24px]">
                  گمشده
                </p>
              </div>
            </div>
            <div className="    relative   w-6  h-10 py-4 items-center justify-center " onClick={toggleDropdown}
            >
              <img
                src={dot}
                width={4}
                height={13.33}
                alt=""
                onClick={toggleDropdown}
              />

              {isOpen && (
                <div className="absolute    left-7      rounded-[11px] flex flex-col bg-[#FAFAFA]   items-center  text-gray-800 shadow-xl w-[166px]    ">
                  <button onClick={() => navigate(`/MissingEdit/${id}`)} className=" w-[158px] h-[48px]  flex  items-center  ">
                    <span className="my-2 py-1  w-[100px] text-[12px] font-kalameDemiBold  mr-2 text-gray-500 hover:text-black">
                      ویرایش گمشده
                    </span>
                  </button>
                </div>
              )}
            </div>

          </div>
          <div className=" w-[328px]  h-[48px]  bg-[#FAFAFA]  rounded-[12px]  flex items-center">
            <div className=" w-[160px]  h-[48px]  flex  items-center  justify-center  gap-1">
              <div className=" minw-[132px]  h-[24px]  flex ">
                <div className="min-w-[123px] h-[24px]    flex gap-1 ">
                  <img src={loction} alt="id" />
                  <p className="text-[#595959] text-[10px]   font-semibold  w-[62px] h-[24px]      flex  items-center">
                    {personInfo?.country_id == null
                      ? ""
                      : getCountryTitle(personInfo?.country_id, listsCountries)}{" "}
                    ,
                    {personInfo?.city_id == null
                      ? ""
                      : getCityTitle(personInfo?.city_id)}
                    { }
                  </p>
                  <span className="text-[#595959] text-[10px]   font-semibold flex  items-center"> {personInfo?.region}</span>
                </div>
              </div>
            </div>
          </div>
          <div className=" w-[328px] h-[48px]   flex  justify-between ">
            <div className="w-[156px] h-[48px] bg-[#FAFAFA]  rounded-[12px]  flex gap-1 justify-center items-center">
              <div className="min-w-[132px] h-[24px]    flex  items-center">
                <div className="w-[58px] h-[24px]  flex  items-center  gap-[3px]">
                  <img src={idcard} alt="loc" />
                  <span className=" text-[12px] text-[#595959] mt-[2px]  min-w-[101px] h-[24px]  flex items-center">
                    کدملی
                  </span>
                </div>

                <div className=" w-[70px] h-[24px] ">
                  <span className=" text-[#595959] text-[12px]    ">
                    {personInfo?.national_code}
                  </span>
                </div>
              </div>
            </div>
            <div className="w-[156px] h-[48px] bg-[#FAFAFA]   rounded-[12px]   flex items-center  justify-center">
              <div className=" w-[130px] h-[24px]   flex  gap-3  ">
                <div className=" min-w-[40px] h-[24px]   flex     items-center gap-[3px] ">
                  <img
                    height={17}
                    width={17}
                    src={personInfo?.gender === "male" ? gender : female}
                    alt="gender"
                  />

                  <p className="text-[#595959] text-xs">
                    {" "}
                    {personInfo?.gender == null
                      ? ""
                      : getGenderText(personInfo?.gender)}
                  </p>
                </div>
                <span className="min-w-[140px] h-[24px] flex   items-center  gap-1   text-[#595959] text-[8px]">
                  {personInfo?.age_group_id
                    ? getAgeGroup(Number(personInfo?.age_group_id))
                    : null}{" "}
                  { }
                  {personInfo?.age_range_id
                    ? getAgeRange(Number(personInfo?.age_range_id))
                    : null}
                </span>
              </div>
            </div>
          </div>
          <div className=" w-[328px]  h-[48px]  bg-[#FAFAFA]  rounded-[12px]  flex items-center">
            <div className=" w-[190px]  h-[48px]  flex  items-center  justify-center  gap-1 ">
              <div className=" minw-[187px]  h-[24px]  flex      items-center">
                <div className="min-w-[123px] h-[24px]    flex gap-1   items-center">
                  <span className=" text-[#595959] text-[12px]  min-w-[165px]  h-[24px]  gap-2   flex items-center">
                    خادم ثبت کننده:
                    <span className=" min-w-[110px]  h-[24px]  text-[12px] text-[#2E857D]  font-semibold  flex items-center">
                      {personInfo?.created_by}
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className=" w-[328px]  h-[48px]  bg-[#FAFAFA]  rounded-[12px]  flex items-center">
            <div className=" w-[190px]  h-[48px]  flex  items-center  justify-center  gap-1 ">
              <div className=" minw-[187px]  h-[24px]  flex      items-center">
                <div className="min-w-[123px] h-[24px]    flex gap-1   items-center">
                  <img src={loc} alt="id" />
                  <span className=" text-[#595959] text-[12px]  min-w-[165px]  h-[24px]  gap-2   flex items-center">
                    محل قرار:
                    <span className=" min-w-[110px]  h-[24px]  text-[12px] text-[#2E857D]  font-semibold  flex items-center">
                      {personInfo?.created_by}
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="w-[328px]  min-h-[97px]  bg-[#FAFAFA]    flex  flex-col  items-center  justify-around   rounded-[12px]">
            <div className=" w-[300px]  h-[24px]  flex  justify-between ">
              <div className=" min-w-[111px]  h-[24px] flex  items-center ">
                <div className=" w-[60px]  h-[24px]  flex   gap-1">
                  <img src={call} alt="id" />
                  <img src={iraq} alt="id" />
                </div>

                <span className="text-[12px]  text-[#595959]   min-w-[63px]  ">
                  شماره عراقی
                </span>
              </div>

              <div className="  w-[111px]   h-[24px]  flex  items-center ">
                <span className=" text-xs text-[#595959]">
                  {personInfo?.iraq_mobile}
                </span>
              </div>
            </div>
            <span className=" w-[304px]  h-0   border-[1px]  border-[#F0F0F0]"></span>

            <div className=" w-[300px]  h-[24px] flex  justify-between ">
              <div className=" min-w-[111px]  h-[24px]  flex  items-center  ">
                <div className=" w-[60px]  h-[24px]  flex  gap-1">
                  <img src={call} alt="id" />
                  <img src={iran} alt="id" />
                </div>

                <span className="text-[12px]  text-[#595959]   min-w-[63px]  ">
                  شماره ایرانی
                </span>
              </div>

              <div className="  w-[111px]   h-[24px]  flex  items-center ">
                <span className=" text-xs text-[#595959]">
                  {personInfo?.iran_mobile}
                </span>
              </div>
            </div>
          </div>
          <div className=" w-[328px]  min-h-[48px]  bg-[#FAFAFA]  rounded-[12px]  flex items-center">
            <div className=" w-[300px]  h-[24px]  flex ">
              <div className="min-w-[300px] h-[24px]    flex gap-1   items-center">
                <img src={loc} alt="id" />
                <span className=" text-[#595959] text-[12px]  min-w-[165px]  h-[24px]  gap-2   flex items-center">
                  همراهان:
                  <span className=" min-w-[110px]  h-[24px]  text-[12px] text-[#2E857D]  font-semibold  flex items-center">
                    {personInfo?.companions}
                  </span>
                </span>
              </div>
            </div>
          </div>

          <div className=" w-[328px]  min-h-[48px]  bg-[#FAFAFA]  rounded-[12px]  flex items-center">
            <div className=" w-[300px]  h-[24px]  flex ">
              <div className="min-w-[300px] h-[24px]    flex gap-1   items-center">
                <img src={loc} alt="id" />
                <span className=" text-[#595959] text-[12px]  min-w-[165px]  h-[24px]  gap-2   flex items-center">
                  توضیحات:
                  <span className=" min-w-[110px]  h-[24px]  text-[12px] text-[#2E857D]  font-semibold  flex items-center">
                    {personInfo?.description}
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <span className="  w-full  h-0   border-[4px]  border-[#F0F0F0]  mt-[190px]"></span>

        <div className="  w-[328px]  min-h-[70px]  flex  items-center   ">
          <span className=" min-w-[114px] h-[28px]  text-[14px] font-bold  text-[#434343]">
            اطلاعات مراجعه کننده
          </span>
        </div>

        <div className="w-[328px]   min-h-[189px] overflow-y-auto   overflow-x-hidden    flex  flex-col   gap-4  mt-[8px]">
          <div className="min-h-[140px]  min-w-[328px] p-5 bg-white rounded-[12px] border-[1px]    border-[#F0F0F0] shadow-md flex flex-col gap-5  pr-2">
            <div className=" w-[305px] min-h-[100px]  flex    flex-col   justify-center    gap-[13px]">
              <span className=" font-semibold text-[12px] text-[#8C8C8C]  flex  gap-1 ">
                نام و نام خانوادگی: {personInfo?.notifier_name}{" "}
                {personInfo?.notifier_family}
              </span>

              {/* <div className="  w-[100%] min-h-[40%]   flex  justify-between  "> */}
              <span className=" font-semibold text-[12px] text-[#8C8C8C]  flex  gap-1   ">
                {" "}
                نسبت: {personInfo?.notifier_relation}
              </span>
              {/* </div> */}

              {/* <div className="  w-[100%] min-h-[40%]   flex  justify-between  "> */}
              <span className=" font-semibold text-[12px] text-[#8C8C8C]  flex  gap-1   ">
                {" "}
                شماره تلفن:{personInfo?.notifier_phone_number}
              </span>
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
