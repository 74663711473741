import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const PasswordChange = () => {
  const { id } = useParams();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPasswords, setShowPasswords] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const handleSubmit = async (e:any) => {
    e.preventDefault();
    if (newPassword === confirmPassword) {
      console.log(`Passwords match! User ID: ${id}`);
      setIsLoading(true); // شروع لودینگ
      try {
        const response = await fetch(`https://api.mafghoudin-zakerin.com/api/users/resetPassword?user_id=${id}&password=${newPassword}&confirm_password=${confirmPassword}`, {
          method: "PUT",
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        setIsLoading(false); // پایان لودینگ

        if (response.ok) {
          toast.success("رمز عبور با موفقیت تغییر کرد!");
          setTimeout(() => {
            navigate(`/Chosenserv/${id}`);
          }, 2000); // هدایت پس از 2 ثانیه
        } else {
          toast.error("خطایی در تغییر رمز عبور رخ داد.");
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        console.log("Response:", data);
      } catch (error) {
        setIsLoading(false); // پایان لودینگ در صورت خطا
        console.error("Error updating password:", error);
      }
    } else {
      toast.error('رمزهای عبور مطابقت ندارند!');
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
      <ToastContainer />
      <div className="bg-white p-8 rounded shadow-md w-full max-w-md">
        <h2 className="text-2xl font-bold mb-6 text-center">تغییر رمز عبور</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="new-password" className="block text-sm font-medium text-gray-700 mb-2">رمز عبور جدید</label>
            <input
              type={showPasswords ? "text" : "password"}
              id="new-password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
              className="w-full px-3 py-2 border border-gray-300 rounded"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="confirm-password" className="block text-sm font-medium text-gray-700 mb-2">تأیید رمز عبور جدید</label>
            <input
              type={showPasswords ? "text" : "password"}
              id="confirm-password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              className="w-full px-3 py-2 border border-gray-300 rounded"
            />
          </div>
          <div className="mb-4 flex items-center">
            <input
              type="checkbox"
              id="show-passwords"
              checked={showPasswords}
              onChange={() => setShowPasswords(!showPasswords)}
              className="mr-2"
            />
            <label htmlFor="show-passwords" className="text-sm font-medium text-gray-700">نمایش رمز عبور</label>
          </div>
          <button type="submit" className="w-full py-2 px-4 bg-blue-500 text-white font-semibold rounded hover:bg-blue-600">
            {isLoading ? 'در حال تغییر...' : 'تغییر رمز عبور'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default PasswordChange;
