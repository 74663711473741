const ageTypesData =[
    {
        "id": 1,
        "title": "خردسال و کودک",
        "status": "enable",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 2,
        "title": "نوجوان",
        "status": "enable",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 3,
        "title": "جوان",
        "status": "enable",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 4,
        "title": "بزرگسال",
        "status": "enable",
        "created_at": null,
        "updated_at": null
    },
    {
        "id": 5,
        "title": "مسن",
        "status": "enable",
        "created_at": null,
        "updated_at": null
    }
];

export default ageTypesData;