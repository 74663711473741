import React, { useState } from "react";
import arrowleft from "../../../Assets/Pager/Arrow-left.svg";
import Nothing from "../Nothing/Nothing";
import Lostinfo from "./Lostinfo/Lostinfo";
import Specialpeople from "./Specialpeople/Specialpeople"; // Import your Specialpeople component

export default function Pagerloatpersons() {
    const [activeTab, setActiveTab] = useState("lostinfo");

    const handleTabClick = (tabName:any) => {
        setActiveTab(tabName);
    };

    return (
        <>
            <div className="w-screen h-[56px] bg-white rounded-t-md sticky top-0 flex justify-center items-center gap-[195px] border-b-[1px] border-[#F0F0F0]">
                <div className="w-[109px] h-[32px] flex justify-center items-center">
                    <p className="text-sm text-[#434343] font-bold">لیست گمشدگان</p>
                </div>
                <div className="w-[24px] h-[24px] flex justify-center items-center">
                    <img src={arrowleft} alt="back" width={24} height={24} />
                </div>
            </div>
            <div className="flex flex-col items-center">
                <div className="flex gap-4 mt-4">
                    <div
                        className={`cursor-pointer p-2 ${activeTab === "lostinfo" ? "text-orange-500 border-b-2 border-orange-500" : "text-gray-500"}`}
                        onClick={() => handleTabClick("lostinfo")}
                    >
                        گمشده
                    </div>
                    <div
                        className={`cursor-pointer p-2 ${activeTab === "specialPeople" ? "text-orange-500 border-b-2 border-orange-500" : "text-gray-500"}`}
                        onClick={() => handleTabClick("specialPeople")}
                    >
                        افراد خاص
                    </div>
                </div>
                <div className="flex justify-center h-screen items-center flex-col gap-2 mt-4 bg-white">
                    {activeTab === "lostinfo" ? (
                        <div className="w-[328px] h-screen overflow-y-scroll flex flex-col gap-4">
                            <Lostinfo />
                        </div>
                    ) : activeTab === "specialPeople" ? (
                        <div className="w-[328px] h-screen overflow-y-scroll flex flex-col gap-4">
                            <Specialpeople />
                        </div>
                    ) : (
                        <Nothing />
                    )}
                </div>
            </div>
        </>
    );
}
