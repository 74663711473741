import React from 'react'
import Home from './Homepage/Home'
import Layout from '../layout/Layoutadmin'
import { Outlet } from 'react-router-dom'

export default function Admin() {
    return (
        <>

            <Layout>
                <Home />
                <Outlet />
            </Layout>
        </>
    )
}
