import React, { useEffect, useState } from "react";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import Chosenservant from "./Chosenservant";
import ServantEdite from "../Addservant/ServantEdite";
export default function RouteChosenservant() {

  return (
    <div className=" flex   flex-col  items-center   gap-4  rounded-[8px]">
      <Routes>
        <Route
          path="/Chosenserv/:id"
          element={
            <>
              <Chosenservant  />
            </>
          }
        />
      </Routes>
    </div>
  );
}
