import { useState } from "react";
import arrowdown from "../../../../Assets/Admin/arrow-down.svg"
const Dropdown: React.FC<{ open: boolean, setIsOpen: React.Dispatch<React.SetStateAction<boolean>> }> = ({ open, setIsOpen }) => {
    const [selectedItem, setSelectedItem] = useState<string | null>(null);
    const [check, setCheck] = useState(open);

    const toggleDropdown = () => {
        setCheck((prevState) => !prevState);
        setIsOpen((prevState) => !prevState);
    };

    const handleItemClick = (item: string) => {
        setSelectedItem(item);
    };

    return (
        <div className="relative inline-block text-left">
            <div>
                <button
                    type="button"
                    className="w-[72px] h-[28px] rounded-[8px] bg-white bg-opacity-10 text-sm text-white flex justify-center items-center gap-1"
                    onClick={toggleDropdown}
                >
                    <div className='w-[34px] h-[24px] ml-2 mt-2 mr-3 flex justify-center'>
                        {selectedItem || 'گزارشات'}
                    </div>
                    <div className='w-[18px] h-[18px] flex justify-center'>
                        <img src={arrowdown} alt='' width={18} height={18}  />
                    </div>
                </button>
            </div>
        </div>
    );
};

export default Dropdown;