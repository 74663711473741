import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import magni from '../../Assets/Admin/Maginy.svg';
import shild from '../../Assets/Admin/Shild.svg';
import home from "../../Assets/Admin/home-2.svg";

const AdminFooter = () => {
    const [activePath, setActivePath] = useState('');
    const location = useLocation();

    useEffect(() => {
        setActivePath(location.pathname);
    }, [location.pathname]);

    const footerItems = [
        {
            icon: home,
            title: "خانه",
            path: "/Home"
        },
        {
            icon: magni,
            title: "گمشدگان",
            path: "/Lostpage"
        },
        {
            icon: shild,
            title: "خدام",
            path: "/Servantpage"
        }
    ];

    return (
        <div className='w-screen h-[80px] bg-white rounded-[8px] flex justify-evenly items-center  fixed bottom-0 border-t-2 z-[10000] shadow-xl'>
            {footerItems.map((item, index) => (
                <Link to={item.path} key={index} onClick={() => setActivePath(item.path)}>
                    <div className={`w-[109px] h-[80px] flex flex-col justify-center items-center gap-1`}>
                        <div className='w-[24px] h-[24px] flex justify-center items-center'>
                               <img
                                style={{
                                    ...(activePath === item.path ? { filter: "brightness(0) saturate(100%) invert(55%) sepia(60%) saturate(491%) hue-rotate(331deg) brightness(96%) contrast(86%)" } : {})
                                }}
                                src={item.icon}
                                alt={item.title}
                                width={24}
                                height={24}
                            />
                             
                          
                        
                        </div>
                        <div className='w-[44px] h-[19px] flex justify-center items-center'>
                            <span className={`${activePath === item.path ? "duration-700 text-[#E48157]" : "text-[#BFBFBF]"}`}>{item.title}</span>
                        </div>
                    </div>
                </Link>
            ))}
        </div>
    );
};

export default AdminFooter;
