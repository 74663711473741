import React from 'react'
import Footer from '../common/BottomNavadmin'

export default function Layout({ children }: any) {
    return (
        <>
            <main className="App-content">{children}</main>
            <Footer />
        </>
    )
}
