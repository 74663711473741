import React, { useState, useEffect } from "react";
import close from "../../../../Assets/Admin/close.svg";
import DatePicker from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import Icon from "react-multi-date-picker/components/icon";
import moment from "jalali-moment";

export default function Filter() {
  const [closee, setClosee] = useState(true);
  const [start, setStart] = useState(new Date());
  const [end, setEnd] = useState(new Date());
  const [loading, setLoading] = useState(false);
  // const [responseData, setResponseData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [timeLeft, setTimeLeft] = useState(120); // 120 seconds (2 minutes)
  interface ResponseData {
  download_link: string;
}

const [responseData, setResponseData] = useState<ResponseData | null>(null);


  const token = localStorage.getItem("token");

  function persianToEnglishDigits(persianNumber:any) {
    const persianDigits = '۰۱۲۳۴۵۶۷۸۹';
    const englishDigits = '0123456789';
    return persianNumber.replace(/[۰-۹]/g, (w:any) =>
      englishDigits[persianDigits.indexOf(w)]
    );
  }

  const convertToGregorian = (shamsiDate:any) => {
    const shamsiDates = persianToEnglishDigits(shamsiDate);
    return moment(shamsiDates, "jYYYY/jMM/jDD").format("YYYY-MM-DD");
  };

  const handleChangeDate = (date:any) => {
    setStart(date);
  };

  const formatDate = (date:any) => {
    return new Intl.DateTimeFormat("fa-IR").format(date);
  };

  const handleChangeDate2 = (date:any) => {
    setEnd(date);
  };

  const handelclick2 = () => {
    setClosee(false);
  };

  const handleApplyFilter = async () => {
    setLoading(true);

    try {
      const response = await fetch(
        `https://api.mafghoudin-zakerin.com/api/export-pilgrims?startDate=${convertToGregorian(formatDate(start))}&endDate=${convertToGregorian(formatDate(end))}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = await response.json();
      setResponseData(data);
      setShowModal(true);
      setTimeLeft(120); // Reset timer to 2 minutes
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (showModal && timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);

      return () => clearInterval(timer);
    } else if (timeLeft === 0) {
      setShowModal(false);
    }
  }, [showModal, timeLeft]);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      {closee && (
        <div className="w-screen h-[600px] flex flex-col rounded-[12px] bg-white justify-evenly fixed bottom-0 z-[100] overflow-auto">
          <div className="w-screen h-[60px] flex justify-center items-center border-b-[1px] border-[#F0F0F0]">
            <div className="w-full h-[48px] flex justify-center items-center gap-[238px]">
              <div className="w-[80px] h-[24px] flex justify-center items-center">
                <p className="text-[#434343] text-sm font-semibold">
                  فیلتر گزارشات
                </p>
              </div>
              <button
                onClick={handelclick2}
                className="w-[20px] h-[20px] flex justify-center items-center"
              >
                <img src={close} alt="close" />
              </button>
            </div>
          </div>

          <div className="flex flex-col items-center gap-[20px]">
            <div>
              <div className="relative mt-4">
                <label className="absolute flex gap-1 right-4 -top-3 text-sm text-gray-700 bg-white px-1">
                  <span className="text-[red] font-[20px]">*</span>
                  شروع
                </label>
                <div className="flex gap-4 items-center w-[328px] min-h-[48px] px-4 py-2 text-gray-900 placeholder-gray-400 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#E99875]">
                  <DatePicker
                    value={start}
                    onChange={handleChangeDate}
                    render={<Icon />}
                    type="icon"
                    calendar={persian}
                    locale={persian_fa}
                    calendarPosition="top-right"
                  />
                  <p className="w-[65px] h-[25px] font-kalameRegular text-[14px] leading-[25.2px] text-[#535353]">
                    {formatDate(start)}
                  </p>
                </div>
              </div>
              <div className="relative mt-4">
                <label className="absolute flex gap-1 right-4 -top-3 text-sm text-gray-700 bg-white px-1">
                  <span className="text-[red] font-[20px]">*</span>
                  پایان
                </label>
                <div className="flex gap-4 items-center w-[328px] min-h-[48px] px-4 py-2 text-gray-900 placeholder-gray-400 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#E99875]">
                  <DatePicker
                    value={end}
                    onChange={handleChangeDate2}
                    render={<Icon />}
                    type="icon"
                    calendar={persian}
                    locale={persian_fa}
                    calendarPosition="top-right"
                  />
                  <p className="w-[65px] h-[25px] font-kalameRegular text-[14px] leading-[25.2px] text-[#535353]">
                    {formatDate(end)}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="w-screen h-[48px] flex justify-center items-center">
            <button
              onClick={handleApplyFilter}
              className="w-[328px] h-[48px] flex justify-center items-center bg-[#2E857D] rounded-[12px] mt-[-70px] relative disabled:opacity-50"
              disabled={loading}
            >
              {loading ? (
                <div className="w-[24px] h-[24px] border-4 border-t-transparent border-white rounded-full animate-spin"></div>
              ) : (
                <p className="text-sm text-white">اعمال فیلتر</p>
              )}
            </button>
          </div>
        </div>
      )}

      {showModal && (
        <div
          className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-[110]"
          onClick={handleCloseModal}
        >
          <div
            className="bg-white rounded-lg p-4 w-1/2 relative"
            onClick={(e) => e.stopPropagation()}
          >
            <button
              onClick={handleCloseModal}
              className="absolute top-0 left-4 mt-2 mr-2 text-gray-700 text-2xl font-bold"
            >
              ×
            </button>
            <h2 className="text-lg font-semibold mb-4">برای دریافت فایل اکسل بر روی دانلود فایل کلیک کنید </h2>
            {responseData && responseData.download_link && (
              <a
                href={responseData.download_link}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 underline"
              >
                دانلود فایل
              </a>
            )}
            <div className="mt-4">
              <p>
                زمان باقی مانده: {Math.floor(timeLeft / 60)}:
                {timeLeft % 60 < 10 ? `0${timeLeft % 60}` : timeLeft % 60}
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
