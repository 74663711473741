import React, { useState, useEffect, useCallback } from "react";
import magni from "../../../Assets/Admin/search.svg";
import setting from "../../../Assets/Admin/Setting.svg";
import All from "../../Servant/All/All";
import debounce from "lodash.debounce";
import Lostperson, { Pilgrim } from "../../Admin/Homepage/Slider/Lostperson";
import Found from "../../Admin/The found/Infopersons/Found";
import Lostlist from "./Lostpersonlist/Lostlist";
import persenal from "../../../Assets/img/personnel.png";
import jMoment from "jalali-moment";
import moment from 'moment-timezone'; import gender from "../../../Assets/Admin/male.svg";
import loc from "../../../Assets/Admin/location.svg";
import { useNavigate } from "react-router-dom";
import Isspecial from "../Isspecial/Isspecial";
import ageRangesData from "@/Components/Servant/Add/Static/ageRangeData";
import ageTypesData from "@/Components/Servant/Add/Static/ageTypeData";
import { convertToTime } from "@/utils/convertToTime";
import { convertToJalaali } from '@/utils/convertToJalaali';
import axios from 'axios';
import { Country } from "@/types/country";
import { City } from "@/types/city";
import { getAgeGroup } from "@/utils/getAgeGroup";
import { getAgeRange } from "@/utils/getAgeRange";
import { getGenderText } from '@/utils/getGenderText';

type ButtonName = "button1" | "button2" | "button3" | "button4";

export default function Servenlostpersons() {
  const token = localStorage.getItem("token");

  const [listsLost, setListsLost] = useState<Pilgrim[]>([]);
  const [loading, setLoading] = useState(false);
  const [listsCountries, setListsCountries] = useState<Country[]>([]);
  const [listsCity, setListsCity] = useState<City[]>([]);
  const [zoomedImage, setZoomedImage] = useState<string | null>(null);
  const [loading2, setLoading2] = useState<boolean>(false)
  const navigate = useNavigate();

  const [buttonState, setButtonState] = useState({
    button1: { text: "همه", isActive: true },
    button2: { text: "گمشده", isActive: false },
    button3: { text: "پیداشده", isActive: false },
    button4: { text: "افراد خاص", isActive: false },

  });
  const [currentComponent, setCurrentComponent] = useState<ButtonName>("button1");
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState<Pilgrim[]>([]);
  const [searchPerformed, setSearchPerformed] = useState(false);

  const handleClick = (btnName: ButtonName) => {
    const updatedButtons = {
      button1: { ...buttonState.button1, isActive: btnName === "button1" },
      button2: { ...buttonState.button2, isActive: btnName === "button2" },
      button3: { ...buttonState.button3, isActive: btnName === "button3" },
      button4: { ...buttonState.button4, isActive: btnName === "button4" },

    };
    setButtonState(updatedButtons);
    setCurrentComponent(btnName);
    setSearchPerformed(false); // Reset search performed state
  };

  const handleSearch = useCallback(debounce(async (query) => {
    setLoading(true);
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("Token not found in localStorage");
      setLoading(false);
      return;
    }

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Accept", "application/json");

    const url = `https://api.mafghoudin-zakerin.com/api/pilgrims/search?search=${encodeURIComponent(query)}`;

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: myHeaders,
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const data = await response.json();
      console.log("Search results:", data);
      setSearchResults(data.data); // Store search results
      setSearchPerformed(true); // Indicate search was performed
    } catch (error) {
      console.error("Error during the search request", error);
    } finally {
      setLoading(false);
    }
  }, 300), []); // Debounce the search function

  useEffect(() => {
    if (searchQuery.length > 0) {
      handleSearch(searchQuery);
    } else {
      setSearchResults([]);
      setSearchPerformed(false);
    }
  }, [searchQuery, handleSearch]);

  const handleImageClick = (image: string | null) => {
    if (image) {
      setZoomedImage(image);
    }
  };

  const handleViewInfoClick = (id: number) => {
    navigate(`/Missinginfopage/${id}`);
  };

  const handleFoundButtonClick = async (userId: number) => {
    setLoading2(true)
    try {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);
      myHeaders.append("Accept", "application/json");

      const requestOptions = {
        method: "PUT",
        headers: myHeaders,
      };

      const response = await fetch(
        `https://api.mafghoudin-zakerin.com/api/pilgrims/change-status/${userId}`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error("Failed to update status");
      }
      const updatedListsLost = listsLost.filter(
        (person) => person.id !== userId
      );
      setListsLost(updatedListsLost);
      console.log("Status updated successfully", response);
    } catch (error) {
      console.error("Error updating status:", error);
    } finally {
      setLoading2(false)
    }
  };
  const handleBackClick = () => {
    setZoomedImage(null);
  };
  if (zoomedImage) {
    return (
      <div className="fixed top-0 left-0 w-full h-full flex flex-col items-center justify-center bg-gray-900 bg-opacity-75 z-50">
        <div className="relative">
          <img
            src={zoomedImage}
            alt="Zoomed"
            className="w-[500px] h-[500px] object-cover"
          />
          <button
            onClick={handleBackClick}
            className="absolute top-2 right-2  rounded-full p-1"
          >
            <span role="img" aria-label="Close">
              ❌
            </span>
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="flex justify-center items-center flex-col gap-5 w-full">
      <div className="h-[130px] flex flex-col gap-4 justify-center items-center">
        <div className="w-[328px] h-[48px] flex justify-between items-center">
          <div className="w-[328px] h-[48px] flex items-center bg-[#F5F5F5] border-[1px] border-[#D9D9D9] rounded-[8px]">
            <button
              className="w-[40px] h-[20px] flex justify-center items-center"
              onClick={() => handleSearch(searchQuery)}
            >
              <img src={magni} alt="search" />
            </button>
            <input
              className="w-fit h-[24px] text-[#8C8C8C] bg-transparent outline-none"
              placeholder="جستجو کنید"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
        </div>
        <div className="w-[328px] h-[35px] flex items-center border-b-[1px] border-[#F0F0F0] gap-6">
          <button
            className={`w-[27px] h-[28px] flex justify-center ${buttonState.button1.isActive
              ? "border-b-[2px] border-[#E99875] duration-700 transition-all"
              : ""
              }`}
            onClick={() => handleClick("button1")}
          >
            <p
              className={`text-[#8C8C8C] ${buttonState.button1.isActive
                ? "text-[#E99875]"
                : "text-[#8C8C8C]"
                } text-sm`}
            >
              {buttonState.button1.text}
            </p>
          </button>
          <button
            className={`w-[44px] h-[28px] flex justify-center ${buttonState.button2.isActive
              ? "border-b-[2px] border-[#E99875] duration-700 transition-all"
              : ""
              }`}
            onClick={() => handleClick("button2")}
          >
            <p
              className={`text-[#8C8C8C] ${buttonState.button2.isActive
                ? "text-[#E99875]"
                : "text-[#8C8C8C]"
                } text-sm`}
            >
              {buttonState.button2.text}
            </p>
          </button>
          <button
            className={`w-[55px] h-[28px] flex justify-center ${buttonState.button3.isActive
              ? "border-b-[2px] border-[#E99875] duration-700 transition-all"
              : ""
              }`}
            onClick={() => handleClick("button3")}
          >
            <p
              className={`text-[#8C8C8C] ${buttonState.button3.isActive
                ? "text-[#E99875]"
                : "text-[#8C8C8C]"
                } text-sm`}
            >
              {buttonState.button3.text}
            </p>
          </button>
          <button
            className={`w-[80px] h-[28px] flex justify-center ${buttonState.button4.isActive
              ? "border-b-[2px] border-[#E99875] duration-700 transition-all"
              : ""
              }`}
            onClick={() => handleClick("button4")}
          >
            <p
              className={`text-[#8C8C8C] ${buttonState.button4.isActive
                ? "text-[#E99875]"
                : "text-[#8C8C8C]"
                } text-sm`}
            >
              {buttonState.button4.text}
            </p>
          </button>
        </div>
      </div>
      <div className="w-[328px]    min-h-[899px]    mb-20  overflow-y-auto   overflow-x-hidden    flex  flex-col   gap-4   mt-2">
        {searchQuery.length > 0 && searchPerformed ? (
          searchResults.length > 0 ? (
            searchResults.map((result) => (

              <div
                key={result.id}
                className="min-h-[337px]  min-w-[328px] p-5 bg-white rounded-[12px] border-[1px]    border-[#F0F0F0] shadow-md flex flex-col gap-5  pr-2"
              >
                <div className="w-[300px] h-[48px] flex justify-between items-center  ">
                  <div className="w-[328px] h-[48px] flex justify-between items-center gap-1 ">
                    <div className=" min-w-[190px] h-[48px]  flex  gap-2">
                      <img
                        onClick={() => handleImageClick(result.photo_url)}
                        src={result.photo_url ? result.photo_url : persenal}
                        alt={`${result.name} ${result.family}`}
                        className="rounded-full  w-[48px] h-[48px]  "
                      />
                      <div className=" min-w-[89px] h-[48px]  flex flex-col  items-center gap-2  ">
                        <p className="text-[#262626] text-[14px] font-semibold  min-w-[75px] h-[24px]">
                          {result.name === "" ? "" : result.name} { }
                          {result.family === "" ? "" : result.family}
                        </p>
                        <p className="text-[#595959] text-[12px] min-w-[75px] h-[24px]">
                          گمشده
                        </p>
                      </div>
                    </div>
                    <div className=" w-[129px] h-[48px]   flex flex-col  items-center ">
                      {" "}
                      <span className="min-w-[53px] h-[24px] text-[#5EA19B]   flex items-center  justify-center  font-semibold   text-[12px]">
                        {result.is_special === "1" ? "افراد خاص" : ""}
                      </span>
                      <p className="text-[#595959] text-[12px]   flex items-center  justify-center  font-semibold   w-[129px]  h-[24px] ">
                        {convertToJalaali(result.created_at)}
                      </p>
                      <p className="text-[#595959] text-[12px]   flex items-center  justify-center  font-semibold   w-[129px]  h-[24px] ">
                        {convertToTime(result.created_at)}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="min-w-[224px]  h-[24px]  flex">
                  <div className=" w-[84px] h-[24px]  flex  items-center  gap-1">
                    <img src={loc} alt="loc" />
                    <p className="text-[#595959] text-[10px]   font-semibold  w-[62px] h-[24px]      flex  items-center">
                      {result.country_id == null
                        ? "نامشخص"
                        : result.country_name}
                      {"، "}
                      {result.city_id == null
                        ? "نامشخص"
                        : result.city_name}
                    </p>
                  </div>

                  <div className=" min-w-[124px] h-[24px]   flex  gap-5">
                    <div className=" min-w-[40px] h-[24px]   flex     items-center gap-[3px] ">
                      <img src={gender} alt="gender" />

                      <p className="text-[#595959] text-xs">
                        {result.gender == null ? "" : getGenderText(result.gender)}
                      </p>
                    </div>
                    <span className="min-w-[140px] h-[24px] flex   items-center  gap-1   text-[#595959] text-[12px]">
                      {result.age_group_id
                        ? getAgeGroup(Number(result.age_group_id))
                        : null}{" "}
                      { }
                      {result.age_range_id
                        ? getAgeRange(Number(result.age_range_id))
                        : null}
                    </span>
                  </div>
                </div>
                <div className=" w-[167px] h-[22px] flex     gap-1">
                  <span className=" w-[167px] h-[24px]   font-semibold text-[12px] text-[#8C8C8C]  flex  gap-1   items-center">
                    خادم ثبت کننده :
                    <span className="  text-[12px]   font-semibold text-[#2E857D]">
                      {result.created_by}
                    </span>
                  </span>
                </div>
                <div className=" min-w-[167px] h-[22px] flex     gap-1">
                  <span className=" min-w-[167px] h-[24px]   font-semibold text-[12px] text-[#8C8C8C]  flex  gap-1   items-center">
                    محل قرار:
                    <span className="  text-[12px]   font-semibold text-[#2E857D]">
                      {" "}
                      {result.appointment}
                    </span>
                  </span>
                </div>
                <div className=" min-w-[301px]  h-[22px]   flex items-center">
                  <span className=" font-semibold text-[12px] text-[#8C8C8C]  flex  gap-1   items-center">
                    توضیحات:
                    <span className="  text-[12px]   font-semibold text-[#2E857D]">
                      {" "}
                      {result.description}
                    </span>
                  </span>
                </div>

                <div className=" w-[305px] min-h-[50px]  flex    flex-col    gap-[13px]">
                  <span className=" font-semibold text-[12px] text-[#8C8C8C]  flex  gap-1 ">
                    نام و نام خانوادگی:
                    <span className="  text-[12px]   font-semibold text-[#2E857D]">
                      {result.notifier_name} {result.notifier_family}
                    </span>
                  </span>
                  <div className="  w-[100%] min-h-[40%]   flex  justify-between  ">
                    <span className=" font-semibold text-[12px] text-[#8C8C8C]  flex  gap-1   ">
                      {" "}
                      نسبت:
                      <span className="  text-[12px]   font-semibold text-[#2E857D]">
                        {result.notifier_relation}
                      </span>
                    </span>
                    <button
                      onClick={() => handleViewInfoClick(result.id)}
                      className="w-[87px] h-[24px] flex justify-center items-center"
                    >
                      <p className="text-xs text-[#E48157]">مشاهده اطلاعات</p>
                    </button>
                  </div>
                </div>
                {result.status === "lost" ? (
                  <>
                    <button
                      onClick={() => handleFoundButtonClick(result.id)}
                      className=" w-[304px] min-h-[40px] p-[8px,16px,8px,16px]  bg-[#E48157] flex items-center justify-center  rounded-[8px]"
                    >
                      {" "}
                      {loading2 ? (
                        <svg
                          className="animate-spin h-5 w-5 mr-3 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A8.002 8.002 0 014 12H0c0 3.042 1.135 5.82 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      ) : (
                        <>
                          <span className=" min-w-[45px] h-[24px]   text-[14px] text-[white]  leading-6 font-medium ">
                            پیدا شد
                          </span>
                        </>
                      )}
                    </button>
                  </>
                ) : (
                  <>

                  </>
                )}
              </div>
            ))
          ) : (
            <p>نتیجه‌ای یافت نشد</p>
          )
        ) : (
          <>
            {currentComponent === "button1" && <All />}
            {currentComponent === "button2" && <Lostlist />}
            {currentComponent === "button3" && <Found />}
            {currentComponent === "button4" && <Isspecial />}

          </>
        )}
      </div>
    </div>
  );
}
