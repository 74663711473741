import React, { useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import Inquiry from './Inquiry'
import { Pilgrim } from '../../Admin/Homepage/Slider/Lostperson';
import Add from './Add';
import MissingEdit from './MissingEdit';
export default function PropsLosts() {
  const [similarPilgrims, setSimilarPilgrims] = useState<Pilgrim[]>([]);
  const [name, setName] = useState<string>("");
  const [last, setLast] = useState<string>("");
  const [gender, setGender] = useState<string>("");
  const [age_range, setAge_range_id] = useState<any>("");
  const [age_group, setAge_group] = useState<number | undefined | any>();
  const [country, setCountry] = useState<number | undefined | any>();
  const [city, setCity] = useState<string | number | any>();
  const [region, setRegion] = useState<string>("");
  const [appointment, setAppointment] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [nid, setNid] = useState<string>("");
  const [companions, setCompanions] = useState<string>("");
  const [irn, setIrn] = useState<string>("");
  const [irq, setIrq] = useState<string>("");
  const [type, setType] = useState<string>("lost");
  const [status, setStatus] = useState<string>("lost");
  const [is_special, setIs_special] = useState<string>("0");
  const [only_pager, setOnly_pager] = useState<string>("0");
  const [Ratio, setRatio] = useState<string>("");
  // console.log(Ratio, "kkkk");
  const [firstLast, setFirstLast] = useState("");
  const [firstLast2, setFirstLast2] = useState("");
  const [Phone, setPhone] = useState("");
  const [selectedImage, setSelectedImage] = useState<File | undefined | any>(
    undefined
  );
  console.log(is_special);
  return (
    <div>
      <Routes>
        <Route path="/Inquiry" element={<Inquiry similarPilgrims={similarPilgrims}
          name={name}
          last={last}
          gender={gender}
          age_range={age_range}
          age_group={age_group}
          country={country}
          city={city}
          region={region}
          appointment={appointment}
          description={description}
          nid={nid}
          companions={companions}
          irn={irn}
          irq={irq}
          type={type}
          status={status}
          is_special={is_special}
          only_pager={only_pager}
          Ratio={Ratio}
          firstLast={firstLast}
          firstLast2={firstLast2}
          Phone={Phone}
          selectedImage={selectedImage}
          setSimilarPilgrims={setSimilarPilgrims}
          setName={setName}
          setLast={setLast}
          setGender={setGender}
          setAge_range_id={setAge_range_id}
          setAge_group={setAge_group}
          setCountry={setCountry}
          setCity={setCity}
          setRegion={setRegion}
          setAppointment={setAppointment}
          setDescription={setDescription}
          setNid={setNid}
          setCompanions={setCompanions}
          setIrn={setIrn}
          setIrq={setIrq}
          setType={setType}
          setStatus={setStatus}
          setIs_special={setIs_special}
          setOnly_pager={setOnly_pager}
          setRatio={setRatio}
          setFirstLast={setFirstLast}
          setFirstLast2={setFirstLast2}
          setPhone={setPhone}
          setSelectedImage={setSelectedImage}
        />} />
        <Route path="/Add" element={<Add
          name={name}
          last={last}
          gender={gender}
          age_range={age_range}
          age_group={age_group}
          country={country}
          city={city}
          region={region}
          appointment={appointment}
          description={description}
          nid={nid}
          companions={companions}
          irn={irn}
          irq={irq}
          type={type}
          status={status}
          is_special={is_special}
          only_pager={only_pager}
          Ratio={Ratio}
          firstLast={firstLast}
          firstLast2={firstLast2}
          Phone={Phone}
          setSimilarPilgrims={setSimilarPilgrims}
          setName={setName}
          setLast={setLast}
          setGender={setGender}
          setAge_range_id={setAge_range_id}
          setAge_group={setAge_group}
          setCountry={setCountry}
          setCity={setCity}
          setRegion={setRegion}
          setAppointment={setAppointment}
          setDescription={setDescription}
          setNid={setNid}
          setCompanions={setCompanions}
          setIrn={setIrn}
          setIrq={setIrq}
          setType={setType}
          setStatus={setStatus}
          setIs_special={setIs_special}
          setOnly_pager={setOnly_pager}
          setRatio={setRatio}
          setFirstLast={setFirstLast}
          setFirstLast2={setFirstLast2}
          setPhone={setPhone}
          setSelectedImage={setSelectedImage}
        />} />

        <Route path="/MissingEdit/:id" element={<MissingEdit />} />

      </Routes>
    </div>
  )
}
