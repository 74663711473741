import React, { useEffect, useState } from 'react';
import genderIcon from '../../../../Assets/Admin/male.svg';
import locIcon from '../../../../Assets/Admin/location.svg';
import start from '../../../../Assets/Admin/Start-date.svg';
import end from '../../../../Assets/Admin/End-date.svg';
import female from "../../../../Assets/Admin/Female.svg"
import moment from 'jalali-moment';
import { Link, useNavigate } from 'react-router-dom';
import { Country } from "@/types/country";
import { City } from "@/types/city";
import { AgeGroup } from "@/types/ageGroup";
import { AgeRange } from "@/types/ageRange";
import { getGenderText } from '@/utils/getGenderText';
import { convertToJalaali } from "@/utils/convertToJalaali";

export type Servant = {
    id: number;
    username: string;
    status: string;
    start_at: string;
    expire_at: string;
    created_at: string;
    updated_at: string;
    image_url: string | null;
    user_data: {
        id: number;
        name: string;
        family: string;
        national_code: string;
        gender: string;
        iran_phone: string;
        iraq_phone: string;
        country_id: number;
        city_id: number;
        user_id: number;
        created_at: string;
        updated_at: string;
    };
    country_name: string;
    city_name: string;
};

interface User {
    servant: Servant;
    image: string | null;
}

const Servantpart: React.FC = () => {
    const token = localStorage.getItem('token');
    const [users, setUsers] = useState<Servant[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [listsCountries, setListsCountries] = useState<Country[]>([]);
    const [listsCity, setListsCity] = useState<City[]>([]);
    const [listAgeRanges, setListAgeRanges] = useState<AgeRange[]>([]);
    const [listAgeGroup, setListAgeGroup] = useState<AgeGroup[]>([]);
    const [zoomedImage, setZoomedImage] = useState<string | null>(null);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(1);
    const navigate = useNavigate()
    useEffect(() => {
        const fetchData = async (page: number) => {
            setLoading(true);
            try {
                const myHeaders = new Headers();
                myHeaders.append('Authorization', `Bearer ${token}`);
                myHeaders.append('Accept', 'application/json');

                const requestOptions = {
                    method: 'GET',
                    headers: myHeaders,
                };

                const response = await fetch(`https://api.mafghoudin-zakerin.com/api/users/servants?page=${page}`, requestOptions);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setUsers(data.users.data);
                setCurrentPage(data.users.current_page);
                setTotalPages(data.users.last_page);

                console.log(data);
            } catch (error) {
                console.error('Error fetching data', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData(currentPage);
    }, [token, currentPage]);

    const handleImageClick = (image: string | null) => {
        if (image) {
            setZoomedImage(image);
        }
    };

    const handleBackClick = () => {
        setZoomedImage(null);
    };

    useEffect(() => {
        // const fetchAgeRanges = async () => {
        //     try {
        //         const myHeaders = new Headers();
        //         myHeaders.append("Authorization", `Bearer ${token}`);
        //         myHeaders.append("Accept", "application/json");

        //         const requestOptions = {
        //             method: "GET",
        //             headers: myHeaders,
        //         };

        //         const response = await fetch(
        //             "https://api.mafghoudin-zakerin.com/api/age_ranges",
        //             requestOptions
        //         );
        //         if (!response.ok) {
        //             throw new Error("Failed to fetch data");
        //         }

        //         const data: AgeRange[] = await response.json();
        //         setListAgeRanges(data);
        //     } catch (error) {
        //         console.error("Error fetching data:", error);
        //     }
        // };
        // fetchAgeRanges();
    }, [token]);

    useEffect(() => {
        // const fetchAgeTypes = async () => {
        //     try {
        //         const myHeaders = new Headers();
        //         myHeaders.append("Authorization", `Bearer ${token}`);
        //         myHeaders.append("Accept", "application/json");

        //         const requestOptions = {
        //             method: "GET",
        //             headers: myHeaders,
        //         };

        //         const response = await fetch(
        //             "https://api.mafghoudin-zakerin.com/api/age_types",
        //             requestOptions
        //         );
        //         if (!response.ok) {
        //             throw new Error("Failed to fetch data");
        //         }

        //         const data: AgeGroup[] = await response.json();
        //         setListAgeGroup(data);
        //     } catch (error) {
        //         console.error("Error fetching data:", error);
        //     }
        // };
        // fetchAgeTypes();
    }, [token]);

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    if (loading) {
        return (
            <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-500 bg-opacity-75 z-50">
                <div className="flex flex-col items-center">
                    <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
                    <div className="mt-4 text-white text-xl">لطفا صبر کنید</div>
                </div>
            </div>
        );
    }
    if (zoomedImage) {
        return (
            <div className="fixed top-0 left-0 w-full h-full flex flex-col items-center justify-center bg-gray-900 bg-opacity-75 z-50">
                <div className="relative">
                    <img src={zoomedImage} alt="Zoomed" className="w-[500px] h-[500px] object-cover" />
                    <button
                        onClick={handleBackClick}
                        className="absolute top-2 right-2  rounded-full p-1"
                    >
                        <span role="img" aria-label="Close">
                            ❌
                        </span>
                    </button>
                </div>
            </div>
        );
    }
    const handleViewInfoClick = (id: number) => {
        navigate(`/Chosenserv/${id}`);
    };
    return (
        <div className="w-[328px] h-full bg mb-20 overflow-y-auto overflow-x-hidden flex flex-col gap-4 mt-5">
            {users.map((user) => (

                <div key={user.id} className="min-h-[220px]  min-w-[328px] p-5 bg-white rounded-[12px] border-[1px]    border-[#F0F0F0] shadow-md flex flex-col gap-5  pr-2">
                    {/* Info of the Pager */}
                    <div className="w-fit h-[50px] flex justify-center items-center gap-2">
                        <div className="w-[48px] h-[48px] rounded-[30px] border-[2px] border-[#2E857D] bg-blue-500" onClick={() => handleImageClick(user.image_url)}>
                            {user.image_url && <img src={user.image_url} alt={`${user.user_data.name} ${user.user_data.family}`} className="rounded-full w-full h-full" />}
                        </div>
                        <div className="w-fit h-[48px] flex flex-col justify-between">
                            <div className="w-fit h-[24px] flex justify-center items-center">
                                <span className="text-sm text-[#262626] font-bold">
                                    {user.user_data.name}, {user.user_data.family}
                                </span>
                            </div>
                            <div className="w-[52px] h-[24px] flex justify-center items-center">
                                <span className="text-xs text-[#2E857D]">ثبت کننده</span>
                            </div>
                        </div>
                    </div>



                    <div className="min-w-[224px]  h-[24px]  flex  items-center     gap-3">
                        <div className=" min-w-[84px] h-[24px]  flex items-center  gap-2">
                            <img src={locIcon} alt="loc" />
                            <p className="text-[#595959] text-[10px]   font-semibold  min-w-[79px] h-[24px]        flex  items-center">
                                {user.country_name} ، {user.city_name}
                            </p>
                        </div>

                        <div className=" w-[100px] h-[24px]   flex  gap-5  ">
                            <div className=" min-w-[40px] h-[24px]   flex     items-center gap-[3px] ">
                                <img src={user.user_data.gender === "male" ? genderIcon : female} alt="gender" className=' w-[20px] h-[20px]' />

                                <p className="text-[#595959] text-xs">
                                    {getGenderText(user.user_data.gender)}
                                </p>
                            </div>
                            <span className="min-w-[140px] h-[24px] flex   items-center  gap-1   text-[#595959] text-[12px]">
                            </span>
                        </div>
                    </div>
                    {/* Collaboration period */}
                    <div className="w-[199px] h-[24px] flex  items-center     gap-4 ">
                        <div className="w-fit h-[24px] flex justify-center items-center gap-1">
                            <div className="w-[18px] h-[18px] flex justify-center items-center">
                                <img src={start} alt="Start-date" />
                            </div>
                            <div className="w-fit h-[24px] flex justify-center items-center">
                                <span className="text-[#595959] text-xs">
                                    {convertToJalaali(user.start_at)}
                                </span>
                            </div>
                        </div>
                        <div className="w-fit h-[24px] flex justify-center items-center gap-1">
                            <div className="w-[18px] h-[18px] flex justify-center items-center">
                                <img src={end} alt="End-date" />
                            </div>
                            <div className="w-fit h-[24px] flex justify-center items-center">
                                <span className="text-[#595959] text-xs">
                                    {convertToJalaali(user.expire_at)}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className='w-[304px]   flex flex-col  '>
                        <span className="w-[304px] h-0 border-[1px] border-[#F0F0F0] " ></span>
                        {/* See the info */}
                        <button onClick={() => handleViewInfoClick(user.id)} className="w-[304px] p-3 h-[40px] flex justify-center items-center cursor-pointer ">
                            <button className="w-fit h-[19px] flex justify-center items-center">
                                <span className="text-[#E48157] text-xs">مشاهده اطلاعات</span>
                            </button>
                        </button>

                    </div>

                </div>
            ))}
            {/* <div className="flex justify-center items-center mt-4">
                {currentPage > 1 && (
                    <button onClick={() => handlePageChange(currentPage - 1)} className="px-3 py-1 mx-1 bg-[#2E857D] text-white rounded">
                        قبلی
                    </button>
                )}
                {Array.from({ length: totalPages }, (_, index) => (
                    <button
                        key={index + 1}
                        onClick={() => handlePageChange(index + 1)}
                        className={`px-3 py-1 mx-1 rounded ${currentPage === index + 1 ? 'bg-[#2E857D] text-white' : 'bg-gray-200 text-black'}`}
                    >
                        {index + 1}
                    </button>
                ))}
                {currentPage < totalPages && (
                    <button onClick={() => handlePageChange(currentPage + 1)} className="px-3 py-1 mx-1 bg-[2E857D] text-white rounded">
                        بعدی
                    </button>
                )}
            </div> */}
            <div className="flex justify-center items-center mt-4">
                {currentPage > 1 && (
                    <button
                        onClick={() => handlePageChange(currentPage - 1)}
                        className="px-3 py-1 mx-1 bg-[#2E857D] text-white rounded"
                    >
                        قبلی
                    </button>
                )}

                {Array.from({ length: totalPages }, (_, index) => {
                    const pageNumber = index + 1;

                    // محاسبه محدوده برای نمایش دکمه‌ها
                    const startPage = Math.max(1, currentPage - 2);
                    const endPage = Math.min(totalPages, currentPage + 2);

                    // فقط دکمه‌های داخل محدوده را نمایش دهید
                    if (pageNumber >= startPage && pageNumber <= endPage) {
                        return (
                            <button
                                key={pageNumber}
                                onClick={() => handlePageChange(pageNumber)}
                                className={`px-3 py-1 mx-1 rounded ${currentPage === pageNumber
                                    ? 'bg-[#2E857D] text-white'
                                    : 'bg-gray-200 text-black'
                                    }`}
                            >
                                {pageNumber}
                            </button>
                        );
                    }

                    return null; // اگر دکمه خارج از محدوده بود، آن را نمایش ندهید
                })}

                {currentPage < totalPages && (
                    <button
                        onClick={() => handlePageChange(currentPage + 1)}
                        className="px-3 py-1 mx-1 bg-[#2E857D] text-white rounded"
                    >
                        بعدی
                    </button>
                )}
            </div>

        </div>
    );
};

export default Servantpart;
