import React, { useEffect, useState, useRef } from "react";
import gender from "../../../Assets/Admin/male.svg";
import loc from "../../../Assets/Admin/location.svg";
import female from "../../../Assets/Admin/Female.svg";

import ageRangesData from "@/Components/Servant/Add/Static/ageRangeData";
import ageTypesData from "@/Components/Servant/Add/Static/ageTypeData";

import { convertToTime } from "@/utils/convertToTime";
import { convertToJalaali } from '@/utils/convertToJalaali';
import { Country } from "@/types/country";
import { City } from "@/types/city";
import {
    Pilgrim,
} from "../../Admin/Homepage/Slider/Lostperson";
import jMoment from "jalali-moment";
import moment from 'moment-timezone';
import { Link, useNavigate } from "react-router-dom";
import persenal from "../../../Assets/img/personnel.png";
import { getAgeGroup } from "@/utils/getAgeGroup";
import { getAgeRange } from "@/utils/getAgeRange";
import { getGenderText } from '@/utils/getGenderText';

export default function Isspecial() {
    const token = localStorage.getItem("token");
    const [listsLost, setListsLost] = useState<Pilgrim[]>([]);
    const [listsCountries, setListsCountries] = useState<Country[]>([]);
    const [listsCity, setListsCity] = useState<City[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [zoomedImage, setZoomedImage] = useState<string | null>(null);
    const navigate = useNavigate();
    const listContainerRef = useRef<HTMLDivElement | null>(null);
    const lastCardRef = useRef<HTMLDivElement | null>(null);
    const [loading2, setLoading2] = useState<boolean>(false)
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const fetchedSpecial = useRef(false);
    const handleImageClick = (image: string | null) => {
        if (image) {
            setZoomedImage(image);
        }
    };
    const handleBackClick = () => {
        setZoomedImage(null);
    };

    useEffect(() => {
        const fetchLostPerson = async (page: number) => {
            if (fetchedSpecial.current) return;
            fetchedSpecial.current = true;
            setLoading(true);
            try {
                const myHeaders = new Headers();
                myHeaders.append("Authorization", `Bearer ${token}`);
                myHeaders.append("Accept", "application/json");

                const requestOptions = {
                    method: "GET",
                    headers: myHeaders,
                };

                const response = await fetch(
                    `https://api.mafghoudin-zakerin.com/api/pilgrims/special?page=${page}`,
                    requestOptions
                );
                if (!response.ok) {
                    throw new Error("Failed to fetch data");
                }

                const data: any = await response.json();
                setListsLost(data.pilgrims.data);
                setCurrentPage(data.pilgrims.current_page);
                setTotalPages(data.pilgrims.last_page);
                console.log(data);
                console.log(data.pilgrims.data);
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchLostPerson(currentPage);
    }, [token, currentPage]);

    const handleFoundButtonClick = async (userId: number) => {
        setLoading2(true)
        try {
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${token}`);
            myHeaders.append("Accept", "application/json");

            const requestOptions = {
                method: "PUT",
                headers: myHeaders,
            };

            const response = await fetch(
                `https://api.mafghoudin-zakerin.com/api/pilgrims/change-status/${userId}`,
                requestOptions
            );

            if (!response.ok) {
                throw new Error("Failed to update status");
            }
            const updatedListsLost = listsLost.filter(
                (person) => person.id !== userId
            );
            setListsLost(updatedListsLost);
            console.log("Status updated successfully");
        } catch (error) {
            console.error("Error updating status:", error);
        } finally {
            setLoading2(false)
        }
    };

    const handlePageChange = (page: number) => {
        fetchedSpecial.current = false;
        setCurrentPage(page);
    };
    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                listContainerRef.current?.scrollTo({
                    top: listContainerRef.current.scrollHeight,
                    behavior: "smooth",
                });
            }
        });

        if (lastCardRef.current) {
            observer.observe(lastCardRef.current);
        }

        return () => {
            if (lastCardRef.current) {
                observer.unobserve(lastCardRef.current);
            }
        };
    }, [listsLost]);

    if (loading) {
        return (
            <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-500 bg-opacity-75 z-50">
                <div className="flex flex-col items-center">
                    <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
                    <div className="mt-4 text-white text-xl">لطفا صبر کنید</div>
                </div>
            </div>
        );
    }
    if (zoomedImage) {
        return (
            <div className="fixed top-0 left-0 w-full h-full flex flex-col items-center justify-center bg-gray-900 bg-opacity-75 z-50">
                <div className="relative">
                    <img
                        src={zoomedImage}
                        alt="Zoomed"
                        className="w-[500px] h-[500px] object-cover"
                    />
                    <button
                        onClick={handleBackClick}
                        className="absolute top-2 right-2  rounded-full p-1"
                    >
                        <span role="img" aria-label="Close">
                            ❌
                        </span>
                    </button>
                </div>
            </div>
        );
    }
    const handleViewInfoClick = (id: number) => {
        navigate(`/Missinginfopage/${id}`);
    };

    return (
        <div className="w-[328px]    min-h-[899px]      mb-20  overflow-y-auto   overflow-x-hidden    flex  flex-col   gap-4  ">
            {listsLost.map((person) => (
                <div
                    key={person.id}
                    className="min-h-[337px]  min-w-[328px] p-5 bg-white rounded-[12px] border-[1px]    border-[#F0F0F0] shadow-md flex flex-col gap-5  pr-2"
                >
                    <div className="w-[300px] h-[48px] flex justify-between items-center  ">
                        <div className="w-[328px] h-[48px] flex justify-between items-center gap-1 ">
                            <div className=" min-w-[190px] h-[48px]  flex  gap-2">
                                <img
                                    onClick={() => handleImageClick(person.photo_url)}
                                    src={person.photo_url ? person.photo_url : persenal}
                                    alt={`${person.name} ${person.family}`}
                                    className="rounded-full  w-[48px] h-[48px] "
                                />
                                <div
                                    className={`min-w-[100px] h-[48px] flex flex-col items-center gap-2 `}
                                >
                                    <span className="text-[#262626] text-[14px] font-semibold min-w-[100px] h-[24px] whitespace-nowrap overflow-hidden text-ellipsis">
                                        {person.name === "" ? "" : person.name}{" "}
                                        {person.family === "" ? "" : person.family}{" "}
                                    </span>
                                    <p className="text-[#595959] text-[12px] min-w-[75px] h-[24px]">
                                        گمشده
                                    </p>
                                </div>
                            </div>
                            <div className=" w-[129px] h-[48px]   flex flex-col  items-center ">
                                {" "}
                                <span className="min-w-[53px] h-[24px] text-[#5EA19B]   flex items-center  justify-center  font-semibold   text-[12px]">
                                    {person.is_special === "1" ? "افراد خاص" : ""}

                                </span>
                                <p className="text-[#595959] text-[12px]   flex items-center  justify-center  font-semibold   w-[129px]  h-[24px] ">
                                    {convertToJalaali(person.created_at)}
                                </p>
                                <p className="text-[#595959] text-[12px]   flex items-center  justify-center  font-semibold   w-[129px]  h-[24px] ">
                                    {convertToTime(person.created_at)}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="min-w-[224px]  h-[24px]  flex">
                        <div className=" w-[84px] h-[24px]  flex  items-center  gap-1">
                            <img src={loc} alt="loc" />
                            <p className="text-[#595959] text-[10px]   font-semibold  w-[62px] h-[24px]      flex  items-center">
                                {person.country_id == null
                                    ? "نامشخص"
                                    : person.country_name}
                                {"، "}
                                {person.city_id == null
                                    ? "نامشخص"
                                    : person.city_name}
                            </p>
                        </div>

                        <div className=" min-w-[124px] h-[24px]   flex  gap-5">
                            <div className=" min-w-[40px] h-[24px]   flex     items-center gap-[3px] ">
                                <img src={gender} alt="gender" />

                                <p className="text-[#595959] text-xs">
                                    {person.gender == null ? "" : getGenderText(person.gender)}
                                </p>
                            </div>
                            <span className="min-w-[140px] h-[24px] flex   items-center  gap-1   text-[#595959] text-[12px]">
                                {person.age_group_id
                                    ? getAgeGroup(Number(person.age_group_id))
                                    : null}{" "}
                                { }
                                {person.age_range_id
                                    ? getAgeRange(Number(person.age_range_id))
                                    : null}
                            </span>
                        </div>
                    </div>
                    <div className=" w-[167px] h-[22px] flex     gap-1">
                        <span className=" w-[167px] h-[24px]   font-semibold text-[12px] text-[#8C8C8C]  flex  gap-1   items-center">
                            خادم ثبت کننده :
                            <span className="  text-[12px]   font-semibold text-[#2E857D]">
                                {person.created_by}
                            </span>
                        </span>
                    </div>
                    <div className=" min-w-[167px] h-[22px] flex     gap-1">
                        <span className=" min-w-[167px] h-[24px]   font-semibold text-[12px] text-[#8C8C8C]  flex  gap-1   items-center">
                            محل قرار:
                            <span className="  text-[12px]   font-semibold text-[#2E857D]">
                                {" "}
                                {person.appointment}
                            </span>
                        </span>
                    </div>
                    <div className=" min-w-[301px]  h-[22px]   flex items-center">
                        <span className=" font-semibold text-[12px] text-[#8C8C8C]  flex  gap-1   items-center">
                            توضیحات:
                            <span className="  text-[12px]   font-semibold text-[#2E857D]">
                                {" "}
                                {person.description}
                            </span>
                        </span>
                    </div>

                    <div className=" w-[305px] min-h-[50px]  flex    flex-col    gap-[13px]">
                        <span className=" font-semibold text-[12px] text-[#8C8C8C]  flex  gap-1 ">
                            نام و نام خانوادگی:
                            <span className="  text-[12px]   font-semibold text-[#2E857D]">
                                {person.notifier_name} {person.notifier_family}
                            </span>
                        </span>
                        <div className="  w-[100%] min-h-[40%]   flex  justify-between  ">
                            <span className=" font-semibold text-[12px] text-[#8C8C8C]  flex  gap-1   ">
                                {" "}
                                نسبت:
                                <span className="  text-[12px]   font-semibold text-[#2E857D]">
                                    {person.notifier_relation}
                                </span>
                            </span>
                            <button
                                onClick={() => handleViewInfoClick(person.id)}
                                className="w-[87px] h-[24px] flex justify-center items-center"
                            >
                                <p className="text-xs text-[#E48157]">مشاهده اطلاعات</p>
                            </button>
                        </div>
                    </div>

                    <button
                        onClick={() => handleFoundButtonClick(person.id)}
                        className=" w-[304px] min-h-[40px] p-[8px,16px,8px,16px]  bg-[#E48157] flex items-center justify-center  rounded-[8px]"
                    >
                        {" "}
                        {loading2 ? (
                            <svg
                                className="animate-spin h-5 w-5 mr-3 text-white"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                            >
                                <circle
                                    className="opacity-25"
                                    cx="12"
                                    cy="12"
                                    r="10"
                                    stroke="currentColor"
                                    strokeWidth="4"
                                ></circle>
                                <path
                                    className="opacity-75"
                                    fill="currentColor"
                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A8.002 8.002 0 014 12H0c0 3.042 1.135 5.82 3 7.938l3-2.647z"
                                ></path>
                            </svg>
                        ) : (
                            <>
                                <span className=" min-w-[45px] h-[24px]   text-[14px] text-[white]  leading-6 font-medium ">
                                    پیدا شد
                                </span>
                            </>
                        )}
                    </button>
                </div>
            ))}
            {/* <div className="flex justify-center items-center mt-4">
            {currentPage > 1 && (
              <button onClick={() => handlePageChange(currentPage - 1)} className="px-3 py-1 mx-1 bg-[#2E857D] text-white rounded">
                قبلی
              </button>
            )}
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index + 1}
                onClick={() => handlePageChange(index + 1)}
                className={`px-3 py-1 mx-1 rounded ${currentPage === index + 1 ? 'bg-[#2E857D] text-white' : 'bg-gray-200 text-black'}`}
              >
                {index + 1}
              </button>
            ))}
            {currentPage < totalPages && (
              <button onClick={() => handlePageChange(currentPage + 1)} className="px-3 py-1 mx-1 bg-[#2E857D] text-white rounded">
                بعدی
              </button>
            )}
          </div> */}
            <div className="flex justify-center items-center mt-4">
                {currentPage > 1 && (
                    <button
                        onClick={() => handlePageChange(currentPage - 1)}
                        className="px-3 py-1 mx-1 bg-[#2E857D] text-white rounded"
                    >
                        قبلی
                    </button>
                )}

                {Array.from({ length: totalPages }, (_, index) => {
                    const pageNumber = index + 1;

                    // محاسبه محدوده برای نمایش دکمه‌ها
                    const startPage = Math.max(1, currentPage - 2);
                    const endPage = Math.min(totalPages, currentPage + 2);

                    // فقط دکمه‌های داخل محدوده را نمایش دهید
                    if (pageNumber >= startPage && pageNumber <= endPage) {
                        return (
                            <button
                                key={pageNumber}
                                onClick={() => handlePageChange(pageNumber)}
                                className={`px-3 py-1 mx-1 rounded ${currentPage === pageNumber
                                    ? 'bg-[#2E857D] text-white'
                                    : 'bg-gray-200 text-black'
                                    }`}
                            >
                                {pageNumber}
                            </button>
                        );
                    }

                    return null; // اگر دکمه خارج از محدوده بود، آن را نمایش ندهید
                })}

                {currentPage < totalPages && (
                    <button
                        onClick={() => handlePageChange(currentPage + 1)}
                        className="px-3 py-1 mx-1 bg-[#2E857D] text-white rounded"
                    >
                        بعدی
                    </button>
                )}
            </div>

        </div>
    );
}
