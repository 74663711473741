import React, { useState, ChangeEventHandler, useEffect } from "react";
import personnel from "../../../Assets/img/personnel.png";
import Edite2 from "../../../Assets/img/Edite2.png";
import Aroow from "../../../Assets/Servant/Arrow.svg";
import { Checkbox } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import arrowback from "../../../Assets/Admin/Arrow-left.svg";

import { listAge_group1, ListageRanges1 } from "./Add";
import { Country } from "../../../types/country";
import { City } from "../../../types/city";
import { Pilgrim } from "../../Admin/Homepage/Slider/Lostperson";
import { ApiResponse } from "../../Admin/The lost/Missing information/Infoperson/Infoperson";

import ageRangesData from "@/Components/Servant/Add/Static/ageRangeData";
import ageTypesData from "@/Components/Servant/Add/Static/ageTypeData";

import { getAgeGroup } from "@/utils/getAgeGroup";
import { getAgeRange } from "@/utils/getAgeRange";

import { getCountryTitle } from "@/utils/getCountryTitle";

export default function MissingEdit() {
  const token = localStorage.getItem("token");
  const { id } = useParams<{ id: string }>();
  const [personInfo, setPersonInfo] = useState<any>([]);
  const navigate = useNavigate();
  const [Id, setId] = useState<number>();
  const [previewImgUrl, setPreviewimgUrl] = useState<string>("");
  const [isOpen1, setIsOpen1] = useState(false);
  const [isRotated1, setIsRotated1] = useState(false);
  const [selectedItem1, setSelectedItem1] = useState("");
  const [isOpen2, setIsOpen2] = useState(false);
  const [isRotated2, setIsRotated2] = useState(false);
  const [selectedItem2, setSelectedItem2] = useState("");
  const [isOpen3, setIsOpen3] = useState(false);
  const [isRotated3, setIsRotated3] = useState(false);
  const [selectedItem3, setSelectedItem3] = useState("");
  const [isOpen4, setIsOpen4] = useState(false);
  const [isRotated4, setIsRotated4] = useState(false);
  const [selectedItem4, setSelectedItem4] = useState("");
  const [Phone, setPhone] = useState("");
  const [isLoading2, setisLoading2] = useState(false);
  const [ListsCountries, setListsCountries] = useState<Country[]>([]);
  const [ListsCity, setListsCity] = useState<City[]>([]);
  const [ListageRanges, setListageRanges] = useState<ListageRanges1[]>([]);
  const [listAge_group, setlistAge_group] = useState<listAge_group1[]>([]);
  const [loading, setLoading] = useState(true);
  const [inputValue, setInputValue] = useState<any>("");
  const [similarPilgrims, setSimilarPilgrims] = useState<Pilgrim[]>([]);
  const [name, setName] = useState<string>("");
  const [last, setLast] = useState<string>("");
  const [gender, setGender] = useState<string>("");
  const [age_range, setAge_range_id] = useState<any>("");
  const [age_group, setAge_group_id] = useState<any>("");
  const [country, setCountry] = useState<number | undefined | any>();
  const [city, setCity] = useState<any>();
  const [region, setRegion] = useState<string>("");
  const [appointment, setAppointment] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [nid, setNid] = useState<string | null>(null);
  const [companions, setCompanions] = useState<string>("");
  const [irn, setIrn] = useState<string | null>(null);
  const [irq, setIrq] = useState<string | null>(null);
  const [type, setType] = useState<string>("lost");
  const [status, setStatus] = useState<string>("lost");
  const [is_special, setIs_special] = useState<string>("0");
  const [only_pager, setOnly_pager] = useState<string>("0");
  // const [is_iranian, setIs_iranian] = useState<string>("0");
  const [firstLast, setFirstLast] = useState("");
  const [firstLast2, setFirstLast2] = useState("");
  const [selectedCountry, setSelectedCountry] = useState(null);

  const [selectedImage, setSelectedImage] = useState<File | undefined | any>(
    undefined
  );
  console.log(is_special);
  const [notifier_relation, setNotifier_relation] = useState("");
  useEffect(() => {
    const fetchPersonInfo = async () => {
      try {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);
        myHeaders.append("Accept", "application/json");
        const requestOptions = {
          method: "GET",
          headers: myHeaders,
        };
        const response = await fetch(
          `https://api.mafghoudin-zakerin.com/api/pilgrims/${id}`,
          requestOptions
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        console.log(data);
        setName(data.pilgrim.name);
        setLast(data.pilgrim.family);
        setNid(data.pilgrim.national_code);
        setGender(data.pilgrim.gender);
        setAge_group_id(data.pilgrim.age_group_id);
        setAge_range_id(data.pilgrim.age_range_id);
        setCountry(data.pilgrim.country_id);
        setCity(data.pilgrim.city_id);
        setDescription(data.pilgrim.description);
        setAppointment(data.pilgrim.appointment);
        setCompanions(data.pilgrim.companions);
        setIrn(data.pilgrim.iran_mobile);
        setIrq(data.pilgrim.iraq_mobile);
        setIs_special(data.pilgrim.is_special);
        // setIs_iranian(data.pilgrim.);
        setFirstLast(data.pilgrim.notifier_name);
        setFirstLast2(data.pilgrim.notifier_family);
        setPhone(data.pilgrim.notifier_phone_number);
        setNotifier_relation(data.pilgrim.notifier_relation);
        setPreviewimgUrl(data.pilgrim.photo_url);
        setNotifier_relation(data.pilgrim.notifier_relation);
        setId(data.pilgrim.id);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
    fetchPersonInfo();
  }, [id, token]);
  const handleFileChange: ChangeEventHandler<HTMLInputElement> = async (
    event
  ) => {
    const fileList = event.target.files as FileList;
    const file = fileList?.[0];
    setSelectedImage(file);
    if (!file) {
      return;
    }
    try {
      const imgUrl = await fileToDataString(file);
      setPreviewimgUrl(imgUrl);
    } catch (error) {
      console.error(error);
    }
  };
  const fileToDataString = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (reader.result) {
          resolve(reader.result as string);
        } else {
          reject(new Error("Failed to convert file to data URL"));
        }
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };
  const ChengInput1 = (e: React.ChangeEvent<HTMLInputElement>) => {
    let input = e.target.value.replace(/[^\s0-9]/g, "");
    if (input.length > 10) {
      return;
    }

    setNid(input.length === 0 ? null : input);
  };
  const ChengInput2 = (e: React.ChangeEvent<HTMLInputElement>) => {
    let input = e.target.value.replace(/[^\s0-9]/g, "");
    if (input.length > 11) {
      return;
    }
    setIrn(input.length === 0 ? null : input);
  };
  const ChengInput3 = (e: React.ChangeEvent<HTMLInputElement>) => {
    let input = e.target.value.replace(/[^\s0-9]/g, "");
    if (input.length > 11) {
      return;
    }
    setIrq(input.length === 0 ? null : input);
  };
  const ChengInput4 = (e: React.ChangeEvent<HTMLInputElement>) => {
    let input = e.target.value.replace(/[^\s0-9]/g, "");
    if (input.length > 11) {
      return;
    }

    setPhone(input);
  };
  const handleCheckboxChange = (e: any) => {
    setIs_special(e.target.checked ? "1" : "0");
  };
  // const handleCheckboxChange2 = (e: any) => {
  //   setIs_iranian(e.target.checked ? "1" : "0");
  // };
  const toggleDropdown1 = () => {
    setIsOpen1(!isOpen1);
    setIsRotated1(!isRotated1);
  };
  const handleItemClick1 = (event: any, id: number) => {
    setAge_group_id(id);
    setSelectedItem1(event.target.textContent);
    setIsOpen1(false);
  };
  const toggleDropdown2 = () => {
    setIsOpen2(!isOpen2);
    setIsRotated2(!isRotated2);
  };
  const handleItemClick2 = (event: any, id: number) => {
    setAge_range_id(id);
    setSelectedItem2(event.target.textContent);
    setIsOpen2(false);
  };
  const toggleDropdown3 = () => {
    setIsOpen3(!isOpen3);
    setIsRotated3(!isRotated3);
  };
  // const handleItemClick3 = (event: any, id: number) => {
  //   setCountry(id);
  //   setSelectedItem3(event.target.textContent);
  //   setIsOpen3(false);
  // };
  const handleItemClick3 = (event: React.MouseEvent<HTMLButtonElement>, id: any) => {
    setSelectedCountry(id);
    setCountry(id);

    const selectedCountryItem = ListsCountries.find((item) => item.id === id);
    if (selectedCountryItem) {
      setSelectedItem3(selectedCountryItem.title);
    }
    setIsOpen3(false);
  };
  const toggleDropdown4 = () => {
    setIsOpen4(!isOpen4);
    setIsRotated4(!isRotated4);
  };
  const countries = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);
      myHeaders.append("Accept", "application/json");

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
      };

      const response = await fetch(
        "https://api.mafghoudin-zakerin.com/api/countries",
        requestOptions
      );
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();
      setListsCountries(data);
      console.log(data);
      setPersonInfo(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    countries();
  }, []);
  const cities = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);
      myHeaders.append("Accept", "application/json");

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
      };

      const response = await fetch(
        "https://api.mafghoudin-zakerin.com/api/cities",
        requestOptions
      );
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();
      setListsCity(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    cities();
  }, []);
  const ageRanges = async () => {
    // try {
    //   const myHeaders = new Headers();
    //   myHeaders.append("Authorization", `Bearer ${token}`);
    //   myHeaders.append("Accept", "application/json");

    //   const requestOptions = {
    //     method: "GET",
    //     headers: myHeaders,
    //   };

    //   const response = await fetch(
    //     "https://api.mafghoudin-zakerin.com/api/age_ranges",
    //     requestOptions
    //   );
    //   if (!response.ok) {
    //     throw new Error("Failed to fetch data");
    //   }

    //   const data = await response.json();
    setListageRanges(ageRangesData);
    // } catch (error) {
    //   console.error("Error fetching data:", error);
    // }
  };

  useEffect(() => {
    ageRanges();
  }, []);
  const agetypes = async () => {
    // try {
    //   const myHeaders = new Headers();
    //   myHeaders.append("Authorization", `Bearer ${token}`);
    //   myHeaders.append("Accept", "application/json");

    //   const requestOptions = {
    //     method: "GET",
    //     headers: myHeaders,
    //   };

    //   const response = await fetch(
    //     "https://api.mafghoudin-zakerin.com/api/age_types",
    //     requestOptions
    //   );
    //   if (!response.ok) {
    //     throw new Error("Failed to fetch data");
    //   }

    //   const data = await response.json();
    setlistAge_group(ageTypesData);
    // } catch (error) {
    //   console.error("Error fetching data:", error);
    // }
  };
  console.log(age_group);

  useEffect(() => {
    agetypes();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        await Promise.all([ageRanges(), agetypes(), countries(), cities()]);
        setLoading(false); // Mark loading as false once all fetches are complete
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  // setIsLoading2(true);

  const updates = async (id: any) => {
    // Define user details with conditional null values
    const userDetails = {
      name: name || null,
      family: last || null,
      gender: gender || null,
      is_special: is_special || "0",
      age_range_id: age_range || null,
      age_group_id: age_group || null,
      country_id: country || null,
      city_id: city || null,
      region: region || null,
      appointment: appointment || null,
      description: description || null,
      national_code: nid || null,
      iran_mobile: irn || null, // Send null if `irn` is null
      iraq_mobile: irq || null,
      type: "lost",
      status: "lost",
      notifier_name: firstLast || null,
      notifier_family: firstLast2 || null,
      notifier_phone_number: Phone || null,
      notifier_relation: notifier_relation || null,
    };

    setisLoading2(true);
    try {
      // Update user details (PUT request)
      const updateUserUrl = `https://api.mafghoudin-zakerin.com/api/pilgrims/${id}`;
      const updateUserRequestOptions = {
        method: "PUT",
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userDetails),
      };

      const updateUserResponse = await fetch(
        updateUserUrl,
        updateUserRequestOptions
      );
      if (!updateUserResponse.ok) {
        throw new Error("Failed to update user details");
      }
      const updateUserData = await updateUserResponse.json();
      console.log(updateUserData);

      // Update user image (POST request)
      const updateImageUrl = `https://api.mafghoudin-zakerin.com/api/pilgrims/imageUpdate/${id}`;
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);
      myHeaders.append("Accept", "application/json");

      const formData = new FormData();
      formData.append("photo", selectedImage);

      const updateImageRequestOptions: RequestInit = {
        method: "POST",
        headers: myHeaders,
        body: formData,
      };

      const updateImageResponse = await fetch(
        updateImageUrl,
        updateImageRequestOptions
      );
      if (!updateImageResponse.ok) {
        throw new Error("Failed to update user image");
      }
      const updateImageData = await updateImageResponse.text();
      console.log(updateImageData);

      // Navigate after successful updates
      navigate(`/Missinginfopage/${id}`);
      toast("گمشده با موفقیت ویرایش شد", {
        position: "top-center",
      });
    } catch (error: any) {
      console.error("Error:", error.message || "Network error occurred.");
      toast.error("  گمشده ویرایش نشد دوباره تلاش کنید", {
        position: "top-center",
      });
    } finally {
      setisLoading2(false);
    }
  };

  function Back() {
    navigate(-1);
  }
  const handleItemClick4 = (event: any, id: number) => {
    const selectedCity = ListsCity.find((city) => city.id === id);
    if (selectedCity) {
      setCity(selectedCity.id);
      setInputValue(event.target.textContent);
      setIsOpen4(false);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };
  const filteredCities = ListsCity.filter((city) =>
    city.title.toLowerCase().includes(inputValue.toLowerCase())
  );
  const getCityTitle = (id: number | undefined) => {
    if (id === undefined) return "Unknown City";
    const city = ListsCity.find((city) => city.id === id);
    return city ? city.title : null;
  };

  const getAgeGroupText = (ageGroup: any, selectedItem: any) => {
    switch (ageGroup) {
      case "1":
        return selectedItem || "خردسال و کودک";
      case "2":
        return selectedItem || "نوجوان ";
      case "3":
        return selectedItem || "جوان";
      case "4":
        return selectedItem || "بزرگسال";
      case "5":
        return selectedItem || "مسن";
      default:
        return selectedItem || "";
    }
  };
  if (loading) {
    return (
      <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-500 bg-opacity-75 z-50">
        <div className="flex flex-col items-center">
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
          <div className="mt-4 text-white text-xl">لطفا صبر کنید</div>
        </div>
      </div>
    );
  }
  return (
    <div
      className="rss-backdrop"
      style={{
        background: "#f7f8f8",
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <ToastContainer />
      <>
        <div className="w-full h-full  flex  items-center flex-col justify-center ">
          <div className="w-[328px] h-[899px] bg-white flex flex-col items-center gap-[20px]  overflow-y-auto  overflow-x-hidden">
            <div className="  w-[328px] min-h-[90px] flex   justify-between   items-center   ">
              <span className="   mr-4">ویرایش گمشده</span>
              <button
                onClick={Back}
                className=" w-[24px] h-[24px] flex justify-center items-center"
              >
                <img
                  src={arrowback}
                  className="  flex items-center  ml-2"
                  alt=""
                />
              </button>
            </div>

            <div className="w-[251px] min-h-[225px] flex justify-center flex-col items-center">
              <img
                src={previewImgUrl ? previewImgUrl : personnel}
                alt=""
                width={150}
                height={150}
                className="rounded-full"
              />
              <label
                htmlFor="file-upload"
                className="w-[99px] my-2 py-1 text-[14px] font-kalameDemiBold text-gray-500 hover:text-black cursor-pointer"
              >
                <img className="mt-[-70px]" src={Edite2} alt="" />
              </label>
              <input
                id="file-upload"
                type="file"
                accept=".png,.jpg,.jpeg"
                hidden
                onChange={handleFileChange}
              />
            </div>
            <div className="relative mt-4">
              <label
                htmlFor="password"
                className="absolute right-4 -top-3 text-sm text-gray-700 bg-white "
              >
                نام{" "}
              </label>
              <input
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="block  w-[320px] min-h-[48px] px-4 py-2 text-gray-900 placeholder-gray-400 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#E99875]"
              />
            </div>
            <div className="relative mt-4">
              <label
                htmlFor="password"
                className="absolute right-4 -top-3 text-sm text-gray-700 bg-white "
              >
                نام خانوادگی{" "}
              </label>
              <input
                value={last}
                onChange={(e) => setLast(e.target.value)}
                className="block  w-[320px] min-h-[48px] px-4 py-2 text-gray-900 placeholder-gray-400 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#E99875]"
              />
            </div>

            {/* <div className="relative mt-4">
              <label
                htmlFor="password"
                className="absolute right-4 -top-3 text-sm text-gray-700 bg-white "
              >
                کدملی{" "}
              </label>
              <input
                value={nid ?? ""}
                onChange={ChengInput1}
                className="block  w-[320px] min-h-[48px] px-4 py-2 text-gray-900 placeholder-gray-400 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#E99875]"
              />
            </div> */}
            <div className="  w-[299px]  flex   gap-8 ">
              <span>جنسیت</span>
              <div className="w-[49px] h-[24px]  flex justify-evenly items-center">
                <input
                  type="radio"
                  name="gender"
                  value="male"
                  onChange={(e) => setGender(e.target.value)}
                  checked={gender === "male"}
                  style={{
                    backgroundColor: gender === "male" ? "pink" : "transparent",
                  }}
                />
                <div className="w-[60px] h-[22px] flex justify-center items-center ">
                  <span className="text-xs text-[#595959]">آقا</span>
                </div>
              </div>
              <div className="w-[60px] h-[24px] flex justify-evenly items-center ">
                <input
                  type="radio"
                  name="gender"
                  value="female"
                  checked={gender === "female"}
                  onChange={(e) => setGender(e.target.value)}
                  style={{
                    backgroundColor:
                      gender === "female" ? "pink" : "transparent",
                  }}
                />
                <div className="w-[26px] h-[22px] flex justify-center items-center">
                  <span className="text-xs text-[#595959]">خانم</span>
                </div>
              </div>
            </div>
            {/* <div className="w-[466px] h-[102px] flex flex-col gap-[25px]"> */}

            <div className=" w-[328px] h-[48px] flex  gap-2 justify-center">
              <div className="relative ">
                <label
                  htmlFor="password"
                  className="absolute right-4 -top-3 text-sm text-gray-700 bg-white "
                >
                  رده سنی{" "}
                </label>
                <button
                  className="  min-w-[156px]  flex  items-center  gap-7  justify-center  h-[48px] px-4 py-2 text-gray-900 placeholder-gray-400 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#E99875]"
                  onClick={toggleDropdown1}
                >
                  <div className="font-kalameDemiBold text-[#535353] leading-[21px] text-[14px] min-w-[100px] ">
                    {/* {age_group==="1"?"خردسال و کودک":selectedItem1||

                         age_group==="2"?" kksd":selectedItem1
                        
                        } */}

                    {getAgeGroupText(age_group, selectedItem1)}
                  </div>
                  <img src={Aroow} alt="" />
                </button>

                {isOpen1 && (
                  <div className="absolute z-50  flex flex-col  gap-2  w-[156px] max-h-[250px] pr-[12px] pt-[10px] overflow-auto no-scrollbar justify-between overflow-y-scroll  overflow-x-hidden font-kalameRegular text-[14px] text-[#535353] mt-[6px] bg-white border border-gray-300 rounded-lg shadow-md ">
                    {listAge_group.map((item) => (
                      <>
                        <button
                          onClick={(event) => handleItemClick1(event, item.id)}
                          className="my-2 py-1 text-[15px] font-kalameDemiBold ml-2 text-gray-500 hover:text-black"
                        >
                          {item.title}
                        </button>
                      </>
                    ))}
                  </div>
                )}
              </div>
              <div className="relative ">
                <label
                  htmlFor="password"
                  className="absolute right-4 -top-3 text-sm text-gray-700 bg-white "
                >
                  بازه سنی{" "}
                </label>
                <button
                  className="  w-[156px] min-h-[48px] flex  items-center  gap-7  px-4 py-2 text-gray-900 placeholder-gray-400 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#E99875]"
                  onClick={toggleDropdown2}
                >
                  <div className="font-kalameDemiBold text-[#535353] leading-[21px] text-[14px] min-w-[90px] ">
                    {age_range ? getAgeRange(age_range) : selectedItem2}
                  </div>
                  <img src={Aroow} alt="" />
                </button>

                {isOpen2 && (
                  <div className="absolute z-50  flex flex-col  gap-2  w-[156px] max-h-[250px] pr-[12px] pt-[10px] overflow-auto no-scrollbar justify-between overflow-y-scroll  overflow-x-hidden font-kalameRegular text-[14px] text-[#535353] mt-[6px] bg-white border border-gray-300 rounded-lg shadow-md ">
                    {ListageRanges.map((item) => (
                      <>
                        <button
                          onClick={(event) => handleItemClick2(event, item.id)}
                          className="my-2 py-1 text-[15px] font-kalameDemiBold ml-2 text-gray-500 hover:text-black"
                        >
                          {item.range}
                        </button>
                      </>
                    ))}
                  </div>
                )}
              </div>
            </div>


            <div className=" w-[328px] h-[48px] flex  gap-2 justify-center">
              <div className="relative ">

                <button
                  className="  w-[100px]  flex  items-center   gap-7 min-h-[48px] px-4 py-2 text-gray-900 placeholder-gray-400 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#E99875]"
                  onClick={toggleDropdown3}
                >
                  <div className="font-kalameDemiBold text-[#535353] leading-[21px] text-[14px] w-[80px] ">

                    {country ? getCountryTitle(country, ListsCountries) : selectedItem3 || "کشور"}

                  </div>
                  <img src={Aroow} alt="" />
                </button>

                {isOpen3 && (
                  <div className="absolute z-50  flex flex-col  gap-2  w-[100px] max-h-[250px] pr-[12px] pt-[10px] overflow-auto no-scrollbar justify-between overflow-y-scroll  overflow-x-hidden font-kalameRegular text-[14px] text-[#535353] mt-[6px] bg-white border border-gray-300 rounded-lg shadow-md ">
                    {ListsCountries.map((item) => (
                      <>
                        <button
                          onClick={(event) => handleItemClick3(event, item.id)}
                          className="my-2 py-1 text-[15px] font-kalameDemiBold ml-2 text-gray-500 hover:text-black"
                        >
                          {item.title}
                        </button>
                      </>
                    ))}
                  </div>
                )}
              </div>
              <div className="relative">
                <input
                  type="text"

                  value={city ? getCityTitle(city) : inputValue}
                  onChange={handleInputChange}
                  className={`w-[100px] min-h-[48px] px-4 py-2 text-gray-900 placeholder-gray-400 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#E99875] ${selectedCountry === 2 ? 'hidden' : ''}`}
                  placeholder='شهر'
                  onFocus={toggleDropdown4}
                />
                {isOpen4 && (
                  <div className="absolute z-50 flex flex-col gap-2 w-[100px] max-h-[250px] pr-[12px] pt-[10px] overflow-auto no-scrollbar justify-between overflow-y-scroll overflow-x-hidden font-kalameRegular text-[14px] text-[#535353] mt-[6px] bg-white border border-gray-300 rounded-lg shadow-md">
                    {filteredCities.map((item) => (
                      <button
                        key={item.id}
                        onClick={(event) => handleItemClick4(event, item.id)}
                        className="my-2 py-1 text-[15px] font-kalameDemiBold ml-2 text-gray-500 hover:text-black"
                      >
                        {item.title}
                      </button>
                    ))}
                  </div>
                )}
              </div>

              <div className="relative ">
                <input
                  placeholder='محله'
                  value={region}
                  onChange={(e) => setRegion(e.target.value)}
                  className="block w-[100px] min-h-[48px]  px-4 py-2 text-gray-900 placeholder-gray-400 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#E99875]"
                />
              </div>
            </div>

            <div className="relative mt-4">
              <label
                htmlFor="password"
                className="absolute right-4 -top-3 text-sm text-gray-700 bg-white "
              >
                توضیحات{" "}
              </label>
              <input
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className="block  w-[320px] min-h-[48px] px-4 py-2 text-gray-900 placeholder-gray-400 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#E99875]"
              />
            </div>
            <div className="relative mt-4">
              <label
                htmlFor="password"
                className="absolute right-4 -top-3 text-sm text-gray-700 bg-white "
              >
                مکان ملاقات{" "}
              </label>
              <input
                value={appointment}
                onChange={(e) => setAppointment(e.target.value)}
                className="block  w-[320px] min-h-[48px] px-4 py-2 text-gray-900 placeholder-gray-400 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#E99875]"
              />
            </div>

            <div className="relative mt-4">
              <label
                htmlFor="password"
                className="absolute right-4 -top-3 text-sm text-gray-700 bg-white "
              >
                همراهان{" "}
              </label>
              <input
                value={companions}
                onChange={(e) => setCompanions(e.target.value)}
                className="block  w-[320px] min-h-[48px] px-4 py-2 text-gray-900 placeholder-gray-400 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#E99875]"
              />
            </div>

            <div className="relative mt-4">
              <label
                htmlFor="password"
                className="absolute right-4 -top-3 text-sm text-gray-700 bg-white "
              >
                شماره تلفن ایرانی{" "}
              </label>
              <input
                type="text"
                inputMode="numeric"
                value={irn ?? ""}
                onChange={ChengInput2}
                className="block  w-[320px] min-h-[48px] px-4 py-2 text-gray-900 placeholder-gray-400 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#E99875]"
              />
            </div>

            <div className="relative mt-4 mb-6">
              <label
                htmlFor="password"
                className="absolute right-4 -top-3 text-sm text-gray-700 bg-white "
              >
                شماره تلفن عراقی{" "}
              </label>
              <input
                type="text"
                inputMode="numeric"
                value={irq ?? ""}
                onChange={ChengInput3}
                className="block  w-[320px] min-h-[48px] px-4 py-2 text-gray-900 placeholder-gray-400 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#E99875]"
              />
            </div>
            <div className=" w-[328px] min-h-[50px]       flex  items-center  justify-around">
              <div className="w-[200px] h-[22px] flex  items-center ">
                <Checkbox
                  checked={is_special === "1"}
                  onChange={handleCheckboxChange}
                  className="w-[24px] h-[24px] flex"
                />

                <p className="w-fit h-[21px] font-kalameDemiBold text-[14px] lesading-[21px] text-[#535353] ">
                  این فرد جزء افراد خاص هست
                </p>
              </div>
            </div>
            {/* <div className=" w-[328px] min-h-[50px]       flex  items-center  justify-around">
              <div className="w-[200px] h-[22px] flex  items-center ">
                <Checkbox
                  checked={is_iranian === "1"}
                  onChange={handleCheckboxChange2}
                  className="w-[24px] h-[24px] flex"
                />

                <p className="w-fit h-[21px] font-kalameDemiBold text-[14px] lesading-[21px] text-[#535353] ">
                  این فرد جزء افراد غیر ایرانی هست
                </p>
              </div>
            </div> */}
            <div className="  w-[299px] h-[60px]   flex ">
              <span className=" text-sm text-gray-700 text-center  min-w-[100px]">
                {" "}
                مشخصات مراجعه کننده
              </span>
            </div>

            <div className=" w-[320px] min-h-[200px] flex flex-col">
              <div className="relative mt-4 mb-6">
                <label
                  htmlFor="password"
                  className="absolute right-4 -top-3 text-sm text-gray-700 bg-white "
                >
                  نام{" "}
                </label>
                <input
                  value={firstLast}
                  onChange={(e) => setFirstLast(e.target.value)}
                  className="block  w-[320px] min-h-[48px] px-4 py-2 text-gray-900 placeholder-gray-400 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#E99875]"
                />
              </div>
              <div className="relative mt-4 mb-6">
                <label
                  htmlFor="password"
                  className="absolute right-4 -top-3 text-sm text-gray-700 bg-white "
                >
                  نام خانوادگی{" "}
                </label>
                <input
                  value={firstLast2}
                  onChange={(e) => setFirstLast2(e.target.value)}
                  className="block  w-[320px] min-h-[48px] px-4 py-2 text-gray-900 placeholder-gray-400 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#E99875]"
                />
              </div>

              <div className="relative mt-4 mb-6">
                <label
                  htmlFor="password"
                  className="absolute right-4 -top-3 text-sm text-gray-700 bg-white "
                >
                  نسبت{" "}
                </label>
                <input
                  value={notifier_relation}
                  onChange={(e) => setNotifier_relation(e.target.value)}
                  className="block  w-[320px] min-h-[48px] px-4 py-2 text-gray-900 placeholder-gray-400 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#E99875]"
                />
              </div>
              <div className="relative mt-4 mb-6">
                <label
                  htmlFor="password"
                  className="absolute right-4 -top-3 text-sm text-gray-700 bg-white "
                >
                  شماره تلفن
                </label>
                <input
                  type="text"
                  inputMode="numeric"
                  value={Phone}
                  onChange={ChengInput4}
                  className="block  w-[320px] min-h-[48px] px-4 py-2 text-gray-900 placeholder-gray-400 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#E99875]"
                />
              </div>
            </div>
          </div>
        </div>
      </>
      <button
        onClick={() => updates(Id)}
        className="w-[320px] min-h-[48px] bg-[#2E857D] flex justify-center items-center p-[8px,16px,8px,16px] rounded-[8px] mt-6 text-white"
      >
        {isLoading2 ? (
          <svg
            className="animate-spin h-5 w-5 mr-3 text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A8.002 8.002 0 014 12H0c0 3.042 1.135 5.82 3 7.938l3-2.647z"
            ></path>
          </svg>
        ) : (
          "ثبت اطلاعات"
        )}
      </button>
    </div>
  );
}
