import { useEffect, useState, useRef } from "react";
import out from "../../../Assets/Admin/out.svg";
import nothing from "../../../Assets/Nothing.png";
import Dropdown from "./Dropdown/Dropdown";
import { Link, Outlet, useNavigate } from "react-router-dom";
import female from "../../../Assets/Admin/Female.svg";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

import {
  Pilgrim
} from "./Slider/Lostperson";
import genderIcon from "../../../Assets/Admin/male.svg";
import locIcon from "../../../Assets/Admin/location.svg";
import moment from "jalali-moment";
import persnal from "../../../Assets/img/personnel.png";
import Filter from "./Dropdownslider/Filter";
import ageRangesData from "@/Components/Servant/Add/Static/ageRangeData";
import ageTypesData from "@/Components/Servant/Add/Static/ageTypeData";
// import convertToTime from '@utils/convertToTime';
import { convertToTime } from "@/utils/convertToTime";
import { convertToJalaali } from '@/utils/convertToJalaali';
import { Country } from "@/types/country";
import { City } from "@/types/city";
import { getAgeGroup } from "@/utils/getAgeGroup";
import { getAgeRange } from "@/utils/getAgeRange";
import { getGenderText } from '@/utils/getGenderText';

export default function Home() {
  const [isOpen, setIsOpen] = useState(false);
  const [pilgrimsCount, setPilgrimsCount] = useState(null);
  const [find, setFind] = useState<number>();
  const [lost, setLost] = useState<number>();
  const [listsLost, setListsLost] = useState<Pilgrim[]>([]);
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState<boolean>(true); // Initially set to true
  const [date, setDate] = useState(new Date());
  const [zoomedImage, setZoomedImage] = useState<string | null>(null);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);

  const fetchedLost = useRef(false);
  const fetchedLostPage = useRef(false);
  const fetchedCount = useRef(false);

  const navigate = useNavigate();
  const handlelogout = async () => {
    setLoading(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);
      myHeaders.append("Accept", "application/json");

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
      };
      const response = await fetch(
        "https://api.mafghoudin-zakerin.com/api/logout",
        requestOptions
      );
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const data = await response.json();
      console.log(data, "response data");
      localStorage.removeItem("token");
      navigate("/");
    } catch (error) {
      console.error("Connection error", error);
    } finally {
      setLoading(false);
    }
  };
  const handleChangeDate = (date: any) => {
    setDate(date);
  };
  const handleImageClick = (image: string | null) => {
    if (image) {
      setZoomedImage(image);
    }
  };

  const handleBackClick = () => {
    setZoomedImage(null);
  };

  const formatDate = (date: number | Date | undefined) => {
    if (!date) return "";

    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "long", // نمایش نام ماه به جای عدد ماه
      day: "numeric",
    };

    return new Intl.DateTimeFormat("fa-IR", options).format(new Date(date));
  };

  const formatDate1 = (date: number | Date | undefined) => {
    return new Intl.DateTimeFormat("fa-IR").format(date);
  };
  useEffect(() => {
    const fetchData = async (page: number) => {
      setLoading(true);
      try {
        if (fetchedLostPage.current) return;
        fetchedLostPage.current = true;
        setLoading(true);
        const myHeaders = new Headers();
        myHeaders.append('Authorization', `Bearer ${token}`);
        myHeaders.append('Accept', 'application/json');

        const requestOptions = {
          method: 'GET',
          headers: myHeaders,
        };

        const response = await fetch(`https://api.mafghoudin-zakerin.com/api/pilgrims/lost?page=${page}`, requestOptions);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        // setUsers(data.users.data);
        setCurrentPage(data.pilgrims.current_page);
        setTotalPages(data.pilgrims.last_page);

        console.log(data);
      } catch (error) {
        console.error('Error fetching data', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData(currentPage);
  }, [token, currentPage]);

  useEffect(() => {
    const fetchLostPerson = async () => {
      try {
        if (fetchedLost.current) return;
        fetchedLost.current = true;
        setLoading(true);
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);
        myHeaders.append("Accept", "application/json");

        const requestOptions = {
          method: "GET",
          headers: myHeaders,
        };

        const response = await fetch(
          "https://api.mafghoudin-zakerin.com/api/pilgrims/lost",
          requestOptions
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();
        setListsLost(data.pilgrims.data);
        console.log(data.pilgrims);
        // setCurrentPage(data.pilgrims.current_page);
        // setTotalPages(data.pilgrims.last_page);

      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); // Set loading to false once data is fetched or errored
      }
    };
    fetchLostPerson();
  }, [token]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (fetchedCount.current) return;
        fetchedCount.current = true;
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);
        myHeaders.append("Accept", "application/json");

        const requestOptions = {
          method: "GET",
          headers: myHeaders,
        };

        const response = await fetch(
          "https://api.mafghoudin-zakerin.com/api/pilgrims/count",
          requestOptions
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();
        setPilgrimsCount(data);
        console.log(data);
        setFind(data.found);
        setLost(data.lost);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  console.log(pilgrimsCount);
  useEffect(() => {
    // const fetchCountries = async () => {
    //   try {
    //     const myHeaders = new Headers();
    //     myHeaders.append("Authorization", `Bearer ${token}`);
    //     myHeaders.append("Accept", "application/json");

    //     const requestOptions = {
    //       method: "GET",
    //       headers: myHeaders,
    //     };

    //     const response = await fetch(
    //       "https://api.mafghoudin-zakerin.com/api/countries",
    //       requestOptions
    //     );
    //     if (!response.ok) {
    //       throw new Error("Failed to fetch data");
    //     }

    //     const data: Country[] = await response.json();
    //     setListsCountries(data);
    //   } catch (error) {
    //     console.error("Error fetching data:", error);
    //   }
    // };
    // fetchCountries();
  }, [token]);

  const handleViewInfoClick = (id: number) => {
    navigate(`/Missinginfopage/${id}`);
  };

  if (loading) {
    return (
      <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-500 bg-opacity-75 z-50">
        <div className="flex flex-col items-center">
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
          <div className="mt-4 text-white text-xl">لطفا صبر کنید</div>
        </div>
      </div>
    );
  }
  if (zoomedImage) {
    return (
      <div className="fixed top-0 left-0 w-full h-full flex flex-col items-center justify-center bg-gray-900 bg-opacity-75 z-50">
        <div className="relative">
          <img
            src={zoomedImage}
            alt="Zoomed"
            className="w-[500px] h-[500px] object-cover"
          />
          <button
            onClick={handleBackClick}
            className="absolute top-2 right-2  rounded-full p-1"
          >
            <span role="img" aria-label="Close">
              ❌
            </span>
          </button>
        </div>
      </div>
    );
  }
  return (
    <>
      <div className=" w-screen h-screen bg-gradient-to-r from-[#2E857D] to-[#5EA19B] overflow-hidden">
        <div className="bg-gradient-to-r from-[#2E857D] to-[#5EA19B] w-screen h-[240px]  flex justify-center items-center flex-col gap-7">
          <div className="w-[328px] h-[28px]  flex justify-between items-center">
            <Dropdown open={isOpen} setIsOpen={setIsOpen} />
            <div
              className=" w-[24px] h-[24px]  flex justify-center items-center"
              onClick={handlelogout}
            >
              <ExitToAppIcon className="text-white  transform rotate-180" />
            </div>
          </div>
          <div className="w-[328px] h-[120px] bg-white bg-opacity-10 rounded-[12px] flex justify-evenly items-center">
            <div className=" w-[97px] h-[70px]  flex-col flex justify-between">
              <div className=" w-fit h-[24px]   flex items-center">
                <p className=" text-white text-xs">تعداد گمشدگان</p>
              </div>
              <div className=" w-[80px] h-[40px]  flex justify-center items-center ">
                <p className=" text-white text-xl">{lost}</p>
              </div>
            </div>
            <div className=" h-[60px] rounded-[2px] border-[2px] border-[#D9D9D9]" />
            <div className=" w-[87px] h-[70px]  flex-col flex justify-between">
              <div className=" w-fit h-[24px]  flex justify-center items-center">
                <p className=" text-white text-xs">تعداد پیداشدگان</p>
              </div>
              <div className=" w-[80px] h-[40px]  flex justify-center items-center">
                <p className=" text-white text-xl">{find}</p>
              </div>
            </div>
          </div>
        </div>
        {isOpen && <Filter />}
        <div className="  w-screen h-screen bg-white rounded-t-3xl flex flex-col items-center">
          <div className=" w-[328px] h-[55px]  border-b-[1px] border-[#F0F0F0] flex flex-col items-center justify-evenly gap-2">
            <div className="w-[40px] h-[10px] ">
              <div className=" w-[40px] border-[3px] border-[#D9D9D9] rounded-sm" />
            </div>
            <div className=" w-[328px] h-[24px]  flex justify-between items-center">
              <div className=" w-[99px] h-[24px]  flex justify-center items-center">
                <span className=" text-sm text-[#434343]">آخرین گمشدگان </span>
              </div>
              <div className=" w-[99px] h-[24px]  flex justify-center items-center">
                <span className=" text-xs text-[#8C8C8C]">
                  {formatDate(date)}
                </span>
              </div>
            </div>
          </div>
          {listsLost.length > 0 ? (
            <div className="w-screen h-full bg-white rounded-t-3xl flex flex-col items-center">
              {/* ... کدهای بخش نمایش آخرین گمشده‌ها ... */}

              <div className="min-w-[328px]   h-[899px]   mt-7  overflow-y-auto   overflow-x-hidden    items-center  flex  flex-col   gap-4  mb-[370px]">

                {listsLost.map((person) => (
                  <div
                    key={person.id}
                    className="min-h-[350px] w-[328px] p-5 bg-white rounded-[12px] border-[1px]   border-[#F0F0F0] shadow-md flex flex-col gap-5  pr-2"
                  >
                    <div className="w-[300px] h-[48px] flex justify-between items-center  ">
                      <div className="w-[328px] h-[48px] flex justify-between items-center gap-1 ">
                        <div className=" min-w-[190px] h-[48px]  flex  gap-2">
                          <img
                            onClick={() => handleImageClick(person.photo_url)}
                            src={person.photo_url ? person.photo_url : persnal}
                            alt={`${person.name} ${person.family}`}
                            className="rounded-full  w-[48px] h-[48px] "
                          />
                          <div className=" min-w-[89px] h-[48px]  flex flex-col  items-center gap-2  ">
                            <p className="text-[#262626] text-[14px] font-semibold  min-w-[75px] h-[24px]">
                              {person.name === "" ? "" : person.name} { }
                              {person.family === "" ? "" : person.family}{" "}
                            </p>
                            <p className="text-[#595959] text-[12px] min-w-[75px] h-[24px]">
                              در حال جستجو
                            </p>
                          </div>
                        </div>

                        <div className=" w-[129px] h-[48px]   flex flex-col  items-center ">
                          {" "}
                          <span className="w-[53px] h-[24px] text-[#5EA19B]   flex items-center  justify-center  font-semibold   text-[12px]">
                            {person.is_special === "1" ? "افراد خاص" : ""}
                          </span>
                          <p className="text-[#595959] text-[12px]   flex items-center  justify-center  font-semibold   w-[129px]  h-[24px] ">
                            {convertToJalaali(person.created_at)}
                          </p>
                          <p className="text-[#595959] text-[12px]   flex items-center  justify-center  font-semibold   w-[129px]  h-[24px] ">
                            {convertToTime(person.created_at)}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="min-w-[224px]  h-[24px]  flex">
                      <div className=" w-[84px] h-[24px]  flex  items-center  gap-1">
                        <img src={locIcon} alt="loc" />
                        <p className="text-[#595959] text-[10px]   font-semibold  w-[62px] h-[24px]      flex  items-center">
                          {person.country_id == null
                            ? "نامشخص"
                            : person.country_name}
                          {"، "}
                          {person.city_id == null
                            ? "نامشخص"
                            : person.city_name}
                        </p>
                      </div>

                      <div className=" min-w-[124px] h-[24px]   flex  gap-5">
                        <div className=" min-w-[40px] h-[24px]   flex     items-center gap-[3px] ">
                          <img
                            height={15}
                            width={15}
                            src={person.gender === "male" ? genderIcon : female}
                            alt="gender"
                          />

                          <p className="text-[#595959] text-xs">
                            {person.gender == null
                              ? ""
                              : getGenderText(person.gender)}
                          </p>
                        </div>
                        <span className="min-w-[140px] h-[24px] flex   items-center  gap-1   text-[#595959] text-[12px]">
                          {person.age_group_id
                            ? getAgeGroup(Number(person.age_group_id))
                            : null}{" "}
                          { }
                          {person.age_range_id
                            ? getAgeRange(Number(person.age_range_id))
                            : null}
                        </span>
                      </div>
                    </div>
                    <div className=" w-fit h-[22px] flex     gap-1">
                      <span className=" w-fit h-[24px]   font-semibold text-[12px] text-[#8C8C8C]  flex  gap-1   items-center">
                        خادم ثبت کننده :
                        <span className="  text-[12px]   font-semibold text-[#2E857D]">
                          {person.created_by}
                        </span>
                      </span>
                    </div>

                    <div className=" w-[167px] h-[22px] flex     gap-1">
                      <span className=" w-[167px] h-[24px]   font-semibold text-[12px] text-[#8C8C8C]  flex  gap-1   items-center">
                        محل قرار:
                        <span className="  text-[12px]   font-semibold text-[#2E857D]">
                          {" "}
                          {person.appointment}
                        </span>
                      </span>
                    </div>

                    <div className=" min-w-[301px]  h-[22px]   flex items-center">
                      <span className=" font-semibold text-[12px] text-[#8C8C8C]  flex  gap-1   items-center">
                        توضیحات:
                        <span className="  text-[12px]   font-semibold text-[#2E857D]">
                          {" "}
                          {person.description}
                        </span>
                      </span>
                    </div>
                    <div className=" w-[305px] min-h-[50px]  flex    flex-col    gap-[13px]">
                      <span className=" font-semibold text-[12px] text-[#8C8C8C]  flex  gap-1 ">
                        نام و نام خانوادگی : {person.notifier_name}
                      </span>

                      <div className="  w-[100%] min-h-[40%]   flex  justify-between   items-center  ">
                        <span className=" font-semibold text-[12px] text-[#8C8C8C]  flex  gap-1   ">
                          {" "}
                          نسبت : {person.notifier_relation}
                        </span>
                        <button
                          onClick={() => handleViewInfoClick(person.id)}
                          className="w-[87px] h-[24px] flex justify-center items-center"
                        >
                          <p className="text-xs text-[#E48157]">
                            مشاهده اطلاعات
                          </p>
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

            </div>


          ) : (
            // در صورت عدم وجود داده نمایش بخش "هنوز گمشده‌ای ثبت نشده است"
            <div className="w-screen h-screen bg-white rounded-t-3xl flex flex-col items-center">
              <div className="w-[170px] h-[300px] gap-8 flex justify-center items-center flex-col">
                <div className="w-[111px] h-[121px]">
                  <img src={nothing} alt="nothing" />
                </div>
                <div className="w-[200px] h-[24px] flex justify-center items-center ">
                  <span className="text-sm text-[#8C8C8C]">
                    در حال بارگزاری
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>

      </div>
      <Outlet />

    </>
  );
}
