
import React, { useEffect, useState, useRef } from "react";
import { Pilgrim } from "../../Admin/Homepage/Slider/Lostperson";
import moment from "jalali-moment";
import { ListageRanges1, listAge_group1 } from "./Add";
import { Country } from "../../../types/country";
import { City } from "../../../types/city";
import { useNavigate } from "react-router-dom";
import personnel from "../../../Assets/img/personnel.png";
import female from "../../../Assets/Admin/Female.svg";
import locIcon from "../../../Assets/Admin/location.svg";
import genderIcon from "../../../Assets/Admin/male.svg";
import { toast, ToastContainer } from "react-toastify";
import arrowleft from "../../../Assets/Admin/Arrow-left.svg";

import { getAgeGroup } from "@/utils/getAgeGroup";
import { getAgeRange } from "@/utils/getAgeRange";

import { getGenderText } from '@/utils/getGenderText';

import { convertToJalaali } from "@/utils/convertToJalaali";
import { convertToTime } from "@/utils/convertToTime";

export interface InquiryProps {
  similarPilgrims: Pilgrim[];
}
const token = localStorage.getItem("token");

export default function Inquiry({
  similarPilgrims,
  name,
  last,
  age_range,
  age_group,
  country,
  city,
  appointment,
  description,
  nid,
  companions,
  irn,
  irq,
  type,
  status,
  is_special,
  only_pager,
  is_iranian,
  Ratio,
  firstLast,
  firstLast2,
  region,
  gender,
  Phone,
  selectedImage,
  setSimilarPilgrims,
  setName,
  setLast,
  setGender,
  setAge_range_id,
  setAge_group,
  setCountry,
  setCity,
  setRegion,
  setAppointment,
  setDescription,
  setNid,
  setCompanions,
  setIrn,
  setIrq,
  setType,
  setStatus,
  setIs_special,
  // setIs_iranian,
  setRatio,
  setFirstLast,
  setFirstLast2,
  setPhone,
  setSelectedImage
}: any) {
  const navigate = useNavigate();
  const [ListsCity, setListsCity] = useState<City[]>([]);
  const [ListageRanges, setListageRanges] = useState<ListageRanges1[]>([]);
  const [listAge_group, setlistAge_group] = useState<listAge_group1[]>([]);
  const [zoomedImage, setZoomedImage] = useState<string | null>(null);
  const [Loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const fetchedCountry = useRef(false);
  const fetchedCity = useRef(false);
  console.log(is_special);
  console.log(name);

  const handleImageClick = (image: string | null) => {
    if (image) {
      setZoomedImage(image);
    }
  };

  const handleBackClick = () => {
    setZoomedImage(null);
  };

  const handleViewInfoClick = (id: number) => {
    navigate(`/Missinginfopage/${id}`);
  };

  function Back() {
    navigate("/Servenlostpersons");
    setName("")
    setLast("")
    setGender("")
    setAge_range_id("")
    setAge_group("")
    setCountry("")
    setCity("")
    setRegion("")
    setAppointment("")
    setDescription("")
    setNid("")
    setCompanions("")
    setIrn("")
    setIrq("")
    setType("")
    setStatus("")
    setIs_special("")
    // setIs_iranian("")
    setRatio("")
    setFirstLast("")
    setFirstLast2("")
    setPhone("")
    setSelectedImage("")
  }
  function Back2() {
    navigate("/Add");
  }
  const persianToEnglish = (str: string): string => {
    return str.replace(/[۰-۹]/g, (char) => {
      return String.fromCharCode(char.charCodeAt(0) - 1728);
    });
  };
  console.log(is_special);
  console.log(is_iranian);
  const handelclick = async () => {
    setIsLoading(true);
    const token = localStorage.getItem("token");
    console.log("Token from localStorage:", token);
    if (!token) {
      console.error("Token not found in localStorage");
      return;
    }
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Accept", "application/json");
    const formdata = new FormData();
    formdata.append("name", name);
    formdata.append("family", last);
    formdata.append("gender", gender);
    // formdata.append("age_range_id", age_range);
    if (age_range) {
      formdata.append("age_range_id", age_range);
    } else {
      formdata.append("age_range_id", "");
    }
    if (age_group) {
      formdata.append("age_group_id", age_group);
    } else {
      formdata.append("age_group_id", "");
    }
    // formdata.append("age_group_id", age_group);
    if (country) {
      formdata.append("country_id", country);
    } else {
      formdata.append("country_id", "");
    }
    if (city) {
      formdata.append("city_id", city);
    } else {
      formdata.append("city_id", "");
    }
    formdata.append("region", region);
    formdata.append("appointment", appointment);
    formdata.append("description", description);
    formdata.append("national_code", persianToEnglish(nid));
    formdata.append("companions", companions);
    if (selectedImage) {
      formdata.append("photo", selectedImage);
    } else {
      formdata.append("photo", "undefined");
    }
    if (is_special === '') {
      formdata.append("is_special", '0');
    } else {
      formdata.append("is_special", is_special);
    }
    if (only_pager === '') {
      formdata.append("only_pager", '0');
    } else {
      formdata.append("only_pager", only_pager);
    }
    // formdata.append("is_special", is_special)
    // formdata.append("is_iranian", is_iranian)
    formdata.append("iran_mobile", irn);
    formdata.append("iraq_mobile", irq);
    formdata.append("type", "lost");
    formdata.append("status", status);
    formdata.append("notifier_name", firstLast);
    formdata.append("notifier_family", firstLast2);
    formdata.append("notifier_relation", Ratio);
    formdata.append("notifier_phone_number", Phone);
    // formdata.append('report_by_notifier' , report_by_notifier)
    const requestOptions: RequestInit = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
    };
    try {
      const response = await fetch(
        "https://api.mafghoudin-zakerin.com/api/pilgrims/store",
        requestOptions
      );
      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }
      if (response.ok) {
        toast("گمشده  با موفقیت ثبت شد");
        navigate("/Servenlostpersons");

        setName("")
        setLast("")
        setGender("")
        setAge_range_id("")
        setAge_group("")
        setCountry("")
        setCity("")
        setRegion("")
        setAppointment("")
        setDescription("")
        setNid("")
        setCompanions("")
        setIrn("")
        setIrq("")
        setType("")
        setStatus("")
        setIs_special("")
        // setIs_iranian("")
        setRatio("")
        setFirstLast("")
        setFirstLast2("")
        setPhone("")
        setSelectedImage("")
      }
      const result = await response.text();
      console.log(result);
    } catch (error: any) {
      console.error("Error:", error.message || "Network error occurred.");
      toast.error("گمشده ثبت نشد دوباره تلاش کنید");
      setLoading(false);
    } finally {
      setLoading(false); // Set loading to false once data is fetched or errored
    }
  };
  console.log(is_special);
  return (
    <>
      {" "}
      <ToastContainer />
      <div className="flex">
        <div className="w-full h-full flex justify-center items-end">
          <div className="w-[390px] h-[700px] flex rounded-radius bg-[#FFFFFF] flex-col items-center">
            <div className="w-[390px] min-h-[36px] flex justify-center items-center">
              <span className="w-[40px] h-0 border-[3px] border-[#D9D9D9] rounded-[7px]"></span>
            </div>
            <div className="w-[380px] h-[60px] flex  justify-between ">
              <span className="w-[140px] h-[24px] flex justify-center items-center text-[14px] font-[600] text-[#262626]">
                گمشدگان مشابه
              </span>
              <button
                onClick={Back2}
                className=" w-[24px] h-[24px] flex justify-center items-center"
              >
                <img src={arrowleft} alt="back" />
              </button>
            </div>
            <span className="w-[328px] h-0 border-[2px] border-[#F0F0F0] rounded-[7px] mt-4"></span>

            {similarPilgrims && similarPilgrims.length > 0 ? (
              <div className="w-[328] h-[899px] mb-20 overflow-y-auto overflow-x-hidden flex flex-col gap-4 mt-4">
                {similarPilgrims.map((person: Pilgrim) => (
                  <div
                    key={person.id}
                    className="min-h-[300px] min-w-[328px] p-5 bg-white rounded-[12px] border-[1px] border-[#F0F0F0] shadow-md flex flex-col gap-5 pr-2"
                  >
                    <div className="w-[300px] h-[48px] flex justify-between items-center">
                      <div className="w-[328px] h-[48px] flex justify-between items-center gap-1">
                        <div className="min-w-[190px] h-[48px] flex gap-2">
                          <img
                            onClick={() => handleImageClick(person.photo_url)}
                            src={
                              person.photo_url ? person.photo_url : personnel
                            }
                            alt={`${person.name} ${person.family}`}
                            className="rounded-full w-[48px] h-[48px]"
                          />
                          <div
                            className={`min-w-[100px] h-[48px] flex flex-col items-center gap-2`}
                          >
                            <span className="text-[#262626] text-[14px] font-semibold min-w-[100px] h-[24px] whitespace-nowrap overflow-hidden text-ellipsis">
                              {person.name === "" ? "" : person.name}{" "}
                              {person.family === "" ? "" : person.family}{" "}
                            </span>
                            <p className="text-[#595959] text-[12px] min-w-[75px] h-[24px] flex">
                              پیدا شده
                            </p>
                          </div>
                        </div>

                        <div className="w-[129px] h-[48px] flex flex-col items-center">
                          <span className="min-w-[53px] h-[24px] text-[#5EA19B] flex items-center justify-center font-semibold text-[12px]">
                            {person.is_special === "1" ? "افراد خاص" : ""}
                          </span>
                          <span className="min-w-[53px] h-[24px] text-[#5EA19B] flex items-center justify-center font-semibold text-[12px]">
                            {/* {person.is_iranian === "1" ? "فرد غیر ایرانی" : ""} */}
                          </span>
                          <p className="text-[#595959] text-[12px] flex items-center justify-center font-semibold w-[129px] h-[24px]">
                            {convertToJalaali(person.created_at)}
                          </p>
                          <p className="text-[#595959] text-[12px]   flex items-center  justify-center  font-semibold   w-[129px]  h-[24px] ">
                            {convertToTime(person.created_at)}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="min-w-[224px] h-[24px] flex">
                      <div className="w-[84px] h-[24px] flex items-center gap-1">
                        <img src={locIcon} alt="loc" />
                        <p className="text-[#595959] text-[10px] font-semibold w-[62px] h-[24px] flex items-center">
                          {person.country_id == null
                            ? "نامشخص"
                            : person.country_name}
                          {"، "}
                          {person.city_id == null
                            ? "نامشخص"
                            : person.city_name}
                        </p>
                      </div>
                      <div className="min-w-[124px] h-[24px] flex gap-5">
                        <div className="min-w-[40px] h-[24px] flex items-center gap-[3px]">
                          <img
                            height={15}
                            width={15}
                            src={person.gender === "male" ? genderIcon : female}
                            alt="gender"
                          />

                          <p className="text-[#595959] text-xs">
                            {person.gender == null
                              ? ""
                              : getGenderText(person.gender)}
                          </p>
                        </div>
                        <span className="min-w-[140px] h-[24px] flex items-center gap-1 text-[#595959] text-[12px]">
                          {person.age_group_id
                            ? getAgeGroup(Number(person.age_group_id))
                            : null}{" "}
                          {person.age_range_id
                            ? getAgeRange(Number(person.age_range_id))
                            : null}
                        </span>
                      </div>
                    </div>
                    <div className=" w-[167px] h-[22px] flex     gap-1">
                      <span className=" w-[167px] h-[24px]   font-semibold text-[12px] text-[#8C8C8C]  flex  gap-1   items-center">
                        خادم ثبت کننده :
                        <span className="  text-[12px]   font-semibold text-[#2E857D]">
                          {person.created_by}
                        </span>
                      </span>
                    </div>
                    <div className="w-[167px] h-[22px] flex gap-1">
                      <span className="w-[167px] h-[24px] font-semibold text-[12px] text-[#8C8C8C] flex gap-1 items-center">
                        محل قرار:
                        <span className="text-[12px] font-semibold text-[#2E857D]">
                          {person.appointment}
                        </span>
                      </span>
                    </div>
                    <div className="min-w-[301px] h-[22px] flex items-center">
                      <span className="font-semibold text-[12px] text-[#8C8C8C] flex gap-1 items-center">
                        توضیحات:
                        <span className="text-[12px] font-semibold text-[#2E857D]">
                          {person.description}
                        </span>
                      </span>
                    </div>

                    <div className="w-[305px] min-h-[50px] flex flex-col gap-[13px]">
                      <span className="font-semibold text-[12px] text-[#8C8C8C] flex gap-1">
                        نام و نام خانوادگی:
                        <span className="text-[12px] font-semibold text-[#2E857D]">
                          {person.notifier_name} {person.notifier_family}
                        </span>
                      </span>

                      <div className="w-[100%] min-h-[40%] flex justify-between">
                        <span className="font-semibold text-[12px] text-[#8C8C8C] flex gap-1">
                          نسبت:
                          <span className="text-[12px] font-semibold text-[#2E857D]">
                            {person.notifier_relation}
                          </span>
                        </span>
                        <button
                          className="w-[87px] h-[24px] flex justify-center items-center"
                          onClick={() => handleViewInfoClick(person.id)}
                        >
                          <p className="text-xs text-[#E48157]">
                            مشاهده اطلاعات
                          </p>
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="w-[328px] h-full flex items-center justify-center">
                <span>هیچ نتیجه ای یافت نشد.</span>
              </div>
            )}

            <div className=" w-[200px] h-[90px]   flex   items-center   justify-between">
              <button onClick={Back} className="">
                انصراف
              </button>
              <button
                onClick={handelclick}
                className=" w-[99px] h-[32px] bg-[#2E857D]   flex justify-center items-center p-[8px,16px,8px,16px] rounded-[8px]"
              >
                {isLoading ? (
                  <svg
                    className="animate-spin h-5 w-5 mr-3 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A8.002 8.002 0 014 12H0c0 3.042 1.135 5.82 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                ) : (
                  <span className="w-[72px]  h-[24px] text-[14px]  leading-6 text-[white]">
                    ثبت اطلاعات
                  </span>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
