import React, { useState, useEffect, useCallback } from "react";
import seeting from "../../../Assets/Admin/Setting.svg";
import search from "../../../Assets/Admin/search.svg";
import plus from "../../../Assets/Admin/add.svg";
import loc from "../../../Assets/Admin/location.svg";
import male from "../../../Assets/Admin/male.svg";
import start from "../../../Assets/Admin/Start-date.svg";
import end from "../../../Assets/Admin/End-date.svg";
import Registrar, { Servant } from "./Registrar/Registrar";
import Pagerpart from "./Pager/Pagerpart";
import Emptydata from "./Emptydata/Emptydata";
import Addserv from "./Addservant/Addservant";
import Puls from "../../../Assets/Admin/Plus.svg";
import { Link, useNavigate } from "react-router-dom";
import persenal from "../../../Assets/img/personnel.png";
import debounce from "lodash.debounce";
import gender from "../../../Assets/Admin/male.svg";
import { Pilgrim } from "../Homepage/Slider/Lostperson";
import moment from "jalali-moment";
import { Country } from "@/types/country";
import { City } from "@/types/city";
import { AgeGroup } from "@/types/ageGroup";
import { AgeRange } from "@/types/ageRange";
import { getGenderText } from '@/utils/getGenderText';
import { convertToJalaali } from "@/utils/convertToJalaali";

export default function Servants() {
  const token = localStorage.getItem("token");
  const [showRegistrar, setShowRegistrar] = useState(false);
  const [showPager, setShowPager] = useState(false);
  const [showAddForm, setShowAddForm] = useState(false);
  const [hasServants, setHasServants] = useState(false);
  const [loading, setLoading] = useState(false);
  const [listsCountries, setListsCountries] = useState<Country[]>([]);
  const [listsCity, setListsCity] = useState<City[]>([]);
  const [listAgeRanges, setListAgeRanges] = useState<AgeRange[]>([]);
  const [listAgeGroup, setListAgeGroup] = useState<AgeGroup[]>([]);
  const [zoomedImage, setZoomedImage] = useState<string | null>(null);

  const navigate = useNavigate();

  const [buttonState, setButtonState] = useState({
    btn1: { text: "text-[#8C8C8C]", selected: "" },
    btn2: { text: "text-[#8C8C8C]", selected: "" },
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState<Servant[]>([]);
  const [searchPerformed, setSearchPerformed] = useState(false);
  const handleClick = async (btnName: "btn1" | "btn2") => {
    const newState = {
      btn1: { text: "text-[#8C8C8C]", selected: "" },
      btn2: { text: "text-[#8C8C8C]", selected: "" },
    };
    newState[btnName] = {
      text: "text-[#E99875]",
      selected: "border-b-[2px] border-[#E99875] duration-700 transition-all",
    };

    setButtonState(newState);
    setShowRegistrar(false);
    setShowPager(false);
    setShowAddForm(false);

    if (btnName === "btn1") {
      setShowRegistrar(true);
      // API request for btn1
      try {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token} `);
        myHeaders.append("Accept", "application/json");
        var requestOptions = {
          method: "GET",
          headers: myHeaders,
        };

        const response = await fetch(
          "https://api.mafghoudin-zakerin.com/api/users/servants",
          requestOptions
        );
        const users = await response.json();
        console.log(users);
        // setName(users.servant.name)
        // setFamily(users.users.servant.family)
        // setCountry_id(users.users.servant.country_id)
        // setCity_id(users.users.servant.city_id)
        // setGender(users.users.servant.gender)
        // setStart(users.users.servant.start_at)
        // setEnd(users.users.servant.expire_at)
        // setImg(users.users.image)
      } catch (error) {
        console.log("error", error);
      }
    } else if (btnName === "btn2") {
      setShowPager(true);
      // API request for btn2
      try {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token} `);

        var requestOptions = {
          method: "GET",
          headers: myHeaders,
        };

        const response = await fetch(
          "https://api.mafghoudin-zakerin.com/api/users/pagers",
          requestOptions
        );
        const result = await response.text();
        console.log(result);
      } catch (error) {
        console.log("error", error);
      }
    }
  };

  const handleAddClick = () => {
    setShowAddForm(true);
  };

  useEffect(() => {
    setShowRegistrar(true);
    setButtonState({
      btn1: {
        text: "text-[#E99875]",
        selected: "border-b-[2px] border-[#E99875] duration-700 transition-all",
      },
      btn2: { text: "text-[#8C8C8C]", selected: "" },
    });
  }, []);

  const handleSearch = useCallback(
    debounce(async (query) => {
      // setLoading(true);
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("Token not found in localStorage");
        // setLoading(false);
        return;
      }

      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);
      myHeaders.append("Accept", "application/json");

      const url = `https://api.mafghoudin-zakerin.com/api/users/search/user?search=${encodeURIComponent(
        query
      )}`;

      try {
        const response = await fetch(url, {
          method: "GET",
          headers: myHeaders,
        });

        if (!response.ok) {
          throw new Error(`Error: ${response.status}`);
        }

        const data = await response.json();
        console.log("Search results:", data);
        setSearchResults(data.data); // Store search results
        setSearchPerformed(true); // Indicate search was performed
      } catch (error) {
        console.error("Error during the search request", error);
      } finally {
        setLoading(false);
      }
    }, 500),
    []
  ); // Debounce the search function

  useEffect(() => {
    if (searchQuery.length > 0) {
      handleSearch(searchQuery);
    } else {
      setSearchResults([]);
      setSearchPerformed(false);
    }
  }, [searchQuery, handleSearch]);

  const handleViewInfoClick = (id: number) => {
    navigate(`/Missinginfopage/${id}`);
  };
  const handleImageClick = (image: string | null) => {
    if (image) {
      setZoomedImage(image);
    }
  };

  const handleBackClick = () => {
    setZoomedImage(null);
  };

  if (zoomedImage) {
    return (
      <div className="fixed top-0 left-0 w-full h-full flex flex-col items-center justify-center bg-gray-900 bg-opacity-75 z-50">
        <div className="relative">
          <img
            src={zoomedImage}
            alt="Zoomed"
            className="w-[500px] h-[500px] object-cover"
          />
          <button
            onClick={handleBackClick}
            className="absolute top-2 right-2  rounded-full p-1"
          >
            <span role="img" aria-label="Close">
              ❌
            </span>
          </button>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="flex justify-center items-center flex-col gap-5 w-full">
        <div className="h-[130px] flex flex-col gap-4 justify-center items-center">
          <div className="w-[328px] h-[48px] flex justify-between items-center">
            <div className="w-[328px] h-[48px] flex items-center bg-[#F5F5F5] border-[1px] border-[#D9D9D9] rounded-[8px] mt-6">
              <button
                className="w-[40px] h-[20px] flex justify-center items-center"
                onClick={() => handleSearch(searchQuery)}
              >
                <img src={search} alt="search" />
              </button>
              <input
                className="w-fit h-[24px] text-[#8C8C8C] bg-transparent outline-none"
                placeholder="جستجو کنید"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
          </div>
          <div className="w-[328px] min-h-[55px]   border-b-[1px] border-[#F0F0F0] flex justify-between items-center ">
            <div className="w-[100px] min-h-[28px]  flex justify-between items-center">
              <button
                className={`w-fit min-h-[28px] flex justify-center items-center ${buttonState.btn1.selected}`}
                onClick={() => handleClick("btn1")}
              >
                <span className={`text-sm ${buttonState.btn1.text}  `}>
                  ثبت کننده
                </span>
              </button>
              <button
                className={`w-fit h-[28px] flex justify-center items-center ${buttonState.btn2.selected}`}
                onClick={() => handleClick("btn2")}
              >
                <span className={`text-sm ${buttonState.btn2.text}`}>پیجر</span>
              </button>
            </div>
            <Link
              to="/Addservant"
              className=" w-[60px] h-[55px]    flex items-center   justify-center"
            >
              <img
                src={Puls}
                height={12}
                width={12}
                alt=""
                onClick={handleAddClick}
              />
            </Link>
          </div>
        </div>

        <div className="w-[328px]    min-h-[899px]    mb-20  overflow-y-auto   overflow-x-hidden    flex  flex-col   gap-4   mt-2">
          {searchQuery.length > 0 && searchPerformed ? (
            searchResults.length > 0 ? (
              searchResults.map((user) => (
                <div
                  key={user.id}
                  className="min-h-[220px]  min-w-[328px] p-5 bg-white rounded-[12px] border-[1px]    border-[#F0F0F0] shadow-md flex flex-col gap-5  pr-2"
                >
                  {/* Info of the Pager */}
                  <div className="w-fit h-[50px] flex justify-center items-center gap-2">
                    <div
                      className="w-[48px] h-[48px] rounded-[30px] border-[2px] border-[#2E857D] bg-blue-500"
                      onClick={() => handleImageClick(user.image_url)}
                    >
                      {user.image_url && (
                        <img
                          src={user.image_url}
                          alt={`${user.user_data.name} ${user.user_data.family}`}
                          className="rounded-full w-full h-full"
                        />
                      )}
                    </div>
                    <div className="w-fit h-[48px] flex flex-col justify-between">
                      <div className="w-fit h-[24px] flex justify-center items-center">
                        <span className="text-sm text-[#262626] font-bold">
                          {user.user_data.name}, {user.user_data.family}
                        </span>
                      </div>
                      <div className="w-[52px] h-[24px] flex justify-center items-center">
                        <span className="text-xs text-[#2E857D]">
                          ثبت کننده
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="min-w-[224px]  h-[24px]  flex  items-center     gap-3">
                    <div className=" min-w-[84px] h-[24px]  flex items-center  gap-2">
                      <img src={loc} alt="loc" />
                      <p className="text-[#595959] text-[10px]   font-semibold  min-w-[79px] h-[24px]        flex  items-center">
                        {user.country_name} ،{" "}
                        {user.city_name}
                      </p>
                    </div>

                    <div className=" w-[100px] h-[24px]   flex  gap-5  ">
                      <div className=" min-w-[40px] h-[24px]   flex     items-center gap-[3px] ">
                        <img src={gender} alt="gender" />

                        <p className="text-[#595959] text-xs">
                          {getGenderText(user.user_data.gender)}
                        </p>
                      </div>
                      <span className="min-w-[140px] h-[24px] flex   items-center  gap-1   text-[#595959] text-[12px]">
                      </span>
                    </div>
                  </div>
                  {/* Collaboration period */}
                  <div className="w-[199px] h-[24px] flex  items-center     gap-4 ">
                    <div className="w-fit h-[24px] flex justify-center items-center gap-1">
                      <div className="w-[18px] h-[18px] flex justify-center items-center">
                        <img src={start} alt="Start-date" />
                      </div>
                      <div className="w-fit h-[24px] flex justify-center items-center">
                        <span className="text-[#595959] text-xs">
                          {convertToJalaali(user.start_at)}
                        </span>
                      </div>
                    </div>
                    <div className="w-fit h-[24px] flex justify-center items-center gap-1">
                      <div className="w-[18px] h-[18px] flex justify-center items-center">
                        <img src={end} alt="End-date" />
                      </div>
                      <div className="w-fit h-[24px] flex justify-center items-center">
                        <span className="text-[#595959] text-xs">
                          {convertToJalaali(user.expire_at)}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="w-[304px]   flex flex-col  ">
                    <span className="w-[304px] h-0 border-[1px] border-[#F0F0F0] "></span>
                    {/* See the info */}
                    <button
                      onClick={() => handleViewInfoClick(user.id)}
                      className="w-[304px] p-3 h-[40px] flex justify-center items-center cursor-pointer "
                    >
                      <button className="w-fit h-[19px] flex justify-center items-center">
                        <span className="text-[#E48157] text-xs">
                          مشاهده اطلاعات
                        </span>
                      </button>
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <p>نتیجه‌ای یافت نشد</p>
            )
          ) : (
            <div className=" w-[328px] overflow-y-scroll gap-4 flex flex-col bg-w">
              {showRegistrar && <Registrar />}
              {showPager && <Pagerpart />}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
