"use client";
import { useState, useEffect } from 'react';
import add from "../../Assets/Servant/Add.svg"
import lost from "../../Assets/Servant/Lost.svg"
import user from "../../Assets/Servant/user.svg"
import { Link } from 'react-router-dom';
const Footer = () => {
    const [activePath, setActivePath] = useState('');

    useEffect(() => {
        if (typeof window !== 'undefined') {
            const { pathname } = window.location;
            setActivePath(pathname);
        }
    }, []);

    const footerItems = [
        {
            icon: add,
            title: "افزودن",
            path: "/Add"
        },
        {
            icon: lost,
            title: "گمشدگان",
            path: "/Servenlostpersons"
        },
        {
            icon: user,
            title: "پروفایل",
            path: "/Servantprof"
        }
    ];

    const handleClick = (path: string) => {
        setActivePath(path);
    };

    return (
        <div className='w-screen h-[80px] bg-white rounded-8 flex justify-evenly items-center   fixed bottom-0 shadow-[#585858]/25'>
            {footerItems.map((item, index) => (
                <Link to={item.path} key={index} onClick={_ => handleClick(item.path)}>
                    <div className={`w-[109px] h-[80px] flex flex-col justify-center items-center gap-1`}>
                        <div className='w-[24px] h-[24px] flex justify-center items-center'>
                            <img
                                style={{
                                    ...(activePath === item.path ? { filter: "brightness(0) saturate(100%) invert(55%) sepia(60%) saturate(491%) hue-rotate(331deg) brightness(96%) contrast(86%)" } : {})
                                }}
                                src={item.icon}
                                alt={item.title}
                                width={24}
                                height={24}
                            />
                        </div>
                        <div className='w-[44px] h-[19px] flex justify-center items-center'>
                            <span className={`${activePath === item.path ? "duration-700 text-[#E48157]" : "text-[#BFBFBF]"}`}>{item.title}</span>
                        </div>
                    </div>
                </Link>
            ))}
        </div>
    );
};

export default Footer;
